import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import LoginApp from './components/LoginApp'
import DashBoardApp from './components/member/DashBoardApp'
import PublishListApp from './components/member/PublishListApp'
import PublishCreateApp from './components/member/PublishCreateApp'
import TimeSheetListApp from './components/member/TimeSheetListApp'
import TimeSheetDetailApp from './components/member/TimeSheetDetailApp'
import AnalyticsApp from './components/member/AnalyticsApp'
import UserListApp from './components/member/UserListApp'
import CalendarApp from './components/member/accommodation/CalendarApp'
import PlanListApp from './components/member/accommodation/PlanListApp'
import PlanPriceApp from './components/member/accommodation/PlanPriceApp'
import PlanCsvApp from './components/member/accommodation/PlanCsvApp'
import RoomListApp from "./components/member/accommodation/RoomListApp"
import AccommodationBookListApp from './components/member/accommodation/BookListApp'
import SyncCheckApp from './components/member/accommodation/SyncCheckApp'
import SettingApp from './components/member/accommodation/SettingApp'
import CashHistoryApp from './components/member/accommodation/CashHistoryApp'
import FaqListApp from './components/member/accommodation/FaqListApp'
import SupplierListApp from "./components/member/SupplierListApp"
import OptionListApp from "./components/member/accommodation/OptionListApp"
import ReceiveMailApp from "./components/member/ReceiveMailApp"
import BookListApp from "./components/member/BookListApp"
import CouponListApp from './components/member/accommodation/coupon/CouponListApp.js';
config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Switch>
        <Route exact path='/' component={LoginApp} />
        <Route exact path='/m/' component={DashBoardApp} />
        <Route exact path='/m/mail' component={PublishListApp} />
        <Route exact path='/m/mail/receive/:id' component={ReceiveMailApp} />
        <Route exact path='/m/mail/create' component={PublishCreateApp} />
        <Route exact path='/m/analitcs' component={AnalyticsApp} />
        <Route exact path='/m/analitcs/:year/:month' component={AnalyticsApp} />
        <Route exact path='/m/timesheet' component={TimeSheetListApp} />
        <Route exact path='/m/timesheet/:id' component={TimeSheetDetailApp} />
        <Route exact path='/m/user' component={UserListApp} />
        <Route exact path='/m/supplier' component={SupplierListApp} />
        <Route exact path='/m/books/:mail' component={BookListApp} />

        <Route exact path='/m/accommodation/:id' component={CalendarApp} />
        <Route exact path='/m/accommodation/:id/book/:book_id' component={CalendarApp} />
        <Route exact path='/m/accommodation/:accommodation_id/plan' component={PlanListApp} />
        <Route exact path='/m/accommodation/:accommodation_id/plan/:plan_id/sync/check' component={SyncCheckApp} />
        <Route exact path='/m/accommodation/:accommodation_id/plan/:plan_id/price' component={PlanPriceApp} />
        <Route exact path='/m/accommodation/:accommodation_id/plan/:plan_id/price/csv' component={PlanCsvApp} />
        <Route exact path='/m/accommodation/:accommodation_id/room' component={RoomListApp} />
        <Route exact path='/m/accommodation/:accommodation_id/cash' component={CashHistoryApp} />
        <Route exact path='/m/accommodation/:accommodation_id/book/:year/:month/:day' component={AccommodationBookListApp} />
        <Route exact path='/m/accommodation/:accommodation_id/setting' component={SettingApp} />
        <Route exact path='/m/accommodation/:accommodation_id/faq' component={FaqListApp} />
        <Route exact path='/m/accommodation/:accommodation_id/option' component={OptionListApp} />
        <Route exact path='/m/accommodation/:accommodation_id/coupon' component={CouponListApp} />

        
        
      </Switch>
    </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
