import React,{useState,useEffect,useCallback} from 'react'

import "../../../../styles/form.css"

function Checkbox(props:PropsValue) {

  /****
  name : form name
  selected: selected value
  items:

    value :form value
    text : lavel html

   changeHandler
  ***/


  const [items, setItems] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableCreateTags = useCallback(createTags,[])

  useEffect(() => {

    stableCreateTags(props.selected,props.items)
  },[props.items, props.selected, props.selected.length,stableCreateTags]);



  function createTags(selected, items) {
    const _items = items.map( item => {
      return (
        <div className="checkbox" key={"check_" + props.name + "_" + item.value} >
          <input className="form-check" type="checkbox" onChange={event => checkHandler(event)} checked={selected.indexOf(item.value) >= 0 ? true: false} id={"check_" + props.name + "_" + item.value}  value={item.value} />
          <label className="check-parts" htmlFor={"check_" + props.name + "_" + item.value} dangerouslySetInnerHTML={{__html: item.text }}>
          </label>
        </div>
      )
    })

    setItems(_items)
  }

  function checkHandler(e){
    var target_value = e.target.value
    props.changeHandler(props.name, target_value)
  }



  return(
    <div className="checkbox-wrap">
      {items}
    </div>

  )
}

export default Checkbox
