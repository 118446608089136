import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { format,addMonths,subMonths,subDays } from 'date-fns'

import RoomActions from '../../../actions/RoomActions'
import BookActions from '../../../actions/BookActions'
import BookOptionActions from "../../../actions/BookOptionActions"
import CalendarActions from '../../../actions/CalendarActions';
import CalendarStore from '../../../stores/CalendarStore';
import ErrorStore from '../../../stores/ErrorStore';
import AuthStore from '../../../stores/AuthStore';
import BookStore from "../../../stores/BookStore"
import Menu from '../parts/Menu';
import Header from '../parts/Header';
import MessageBox from '../parts/MessageBox'
import Calendar from '../parts/Calendar'
import CalendarColumn from '../parts/CalendarColumn'
import BookItem from '../parts/BookItem'
import Alert from '../parts/Alert';

import "../../../styles/dashboard.css"
import "../../../styles/calendar.css"

function CalendarApp(props:PropsValue) {

  const { id } = useParams();
  const {book_id} = useParams()

  const [alert, setAlert] = useState(null)
  const [current_month,setCurrenMonth] = useState(null)

  const [pannelCss, setPannelCss] = useState("full")
  const [bookPannel, setBookPannel] = useState(null)

  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")
  const [calendar_type, setCalendarType] = useState("row")
  const [is_adding, setIsAdding] = useState(false)

  const [bottomBtnClass, setBottomButton] = useState("")

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  

  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
  }

  const stableCalendarChangeHandler = useCallback(calendarChangeHandler, [])
  function calendarChangeHandler(){
    setCurrenMonth(CalendarStore.getCurrentMonth())
  }

  const stableCalendarCreatedHandler = useCallback(calendarCreatedHandler,[])
  function calendarCreatedHandler(){
    setIsAdding(false)
  }

  const stableCalendarTypeHandler = useCallback(calendarTypeHandler, [])
  function calendarTypeHandler() {
    setCalendarType(CalendarStore.getType())
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableopenPannel = useCallback(openPannel, [])

  useEffect(() => {

    ErrorStore.addChangeListener(stableErrorChangeHandler)
    CalendarStore.addChangeListener(stableCalendarChangeHandler,"selected")
    CalendarStore.addChangeListener(stableCalendarCreatedHandler,"created_upper")
    CalendarStore.addChangeListener(stableCalendarCreatedHandler,"created_bottom")
    CalendarStore.addChangeListener(stableCalendarTypeHandler,"update_type")
    setCurrenMonth(CalendarStore.getCurrentMonth())
    RoomActions.getList(AuthStore.getToken(), id)
    CalendarActions.getCalendarType()
    if(book_id){
      stableopenPannel(book_id)
    }
    return function cleanup() {

      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      CalendarStore.removeChangeListener(stableCalendarChangeHandler,"selected")
      CalendarStore.removeChangeListener(stableCalendarCreatedHandler,"created_upper")
      CalendarStore.removeChangeListener(stableCalendarCreatedHandler,"created_bottom")
      CalendarStore.removeChangeListener(stableCalendarTypeHandler,"update_type")
    };

  },[stableErrorChangeHandler,stableCalendarChangeHandler,stableCalendarCreatedHandler,stableCalendarTypeHandler,id, stableopenPannel, book_id]);



  function closeMessageBox(){
    setBoxClass("close")
  }

  function openPannel(_book_id,target_day){
    props.history.push("/m/accommodation/" + id + "/book/" + _book_id)
    setBookPannel(<BookItem id={_book_id}  date={target_day} accommodation_id={id} closePannel={closePannel} deleteOption={deleteOption} />)
  }

  function closePannel(){
    setPannelCss("full")
    setBookPannel(null)
    BookActions.setItem(null)
  }


  function returnToToday(){
    document.getElementById("calendar_" + format(subDays(new Date(),7),'yyyy_MM_dd')).scrollIntoView(true)
  }


  function handleScroll(){
    const time_line_element = document.getElementById("time_line")
    const h = document.getElementById("calendar").offsetHeight - time_line_element.offsetHeight

    if(!is_adding && time_line_element.scrollTop < 200){
      setIsAdding(true)
      CalendarActions.createUpper()
    }else if( !is_adding && time_line_element.scrollTop > h - 200 ){
      CalendarActions.createBottom()
      setIsAdding(true)
    }

    //currentがいるかどうか判定
    if(CalendarStore.getCurrentMonth() && document.getElementById("calendar_" + format(CalendarStore.getCurrentMonth(),'yyyy_MM_dd')) ){
      const current_month_top = document.getElementById("calendar_" + format(CalendarStore.getCurrentMonth(),'yyyy_MM_dd')).offsetTop

      //上に表示されていない場合
      if(CalendarStore.getCurrentMonth() && current_month_top - time_line_element.scrollTop < 0){
        const next_month_top = document.getElementById("calendar_" + format(addMonths(CalendarStore.getCurrentMonth(),1),'yyyy_MM_dd')).offsetTop

        if(next_month_top - time_line_element.scrollTop < document.getElementById("calendar_" + format(CalendarStore.getCurrentMonth(),'yyyy_MM_dd')).offsetHeight ){
          CalendarActions.changeCurrentMonth(addMonths(CalendarStore.getCurrentMonth(),1))
        }
      }else{ //表示中 or 下の場合
        if(current_month_top - time_line_element.scrollTop  > document.getElementById("calendar_" + format(CalendarStore.getCurrentMonth(),'yyyy_MM_dd')).offsetHeight){
          CalendarActions.changeCurrentMonth(subMonths(CalendarStore.getCurrentMonth(),1))
        }
      }
    }


    //Todayが表示されているか
    const today_element = document.getElementById("calendar_" + format(new Date(),'yyyy_MM_dd'))
    if( time_line_element.scrollTop  - today_element.offsetTop - today_element.offsetHeight > 0){
      setBottomButton("active")
    }else if(h - time_line_element.scrollTop - time_line_element.scrollTop - 2 * today_element.offsetHeight > 0 ){
      setBottomButton("active")
    }else{
      setBottomButton("")
    }
  }

  function createNew(){
    setPannelCss("wide")
    setBookPannel(<BookItem id={0} accommodation_id={id} closePannel={closePannel} />)
  }

  function chanteCalendar(id){
    CalendarActions.updateCalendarType(id)
  }

  function cancellAlert(){
    setAlert(null)
  }
  function deleteOptionExe(id){
    const item = BookStore.getDetail()
    BookOptionActions.delete(AuthStore.getToken(), item.id, id)
    setAlert(null)
  }
  function deleteOption(id){
    setAlert(<Alert ok="削除"  message="オプションを削除してよろしいですか" executor={deleteOptionExe} cancell={cancellAlert} param={id}/>)
  }


  return(
    <div className="inner">
      {alert}
        <main className="main">
          <Menu selected="accommodation" accommodation_id={id}/>
          <div className="main-content calendar">
            <Header accommodation_id={id} id="calendar"/>
              <div className="open-close-section">
              {
                calendar_type === "row" ? <Calendar accommodation_id={id} openPannel={openPannel} handleScroll={handleScroll} pannelCss={pannelCss} current_month={current_month} createNew={createNew} changeCalendar={() => chanteCalendar("column")}/>
                : <CalendarColumn accommodation_id={id} openPannel={openPannel} handleScroll={handleScroll} pannelCss={pannelCss} createNew={createNew} changeCalendar={() => chanteCalendar("row")}/>

              }

                {bookPannel}
              </div>
              <div className={"bottom-button-wrap " + bottomBtnClass}><div className="bottom-button" onClick={returnToToday}>本日</div></div>
          </div>
          <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>

        </main>
    </div>
  )
}

export default CalendarApp
