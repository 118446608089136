import { useState } from 'react';
import CouponAction from './CouponAction';
import Coupon from './Coupon.tsx';
import ResponseData from '../../../../actions/ResponseData';
import parseISO from 'date-fns/parseISO';

type CouponJson = { 
  id:string | null 
  start_date:string | null 
  end_date:string | null 
  discount_type:string | null 
  discount_value:number | null 
};




function useCouponState() {

  const [error, setError] = useState(null)
  const [coupon, setCoupon] = useState<Array<Coupon> | null>(null)

  const loadHandler = (data:ResponseData) => {
    if(data.data){
      const _coupons:Array<CouponJson> = data.data
      setCoupon(_coupons.map(item => {
        return new Coupon(
          {id:item.id, start_date: item.start_date ? new Date(parseISO(item.start_date) ): null , end_date: item.end_date ?  new Date(item.end_date) : null, discount_type:item.discount_type,discount_value: item.discount_value }
        )
      }))
    }
  }

  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }


  const get = (token:string, accommocation_id:string) => {
    CouponAction.get(token, accommocation_id, loadHandler, errorHandler)
  } 

  

  return { coupon, error,  getCoupon:get}

}

export default useCouponState