import React from 'react'

import MailCreateBoxItem from "./MailCreateBoxItem"
import '../../../styles/overlay_form.css'

function MailCreateBox(props:PropsValue) {



  return(
    <div >
      <section className="pannel left">
        <MailCreateBoxItem item={props.item} accommodation_id={props.accommodation_id} cancell={props.cancell}/>
      </section>
    </div>



  )
}

export default MailCreateBox
