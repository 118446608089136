import React, { useState,useEffect,useCallback } from 'react'
import { format,setDate,subMonths,getDay,subDays,addDays,differenceInCalendarWeeks,isSameDay,getYear,getMonth,getDate } from 'date-fns'
import config from 'react-global-configuration';

import CalendarActions from "../../../actions/CalendarActions"
import CalendarStore from "../../../stores/CalendarStore"
import AccommodationStore from "../../../stores/AccommodationStore"
import BookStore from "../../../stores/BookStore"
import AuthStore from "../../../stores/AuthStore"
import RoomStore from "../../../stores/RoomStore"
import "../../../styles/calendar.css"


function CalendarColumn(props:PropsValue) {

  const [calendar_content , setCalendarContent] = useState([]);

  var is_first = 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableCreateColTag = useCallback(createColTag,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableRoomChangeHandler = useCallback(roomChangeHandler, [])

  function bookChangeHandler(){
    const target_date = CalendarStore.getCurrentDate()
    if(target_date){
      var first_day = target_date.start_at
      const start_dif = getDay(first_day)
      first_day = subDays(first_day,start_dif + 1)
      var counter = 0;
      if(differenceInCalendarWeeks(target_date.end_at,first_day)){
        const new_array =  (new Array(config.get("CALENDAR_ADD_DAYS"))).fill(null).map( i => {
          counter += 1
          return (stableCreateColTag(addDays(first_day, counter)))
        })
        setCalendarContent(new_array)
        if(is_first < 2){
          is_first ++
          document.getElementById("calendar_" + format(subDays(new Date(),7),'yyyy_MM_dd')).scrollIntoView(true)
        }
      }
    }
  }

  function roomChangeHandler(){
    bookChangeHandler()
  }

  useEffect(() => {
    //CalendarStore.addChangeListener(stableCalendarCreateUpperChangeHandler,"create_upper")
    //CalendarStore.addChangeListener(stableCalendarCreateBottomChangeHandler,"create_bottom")
    BookStore.addChangeListener(stableBookChangeHandler)
    RoomStore.addChangeListener(stableRoomChangeHandler)
    return function cleanup() {
      BookStore.removeChangeListener(stableBookChangeHandler)
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      //CalendarStore.removeChangeListener(stableCalendarCreateUpperChangeHandler,"create_upper")
    //  CalendarStore.removeChangeListener(stableCalendarCreateBottomChangeHandler,"create_bottom")
    };
  },[stableBookChangeHandler,stableRoomChangeHandler]);


  //First Calendar
  useEffect(() => {
    const today = new Date()
    var first_day = setDate(subMonths(today,1), 1) ;
    const start_dif = getDay(first_day)
    first_day = subDays(first_day,start_dif + 1)

    var counter = 0;
    const new_array =  (new Array(config.get("CALENDAR_ADD_DAYS"))).fill(null).map( i => {
      counter += 1
      return (stableCreateColTag(addDays(first_day, counter)))
    })

    setCalendarContent(new_array)
    CalendarActions.setCurrentDate(AuthStore.getToken(), {start_at:first_day, end_at:addDays(first_day, counter)}, props.accommodation_id)
  },[props.accommodation_id,stableCreateColTag]);


  //Create Common Tag

  function createColTag(target_day, selected_id){

    const bookings = BookStore.getDataFromDate(target_day)
    const items = bookings.map( item => {

      const op_icons = item.options.map(op => {
        if(op.icon ) {
          return  <i className={"red " +op.icon}>&nbsp;</i>
        }
        else { return null}
      })

      return <div className="book" onClick={() => openPannel(item.id,target_day)}>
        <div className="w150">{item.plan_icon ? <i className={"red " +item.plan_icon}>&nbsp;</i>:null}{op_icons}{item.name}</div>
        <div className="w30 right">{item.person_number}人</div>
        <div className="w220">{item.room_name}</div>
        <div className="w220 wide-hidden">{item.plan_name}</div>
        <div className="free wide-hidden">{item.mini_memo}</div>
      </div>
    })
      var add_class = ""
    if(isSameDay(target_day,new Date())) add_class += "today "
    if(items.length > 0){
      const is_no_room_over_book = RoomStore.checkOverBook(bookings)
      if(bookings.length > AccommodationStore.getById(props.accommodation_id).room_number || !is_no_room_over_book ){
        add_class += "over "
      }else if(bookings.length === AccommodationStore.getById(props.accommodation_id).room_number  ){
        add_class += "full "
      }else{
        add_class += "has-book "
      }
    }
    if(BookStore.getDetail() && BookStore.getDetail().id){
      if(items.length === 0){
        add_class += " unselect "
      }else{
        var selected = false
        for(var i =0 ;  i < bookings.length; i++){
          if(bookings[i].id === BookStore.getDetail().id) selected = true
        }
        if(!selected ) add_class += " unselect "
      }

    }
    return (
      <div className={"day " + add_class}>
        <div className={"date "} id={"calendar_" + format(target_day, 'yyyy_MM_dd')} key={"calendar_" +format(target_day, 'yyyy_MM_dd')}><div className="calendar-date" onClick={() => gotoBookList(target_day)}>{format(target_day, 'M/d')}</div></div>
        <div className="books">{items}</div>
      </div>
    )
  }

  function openPannel(id,target_date){
    props.openPannel(id,target_date)
  }

  function gotoBookList(target_date){
    window.location.href = "/m/accommodation/" +  props.accommodation_id + "/book/" + getYear(target_date) + "/" + (getMonth(target_date) + 1)+ "/" + getDate(target_date)
  }
  return(

    <section className={"pannel " + props.pannelCss}>
      <div className="section-header">

        <div className="current-month">{props.current_month ? format(props.current_month,"yyyy年MM月") : null}</div>

        <div className="right-menu">
          <div className="" onClick={props.changeCalendar}><i className="far fa-calendar-alt"></i>&nbsp;横表示</div>
          <div className="inline-link" onClick={props.createNew}><i className="fas fa-plus-circle"></i> 新規予約作成</div>
        </div>
      </div>

      <div className="free-pannel scrollable-vertical" id="time_line" onScroll={props.handleScroll}>
        <div>
          <div className="calendar-days " id="calendar">
            {calendar_content}
          </div>
        </div>
      </div>
    </section>



  )
}

export default CalendarColumn
