import React,{useState} from 'react'


import UserActions from "../../../actions/UserActions"
import AuthStore from "../../../stores/AuthStore"

import "../../../styles/alert.css"

function PasswordBox(props:PropsValue) {

  const [password, setPassword] = useState([]);

  function update(){
    UserActions.updatePassword(AuthStore.getToken(), props.id, password)
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">パスワード変更  </div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">新規パスワード</div>
                <div className="item-content">
                  <input type="password" value={password} className="full" name="password" onChange={(e) => setPassword(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={update}>変更</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default PasswordBox
