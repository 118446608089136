import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { format,differenceInCalendarDays,addDays } from 'date-fns'
import ja from 'date-fns/locale/ja'

import AuthStore from '../../../stores/AuthStore';
import PlanActions from '../../../actions/PlanActions';
import PlanStore from '../../../stores/PlanStore';
import ErrorStore from '../../../stores/ErrorStore';

import Menu from '../parts/Menu';
import Header from '../parts/Header';


import "../../../styles/price.css"
import Plan from '../../../accommodation/plan/Plan';

function PlanCsvApp(props:PropsValue) {
 
  const { accommodation_id } = useParams();
  const { plan_id } = useParams();

  const [fileName, setFileName] = useState(null)
  const [item, setItem] = useState(null)

  const [btn, setBtn] = useState(null)
  const [list, setList] = useState([])
  const [list_header, setListHeader] = useState([])
  const [list_class, setListClass] = useState("")
  const [message,setMessage] = useState(null)

// eslint-disable-next-line react-hooks/exhaustive-deps
  const stablePlanChangeHandler = useCallback(planChangeHandler, [])
  function planChangeHandler(){

    const plan = PlanStore.getItem()
    const _prices = PlanStore.getPrices()
    setItem(plan)

    if(_prices.length > 0){
      var header = [<div className="date">日付</div>]
      //setListHeader(header)
      for(var t = 0; t < plan.max_num; t++){
        header.push(<div className="free">{t+1}人</div>)
      }
      setListHeader(<div className="mail-list-item header">{header}</div>)

      const min_day = PlanStore.getMinDateFromPrices()
      const max_day = PlanStore.getMaxDateFromPrices()
      const dif = differenceInCalendarDays(  max_day,min_day)

      var days = []
      for(var i = 0 ; i <= dif ; i ++){
        var units = []
        const d = addDays(min_day, i)
        for(var k = 0; k < plan.max_num; k++){
          const price = PlanStore.getPriceFromPrices(d, k + 1)
          units.push(<div className="free right">{price ? price.toLocaleString() + "円": null}</div>)
        }

        days.push(
          <div className={"mail-list-item week_" + d.getDay()}>
            <div className="date with-week">{format(d, 'yyyy年MM月dd日 (eeeee)',{locale: ja})}</div>
            {units}
          </div>
        )
      }

      setList(days)
      if(plan.max_num > 5){
        setListClass("small")
      }

      setBtn(<button className="link-btn" onClick={exeCsv}>下記の内容で更新する</button>)
    }



  }


  const stableCsvChangeHandler = useCallback(csvChangeHandler, [])
  function csvChangeHandler(){
    setFileName(<div className="upload-file-name">{PlanStore.getSelectedCsv().name}</div>)
  }

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  function errorChangeHandler(){
    setMessage(ErrorStore.getMessage())
  }
  useEffect(() => {
    PlanStore.addChangeListener(stablePlanChangeHandler)
    PlanStore.addChangeListener(stableCsvChangeHandler,"update")
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    PlanActions.getDetail(AuthStore.getToken(),accommodation_id,plan_id)

    return function cleanup() {
        PlanStore.addChangeListener(stablePlanChangeHandler)

        PlanStore.removeChangeListener(stableCsvChangeHandler,"update")
        ErrorStore.removeChangeListener(stableErrorChangeHandler)
    };

  },[accommodation_id,plan_id,stablePlanChangeHandler,stableCsvChangeHandler,stableErrorChangeHandler]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stabletestCsv =  useCallback(testCsv,[])
  function testCsv(){
    PlanActions.csvTest(AuthStore.getToken(),plan_id,PlanStore.getSelectedCsv())
    setBtn(null)
  }


  function exeCsv(){
    PlanActions.csvCreate(AuthStore.getToken(),plan_id,PlanStore.getSelectedCsv(),accommodation_id)
  }

  useEffect(() => {
    if(fileName){
      setBtn(<button className="white-btn" onClick={stabletestCsv}>テスト送信</button>)
    }else{
      setBtn(null)
    }
  },[stabletestCsv,fileName])


  function setCsvFile(e){
    PlanActions.updateCsv(e)
    setMessage(null)
    setBtn(<button className="white-btn" onClick={stabletestCsv}>テスト送信</button>)
  }


  const getCsvFormat = () => {
    const plan = new Plan(item)
    const csv_string = plan.getCsvObject()
    const blob = new Blob([csv_string], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =  plan.getFileName() + ".csv"; //フォーマットによってファイル拡張子を変えている
    a.click();
    a.remove();
  }

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id}/>
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="open-close-section">
              <section className={"pannel"}>
                <div className="section-header">
                    <div className="title">プラン | {item ? item.name : null} |  <a href={"/m/accommodation/" + accommodation_id + "/plan/" + plan_id + "/price" } >金額設定</a> | CSV更新 </div>
                </div>


                <div className="scrollable-container">
                  <div className="item-content">

                    
                    <label className="upload-btn">
                      <i className="fas fa-file-upload"></i> CSVファイルを選択
                      <input type="file" className="full" onChange = {(e) => setCsvFile(e.target.files[0]) } />
                      {fileName}
                    </label>
                    
                    
                    <div className="link center" onClick={getCsvFormat}><i className="fas fa-cloud-download-alt"></i> CSVフォーマットをダウンロード</div>
            

                    <div className="button-box">
                      <div className="message">{message}</div>
                      {btn}
                    </div>
                  </div>


                  {list_header}


                  <div className="scrollable-container ">
                    <div className={"scrollable-vertical price " + list_class}>{list}</div>
                  </div>


                </div>
              </section>



              </div>
          </div>
        </main>
    </div>
  )
}

export default PlanCsvApp
