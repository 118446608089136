import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import MailHistoryActions from "./MailHistoryActions"
import AuthActions from "./AuthActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format } from 'date-fns'
import cookie from 'react-cookies'

var BookActions = {


  getList(token, accommodation_id, stay_from, stay_to, sync = null, no_paid = null, with_deleted = false, mail = null){

    cookie.save("book_search_params", {accommodation_id:accommodation_id,stay_from:stay_from, stay_to:stay_to,sync:sync,no_paid:no_paid,with_deleted:with_deleted,mail:mail}, { path: '/' })

    let params = new URLSearchParams();
    params.set("with_consecutive",true )
    if(accommodation_id){
      params.set('accommodation_id', accommodation_id);
    }

    if(stay_from){
      params.set('stay_start_date', format(stay_from, 'yyyy-MM-dd'));
    }
    if(stay_to){
      params.set('stay_end_date', format(stay_to, 'yyyy-MM-dd'));
    }

    if(sync != null){
      params.set('sync', sync);
    }

    if(no_paid != null){
      params.set('not_paid_only', no_paid);
    }

    if(with_deleted != null){
      params.set('with_deleted', with_deleted);
    }

    if(mail != null){
      params.set('mail', mail);
    }

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      var name = null
      if(sync === 0){
        name = "sync"
      }else  if(no_paid === true){
        name = "paid"
      }
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_LIST_LOADED,
          list:data.books,
          name:name
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getTodayList(token,created_at_from,created_at_to){

    const today = new Date()
    let params = new URLSearchParams();
    params.set('created_at_from', format(today, 'yyyy-MM-dd'));
    params.set('created_at_to', format(today, 'yyyy-MM-dd'));
    params.set('with_deleted', true);



    fetch(config.get("API_PATH") + config.get("API_BOOK") + "?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_TODAY_LIST_LOADED,
          list:data.books
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getDetail(token, id){
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  sync(token, id){

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id + "/sync", {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_SYNCED,
        })
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          item:data
        })
        BookActions.getList(token, null, new Date(), null, 0)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_SYNC_0")
        })
      }
    })
  },

  mail(token, id, type){
    const _item = {
      type:type
    }
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id + "/mail", {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        MailHistoryActions.getList(token,id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_MAIL_0")
        })
      }
    })
  },
  getByDate(token, date){

    fetch(config.get("API_PATH") + config.get("API_BOOK_DATE") + "/" + date , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_STATUS_LOADED,
          item:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_SYNC_0")
        })
      }
    })
  },

  create(token, item, accommodation_id, reload_list){

    var _item = {
      accommodation_id:Number(accommodation_id),
      start_date:format(item.start_date, 'yyyy-MM-dd'),
      days:Number(item.days),
      person_number:Number(item.person_number),
      room_id:Number(item.room_id),
      plan_id:item.plan_id ? Number(item.plan_id) : null,
      name:item.name,
      address:item.address,
      tel:item.tel,
      mail:item.mail,
      mini_memo:item.mini_memo,
      price:Number(item.price),
      via:101
    }

    fetch(config.get("API_PATH") + config.get("API_BOOK") , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          item:data
        })
        if(reload_list){
          const params = cookie.load("book_search_params")

          if(params){
            //BookActions.getList(token, params.accommodation_id, new Date(params.stay_from), new Date(params.stay_to), params.sync, params.no_paid)
          }
        }
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_BOOK_CREATE")
        })
      }
    })



  },

  update(token, id, item, reload_list){

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        console.log(data)
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          item:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_BOOK_UPDATE")
        })
      }
    })
  },

  updateParts(token, id, params){

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_BOOK_UPDATE")
        })
      }
    })
  },

  delete(token,id ,mail){

    let params = new URLSearchParams();

    if(mail){
      params.set('mail', mail);
    }

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + id + "?" + params.toString(), {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_DELETED,
          id:id
        })
        BookActions.getDetail(token, id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  setItem(item){

    AppDispatcher.dispatch({
      actionType: Constants.BOOK_DETAIL_SET,
      item:item
    })
  },
  updateForm(name, value){

    AppDispatcher.dispatch({
      actionType: Constants.BOOK_UPDATE_FORM,
      name:name,
      value:value
    })
  },

  setSearchParam(name,value) {
    AppDispatcher.dispatch({
      actionType: Constants.BOOK_SET_SEARCH_PARAM,
      name:name,
      value:value
    })
    
  }


}

export default BookActions
