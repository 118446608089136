import React,{useState,useEffect,useCallback} from 'react'

import DatePicker from "react-datepicker"
import TimeSheetActions from "../../../actions/TimeSheetActions"
import AuthStore from "../../../stores/AuthStore"
import TimeSheetStore from "../../../stores/TimeSheetStore"

import "../../../styles/payment.css"
import "../../../styles/alert.css"


function TimeSheetGroupCreateBox(props:PropsValue) {

  const [item, setItem] = useState([]);
  const [message, setMessage] = useState(null)


  const stableTimeSheetGroupChangeHandler = useCallback(timeSheetGroupChangeHandler,[])

  useEffect(() => {
    TimeSheetStore.addChangeListener(stableTimeSheetGroupChangeHandler)
    return function cleanup() {
      TimeSheetStore.removeChangeListener(stableTimeSheetGroupChangeHandler)
    };
  },[stableTimeSheetGroupChangeHandler]);


  function timeSheetGroupChangeHandler(){
    setItem(
      TimeSheetStore.getItem()
    )
  }

  function create(){
    const item = TimeSheetStore.getItem()
    //validationa
    if(item.end_at && item.start_date && item.end_date ){
      if(item.id === 0) {
        TimeSheetActions.create(AuthStore.getToken(), item)
      }
      props.cancell()
    }else {
      setMessage("全ての項目を入力してください")
    }
  }

  function changeDateHandler(value, name){
    TimeSheetActions.updateItem(name,value)
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">シフト日程リクエスト</div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">リクエスト日程</div>
                <div className="item-content">
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="開始日" dateFormat="yyyy-MM-dd" selected={item ? item.start_date : null} onChange={date => changeDateHandler(date,"start_date")} />
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="終了日" dateFormat="yyyy-MM-dd" selected={item ? item.end_date : null } onChange={date => changeDateHandler(date,"end_date")} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">登録締め切り日</div>
                <div className="item-content">
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="締め切り日" dateFormat="yyyy-MM-dd" selected={item ? item.end_at : null} onChange={date => changeDateHandler(date,"end_at")} />
                </div>
              </div>
            </div>
          </div>
          <div className="message" >{message}</div>
          <div className="btn-box">

            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={create}>送信</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default TimeSheetGroupCreateBox
