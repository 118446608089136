import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import TimeSheetGroup from "../vo/TimeSheetGroup"

const CHANGE_EVENT = "change"
const DELETED = "deleted"


var _items = []
var _item = null

var setList = (items) => {
  _items = items
}

var setItem = (item) => {
  _item = item
}

var update = (name, value) => {
   _item = _item.set(name,value)
}


var TimeSheetStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getFromList(id){
    for(var i = 0 ; i < _items.length; i++){
      if(_items[i].id === id ) return _items[i]
    }
    return null
  },

  getItem(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

  createObject(item) {
    return new TimeSheetGroup({
      id: item.id,
      end_at: item.end_at,
      start_date: item.start_date,
      end_date: item.end_date
    })
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.TIMESHEET_GROUP_LIST_LOADED:

      setList(action.list);
      TimeSheetStore.emitChange()
    break;
    case Constants.TIMESHEET_GROUP_SET_ITEM:
    case Constants.TIMESHEET_GROUP_ITEM_LOADED:

      setItem(action.item);
      TimeSheetStore.emitChange()
    break;
    case Constants.TIMESHEET_GROUP_UPDATE:
      update(action.name, action.value);
      TimeSheetStore.emitChange()
    break;

    case Constants.TIMESHEET_GROUP_DELETED:
      TimeSheetStore.emitChange(DELETED)
    break;

    default:
  }
})
export default TimeSheetStore
