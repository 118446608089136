import { useState } from 'react';
import AuthAction from './AuthAction';


function useAuthState() {

  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)


  const completedHandler = (data) => {
    setToken(data)
  }



  const errorHandler = (data) => {
    //window.location.href = "/login"
    setError(data)
  }


  const getToken = () => {
    AuthAction.getToken( completedHandler, errorHandler)
  }
  
  const logout = () => {
    AuthAction.logout()
    window.location.href = "/login"
  }


  return { token:token, loginError:error, auth:getToken, getToken, logout:logout};
}

export default useAuthState