import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import BookActions from "./BookActions"

var BookOptionActions = {


  edit(token, book_id, item){

    const str =  item.id ? ("/" + item.id) : ""
    const _item = {
      option_id:item.option_id,
      unit:Number(item.unit),
      days:Number(item.days),
      comment:item.comment
    }
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" +book_id + config.get("API_OPTION") + str, {
      method: item.id ? "PATCH" : "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        BookActions.getDetail(token,book_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  update(token, book_id, id, paid_at){

    const _item = {
      paid_at:paid_at ? new Date() : null
    }
    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" +book_id + config.get("API_OPTION") + "/" + id, {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        BookActions.getDetail(token,book_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  delete(token, book_id, id){

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" +book_id + config.get("API_OPTION") + "/" + id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        BookActions.getDetail(token,book_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },



  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.BOOK_OPTION_UPDATE,
      name:name,
      value:value
    })
  },




  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.BOOK_OPTION_SET_ITEM,
      item:item
    })
  }



}

export default BookOptionActions
