import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';


var AccommodationActions = {


  getList(token,callback = null){
   
    
    const storage = sessionStorage
    let list = null
    let company = JSON.parse(storage.getItem('company'))

    if(list && company){
      AppDispatcher.dispatch({
        actionType: Constants.ACCOMMODATION_LIST_LOADED,
        list:list
      })
      AppDispatcher.dispatch({
        actionType: Constants.COMPANY_INFO_LOADED,
        data:company
      })

      if(callback){
        for(var i = 0; i < list.length; i++){
          callback(token,list[i].id)
        }
      }
    }else{
      fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION"), {
        method: "GET",
        headers: {

            'Authorization': 'Bearer ' + token
        },
      }).then(function(response) {

        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
           window.location.href = config.get("LOGIN_URL")
        }else{
          return  {"error":response.status}
        }
      }).then(function(data) {

        if(data && !data.error){
          AppDispatcher.dispatch({
            actionType: Constants.ACCOMMODATION_LIST_LOADED,
            list:data.accommodations
          })
          AppDispatcher.dispatch({
            actionType: Constants.COMPANY_INFO_LOADED,
            data:data.company
          })

          storage.setItem('accommodations', JSON.stringify(data.accommodations), { path: '/' })
          storage.setItem('company', JSON.stringify(data.company), { path: '/' })

          if(callback){
            for(var i = 0; i < data.accommodations.length; i++){
              callback(token,data.accommodations[i].id)
            }
          }


        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            code:data.error
          })
        }
      })
    }


  },



}

export default AccommodationActions
