import React, { useState, useEffect,useCallback } from 'react'
import Select from 'react-select'
import AccommodationStore from '../../../stores/AccommodationStore'
import "../../../styles/selecter.css"

function AccommodationSelect(props:PropsValue) {

  const [accommodation_list, setAccommodation] = useState([]);
  const stableAccommodationHandlerr = useCallback(accommodationChangeHandler, [])

  const dot = (color = '#ccc', mr = 0) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      height: 10,
      marginRight: mr + "px",
      width: 10,
    },
  });

const accStyles = {
  control: styles => (
    { ...styles,
      borderRadius:'2px',
      boxShadow:'none ',
     }
  ),

  option: (styles, { data }) => {
    return {
      ...styles,
      ...dot(data.color,4) ,
    };
  },


  container: styles => (
    { ...styles,
      backgroundColor: 'white',
      width:'100%',
    }
  ),

  multiValue: (styles, { data }) => {

     return {
       ...styles,
       ...dot(data.color) ,
       backgroundColor: 'white',
     };
   },

};


  useEffect(() => {
    AccommodationStore.addChangeListener(stableAccommodationHandlerr)
    accommodationChangeHandler()
    return function cleanup() {
      AccommodationStore.removeChangeListener(stableAccommodationHandlerr)
    };
  },[stableAccommodationHandlerr]);


  function accommodationChangeHandler(){

    setAccommodation(
      AccommodationStore.getList().map( item => {
        return {value:item.id, label:item.name , color:"#" + item.color}
      })
    )
  }

  function changeHandler(selected){
    props.changeHandler(selected)
  }

  return(
    <Select
      options={accommodation_list}
      isMulti
      styles = {accStyles}
      placeholder = "対象施設を選択"
      onChange = {changeHandler}
    />
  )
}

export default AccommodationSelect
