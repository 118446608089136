import React from 'react'
import { format,parseISO } from 'date-fns'

import '../../../styles/mail.css'

function ReceiveMailItem(props:PropsValue) {
  const timeZone = 'Asia/Tokyo'
  return(
    <div className="receive-mail">
      <div className="title">{props.item.title}</div>
      <div className="from">From: {props.item.from_address} </div>
      <div className="from">{format(parseISO(props.item.created_at), 'yyyy年M月d日 HH時mm分' ,{ timeZone:timeZone })}</div>
      <div className="body"  dangerouslySetInnerHTML={{ __html :props.item.body.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></div>
  </div>
  )
}

export default ReceiveMailItem
