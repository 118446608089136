import React, { useState, useEffect,useCallback } from 'react'
import RoomActions from '../../../actions/RoomActions';
import OptionActions from "../../../actions/OptionActions"
import SupplierActions from "../../../actions/SupplierActions"
import AuthStore from "../../../stores/AuthStore"
import OptionStore from "../../../stores/OptionStore"
import SupplierStore from "../../../stores/SupplierStore"
import OptionMaster from "../../../vo/OptionMaster"
import Select from 'react-select'
import RoomStore from '../../../stores/RoomStore';
import {selectFormStyles} from "./selectStyle"
import Checkbox from "./form/Checkbox"
import "../../../styles/alert.css"

function OptionMasterBox(props:PropsValue) {

  const [item, setItem] = useState(null);
  const [message, setMessage] = useState(null)
  const [room_list, setRoomList] = useState([])
  const [supplier_list, setSupplierList] = useState([])
  const stableOptionMasterChangeHandler = useCallback(optionMasterChangeHandler, [])
  const stableRoomChangeHandler = useCallback(roomChangeHandler,[])
  const stableSupplierChangeHandler = useCallback(supplierChangeHandler,[])
  useEffect(() => {
    RoomStore.addChangeListener(stableRoomChangeHandler)
    OptionStore.addChangeListener(stableOptionMasterChangeHandler)
    SupplierStore.addChangeListener(stableSupplierChangeHandler)
    RoomActions.getList(AuthStore.getToken(),props.accommodation_id)
    OptionActions.setItem(props.item ? props.item : OptionMaster())
    SupplierActions.getList(AuthStore.getToken())
    return function cleanup() {
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      OptionStore.removeChangeListener(stableOptionMasterChangeHandler)
      SupplierStore.removeChangeListener(stableSupplierChangeHandler)
    };

  },[stableOptionMasterChangeHandler,stableRoomChangeHandler,stableSupplierChangeHandler,props.accommodation_id,props.item]);

  function optionMasterChangeHandler(){
    setItem(OptionStore.getItem())
  }

  function supplierChangeHandler(){
    setSupplierList(
      [{label:"公式", value:null}].concat(SupplierStore.getList().map(item => {
        return {label:item.name, value:item.id}
      }))
    )
  }

  function update(e){
    const target = e.target
    OptionActions.updateForm(target.name, target.value)
  }

  function roomChangeHandler(){
    setRoomList([{label:"全ての部屋で表示", value:null}].concat(RoomStore.getList().map(item => {
      return {label:item.name, value:item.id}
    })))
  }

  function edit(){
    const item = OptionStore.getItem()

    if(!item.name){
      setMessage("名前を入力してください。")
      return false
    }

    if(item.price === null || isNaN(Number(item.price))){
      setMessage("金額を入力してください")
      return false
    }
    if(!item.unit){
      setMessage("単位を入力してください。")
      return false
    }

    OptionActions.edit(AuthStore.getToken(), props.accommodation_id, item)

    props.cancell()
  }

  function changeSelectRoomHandler(e){
    OptionActions.updateForm("room_id", e)
  }

  function changeSelectPartnerHandler(e){
    OptionActions.updateForm("supplier_id", e)
  }

  function userSelectChangeHandler(name, value){
    const item = OptionStore.getItem()
    var target = null
    switch (name) {
      case "user_selection":
        target =  item.user_selection 
        break
      case "is_stay_type":
        target = item.is_stay_type 
        break;
      case "notice":
         target = item.notice 
         break;
      default:
    }
    if(target === 0 ){
      OptionActions.updateForm(name, 1)
    }else{
      OptionActions.updateForm(name,0)
    }
    
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">{props.item ? "オプション編集" : "オプション作成"}  </div>

          <div className="payment-item">
            <div className="section-content list form fix">
              <div className="list-item">
                <div className="item-head">パートナー</div>
                <div className="item-content">
                  {
                    props.item ? item && item.supplier ? item.supplier.name  : "公式"
                    :
                    <div className="select w100p">
                      <Select
                          options={supplier_list}
                          placeholder = "パートナー"
                          onChange = {(e) => changeSelectPartnerHandler(e.value)}
                          styles = {selectFormStyles}
                      />
                    </div>
                  }

                </div>
              </div>
              <div className="list-item">
                <div className="item-head">名前</div>
                <div className="item-content">
                  <input type="text" value={item ? item.name : null} className="full" name="name" onChange={update} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">金額</div>
                <div className="item-content">
                  <input type="text" value={item ? item.price : null} className="full" name="price" onChange={update} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">単位</div>
                <div className="item-content">
                  <input type="text" value={item ? item.unit : null} className="full" name="unit" onChange={update} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">登録可能数</div>
                <div className="item-content">
                  <input type="text" value={item ? item.unit_max : null} className="full" name="unit_max" onChange={update} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">ユーザー表示</div>
                <div className="item-content">
                  <Checkbox  name="user_selection"  selected={item ? [item.user_selection] : []} 
                    items={[{value:1, text:"ユーザーに表示"}]}
                    changeHandler={userSelectChangeHandler}
                  />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">当日受付</div>
                <div className="item-content">
                  <Checkbox  name="is_stay_type"  selected={item ? [item.is_stay_type] : []} 
                    items={[{value:1, text:"当日受付"}]}
                    changeHandler={userSelectChangeHandler}
                  />
                </div>
              </div>


              <div className="list-item">
                <div className="item-head">メール通知</div>
                <div className="item-content">
                  <Checkbox  name="notice"  selected={item ? [item.notice] : []} 
                    items={[{value:1, text:"メールで通知する"}]}
                    changeHandler={userSelectChangeHandler}
                  />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">説明</div>
                <div className="item-content">
                  <textarea value={item ? item.description : null} className="full" name="description" onChange={update} />
                </div>
              </div>
              <div className="list-item">
                <div className="item-head">対象部屋</div>
                <div className="item-content">
                {
                  props.item ? item && item.room_id ? RoomStore.getName(item.room_id)  : "全ての部屋"
                  :
                  <div className="select w100p">
                    <Select
                        options={room_list}
                        placeholder = "対象部屋"
                        onChange = {(e) => changeSelectRoomHandler(e.value)}
                        styles = {selectFormStyles}
                    />
                  </div>
                }
                </div>
              </div>





            </div>
          </div>
          <div className="message">{message}</div>
          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={edit}>{props.item ? "更新" : "作成"}</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default OptionMasterBox
