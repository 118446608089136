import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';

import AuthStore from '../../../stores/AuthStore';
import RoomActions from '../../../actions/RoomActions';
import RoomStore from '../../../stores/RoomStore';
import ErrorStore from '../../../stores/ErrorStore';
import RoomItem from "../parts/RoomItem"
import MessageBox from '../parts/MessageBox'
import Alert from "../parts/Alert"
import Menu from '../parts/Menu';
import Header from '../parts/Header';


function RoomListApp(props:PropsValue) {

  const { accommodation_id } = useParams();

  const [list, setList] = useState(null)
  const [detail, setDetail] = useState(null)
  const [pannelCss, setPannelCss] = useState("full")

  const [alert, setAlert] = useState(null)

  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableRoomChangeHandler = useCallback(roomChangeHandler, [])
  const stableRoomCreatedHandler = useCallback(roomCreatedHandler,[])
  const stableRoomDeletedHandler = useCallback(roomDeletedHandler,[])
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])



  useEffect(() => {
    RoomStore.addChangeListener(stableRoomChangeHandler)
    RoomStore.addChangeListener(stableRoomCreatedHandler, "created")
    RoomStore.addChangeListener(stableRoomDeletedHandler, "deleted")
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    RoomActions.getList(AuthStore.getToken(),accommodation_id)
    return function cleanup() {
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      RoomStore.removeChangeListener(stableRoomCreatedHandler, "created")
      RoomStore.removeChangeListener(stableRoomDeletedHandler, "deleted")
    };

  },[stableRoomChangeHandler,stableRoomCreatedHandler,stableRoomDeletedHandler,stableErrorChangeHandler,accommodation_id]);


  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
  }

  function roomCreatedHandler(){
    setBoxError(0)
    setBoxMessage("部屋が作成されました。")
    setBoxClass("active")
  }
  function roomDeletedHandler(){
    setBoxError(0)
    setBoxMessage("部屋が削除されました。")
    setBoxClass("active")
  }


  function roomChangeHandler(){

    setList(RoomStore.getList().map( item => {
      return <div className="mail-list-item" key={"plan_list_" + item.id}>
          <div className="name">{item.name}</div>
          <div className="d100">{item.max_num}部屋</div>
          <div className="button wide-hidden" onClick={() => openPannel(item)}>設定</div>
          <div className="button wide-hidden" onClick={() => deleteItem(item.id)}>削除</div>
        </div>
    }))
  }


  function openPannel(item){
    setPannelCss("wide")

    setDetail(<RoomItem item={item} accommodation_id={accommodation_id} closePannel={closePannel} />)
  }

  function closePannel(){
    setPannelCss("full")
    setDetail(null)
  }

  function createNew(){
    setPannelCss("wide")
    setDetail(<RoomItem item={{id:0}} accommodation_id={accommodation_id} closePannel={closePannel} />)
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="部屋を削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function cancell(){
    setAlert(null)
  }

  function deleteExe(id){
    setAlert(null)
    RoomActions.delete(AuthStore.getToken(), accommodation_id, id)
  }

  function closeMessageBox(){
    setBoxClass("close")
  }

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id} />
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="free-pannel scrollable-vertical">
              <section className={"pannel " + pannelCss}>
                <div className="section-header">
                  <div className="title">部屋一覧</div>
                  <div className="right-menu">
                    <div className="inline-link" onClick={createNew}><i className="fas fa-plus-circle"></i> 新規部屋作成</div>
                  </div>
                </div>

                <div className="mail-list-item header">
                  <div className="name">部屋名</div>
                  <div  className="d100">部屋数</div>
                  <div  className="button wide-hidden">設定</div>
                  <div  className="button wide-hidden">削除</div>
                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{list}</div>
                </div>


              </section>
            </div>
          </div>
          {detail}
          {alert}
          <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
        </main>
    </div>
  )
}

export default RoomListApp
