import React from 'react'
import {  Draggable } from "react-beautiful-dnd";

import '../../../../styles/movable.css'
import RoomStore from "../../../../stores/RoomStore"

function FaqItem(props:PropsValue) {


  return(
    <Draggable key={"item_" + props.item.id} draggableId={"item_" + props.item.id} index={props.index}>
    {(provided) => (
      <div className="list-item movable-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <div className="w300">{props.item.question}</div>
        <div className="free">{props.item.answer}</div>
        <div className="w130">{RoomStore.getName(props.item.room_id).substr(0,18)}</div>
        <div className="w50 link" onClick={() => props.editItem(props.item)}>編集</div>
        <div className="w50 link" onClick={() => props.deleteItem(props.item.id)}>削除</div>
      </div>
    )}
    </Draggable>
  )
}

export default FaqItem
