import React, { useState, useEffect,useCallback } from 'react'

import { format, parseISO,differenceInDays,addDays } from 'date-fns'
import {  useParams} from 'react-router-dom';
import AccommodationStore from "../../stores/AccommodationStore"
import UserActions from "../../actions/UserActions"
import UserStore from "../../stores/UserStore"
import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import TimeSheetStore from '../../stores/TimeSheetStore';
import TimeSheetActions from '../../actions/TimeSheetActions';

import Menu from './parts/Menu';
import Header from './parts/Header';

import MessageBox from './parts/MessageBox'

import '../../styles/timesheet.css'


function  TimeSheetDetailApp(props:PropsValue) {

  const { id } = useParams();

  const [item, setItem] = useState(null)
  const [header, setHeader] = useState(null)
  const [boxMessage, setBoxMessage] = useState({message:"", box_class:"", error:0})
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])
  const stableTimeSheetGroupChangeHandler = useCallback(timeSheetGroupChangeHandler, [])
  useEffect(() => {
    TimeSheetStore.addChangeListener(stableTimeSheetGroupChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    UserStore.addChangeListener(stableTimeSheetGroupChangeHandler)
    TimeSheetActions.getItem(AuthStore.getToken(),id)
    UserActions.getList(AuthStore.getToken())
    return function cleanup() {
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      UserStore.removeChangeListener(stableTimeSheetGroupChangeHandler)
      TimeSheetStore.removeChangeListener(stableTimeSheetGroupChangeHandler)
    };

  },[stableErrorChangeHandler,stableTimeSheetGroupChangeHandler,id]);

  function errorChangeHandler(){

    setBoxMessage({message:ErrorStore.getMessage(), box_class:"active", error:1})
  }


  function timeSheetGroupChangeHandler(){
    const _item = TimeSheetStore.getItem()
    const accommodations = AccommodationStore.getList()
    const users = UserStore.getList()
    if(_item){
      const header_day = [  <div className="day">日付</div>]
      const header_acc = accommodations.map( item => {
        return <div className="accommdation"><div style={{backgroundColor: '#' + item.color}} className="acc_icon">{item.short_name}</div></div>
        }
      )

      var header_user = []
      for(var k = 0; k < users.length; k++){
        if(users[k].user_type === 2){
          header_user.push(<div className="user">{users[k].name}</div>)
        }
      }

      setHeader(header_day.concat(header_acc).concat(header_user))
      const start_day = parseISO(_item.start_date)
      const end_day = parseISO(_item.end_date)
      const dif_days = differenceInDays( end_day, start_day)
      const current_day = parseISO(_item.start_date)
      const items = []

      for(var i = 0; i <= dif_days ; i++){
        const c_d = addDays(current_day,i)
        const acc = accommodations.map( item => {
           return <div className="accommdation">y</div>
           }
        )
        items.push(
          <div className="calendar-day">
            <div className="day">{format(c_d,"M/d")}</div>
            {acc}
          </div>
        )
      }
      setItem(items)
    }
  }

  function closeMessageBox(){
    setBoxMessage({message:boxMessage.message, box_class:"close", error:boxMessage.error})
  }


  return(
    <div className="inner">
        {alert}

        <main className="main">
          <Menu selected="timesheet"/>
          <div className="main-content">
            <Header title="シフト管理 "/>

            <section className="timesheet-calendar-wrap pannel full ">
              <div className="mail-list-item header fix">
               {header}
              </div>
              <div className="timesheet-calendar scrollable-container">
                <div className="calendar-items">
                    {item}
                </div>      
              </div>
            </section>
          </div>

        </main>
        <MessageBox message={boxMessage.message} error={boxMessage.error} main_class={boxMessage.box_class} closeMessageBox={closeMessageBox}/>


    </div>
  )
}

export default TimeSheetDetailApp
