import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format } from 'date-fns'


var CalendarInfoActions = {

  getTodayInfo(token, accommodation_id){
    CalendarInfoActions.getList(token, accommodation_id, new Date(), new Date())
  },

  getList(token, accommodation_id, from, to,createIfZero = false){


    let params = new URLSearchParams();
    params.set('start_date', format(from, 'yyyy-MM-dd'));
    params.set('end_date', format(to, 'yyyy-MM-dd'));

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_CALENDAR_INFO") + "?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {


      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.CALENDAR_INFO_LIST_LOADED,
          list:data.comments,
        })

        if(createIfZero && data.comments.length === 0){
          AppDispatcher.dispatch({
            actionType: Constants.CALENDAR_INFO_CREATE_NEW,
            accommodation_id:accommodation_id,
            target_date:from
          })
        }
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  create(token, item){

    var _item = {
      target_date:format(item.target_date, 'yyyy-MM-dd'),
      comment:item.comment
    }
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +item.accommodation_id + config.get("API_CALENDAR_INFO"), {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)

    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.CALENDAR_INFO_DELETE,
          id:0
        })
        CalendarInfoActions.getList(token, item.accommodation_id, item.target_date,item.target_date )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_SYNC_0")
        })
      }
    })



  },

  update(token, accommodation_id ,id , comment){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_CALENDAR_INFO") +  "/" + id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({comment:comment})
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.CALENDAR_INFO_UPDATED,
          item:data,
          id:id
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  delete(token,accommodation_id,id,target_date){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_CALENDAR_INFO") +  "/" + id , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.CALENDAR_INFO_DELETE,
          id:id
        })
        CalendarInfoActions.getList(token, accommodation_id, target_date,target_date,true )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  updateComment(id,comment){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_INFO_UPDATE,
      id:id,
      comment:comment
    })
  }


}

export default CalendarInfoActions
