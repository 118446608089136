import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var OptionActions = {


  getList(token,accommodation_id,room_id){
    let params = new URLSearchParams();
    if(room_id){
      params.set('room_id', room_id);
    }

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_OPTION") + "?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.OPTION_LIST_LOADED,
          list:data,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  edit(token, accommodation_id, item){
    const str =  item.id ? ("/" + item.id) : ""
    const _item = {
      id:item.id,
      name:item.name,
      price:Number(item.price),
      unit:item.unit,
      unit_max:item.unit_max ? Number(item.unit_max) : 0,
      description:item.description,
      supplier_id: item.supplier_id,
      room_id:item.room_id ? Number(item.room_id) : null,
      user_selection:Number(item.user_selection),
      is_stay_type:Number(item.is_stay_type),
      notice:Number(item.notice),
      display_order:Number(item.display_order)
    }
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_OPTION") + str, {
      method: item.id ? "PATCH" : "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        OptionActions.getList(token,accommodation_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },



  delete(token,accommodation_id, id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + config.get("API_OPTION") + "/" + id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        OptionActions.getList(token,accommodation_id )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  updateList(token, accommodation_id, items){

    AppDispatcher.dispatch({
      actionType: Constants.OPTION_LIST_LOADED,
      list:items
    })
    for(var i = 0; i < items.length; i++){
      if(items[i].display_order !== i) {
        const _item = items[i].set("display_order", i )
        OptionActions.edit(token, accommodation_id, _item)
      }
    } 
  },

  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.OPTION_UPDATE,
      name:name,
      value:value
    })
  },




  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.OPTION_SET_ITEM,
      item:item
    })
  }



}

export default OptionActions
