import React, { useState, useEffect,useCallback } from 'react'

import config from 'react-global-configuration';
import { format,parseISO } from 'date-fns'

import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import TimeSheetStore from '../../stores/TimeSheetStore';
import TimeSheetActions from '../../actions/TimeSheetActions';
import TimeSheetGroup from '../../vo/TimeSheetGroup';

import Menu from './parts/Menu';
import Header from './parts/Header';
import Alert from './parts/Alert';
import MessageBox from './parts/MessageBox'
import TimeSheetGroupCreateBox from "./parts/TimeSheetGroupCreateBox"
import '../../styles/customer.css'
import '../../styles/user.css'

function  TimeSheetListApp(props:PropsValue) {


  const [list, setList] = useState([]);
  const [group_box, setGroupBox] = useState([])

  const [alert, setAlert] = useState(null)

  const [boxMessage, setBoxMessage] = useState({message:"", box_class:"", error:0})
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableTimeSheetGroupChangeHandler = useCallback(timeSheetGroupChangeHandler, [])
  useEffect(() => {
    TimeSheetStore.addChangeListener(stableTimeSheetGroupChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    TimeSheetActions.getList(AuthStore.getToken())
    return function cleanup() {
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      TimeSheetStore.removeChangeListener(stableTimeSheetGroupChangeHandler)
    };

  },[stableErrorChangeHandler,stableTimeSheetGroupChangeHandler]);

  function errorChangeHandler(){
    setBoxMessage({message:ErrorStore.getMessage(), box_class:"active", error:1})
  }

  function editItem(id, release){
    if(release === 0){
      window.location.href = "/m/timesheet/" + id
    }
  }

  function timeSheetGroupChangeHandler(){
    setList(
      TimeSheetStore.getList().map( item => {
        return (
          <div className="mail-list-item">
            <div className="d400">{format(parseISO(item.start_date), "yyyy年M月d日")} - {format(parseISO(item.end_date), "yyyy年M月d日")}</div>
            <div className="d300">{format(parseISO(item.end_at), "yyyy年M月d日")}</div>
            <div  className="auto">{
              item.user_no_record.map( user =>{
                return (<span className="line-list">{user.name}</span>)
                }
              )
            }</div>
            <div className="d100 center" onClick={() => editItem(item.id, item.release_flg)}>{item.release_flg === 1 ? "リリース済" : <span className="link">編集</span>}</div>
            <div className="delete-f  wide-hidden" onClick={() => deleteItem(item.id)}>削除</div>
          </div>
        )
      })
    )
  }



  function userDeletedHandler(){
    TimeSheetStore.removeChangeListener(userDeletedHandler, "deleted")
    setBoxMessage({message:config.get("MESSAGE_TIMESHEET_GROUP_DELETED"), box_class:"active", error:0})
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="シフトグループを削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }


  function deleteExe(id){
    setAlert(null)
    TimeSheetStore.addChangeListener(userDeletedHandler, "deleted")
    TimeSheetActions.delete(AuthStore.getToken(),id)
  }


  function cancell(){
    setAlert(null)
  }

  function closeMessageBox(){
    setBoxMessage({message:boxMessage.message, box_class:"close", error:boxMessage.error})
  }

  function closeBox(){
    setGroupBox(null)
  }
  function createGroup(){
    setGroupBox(<TimeSheetGroupCreateBox cancell={closeBox} />)
    TimeSheetActions.setItem(TimeSheetGroup({}))
  }




  return(
    <div className="inner">
        {alert}

        <main className="main">
          <Menu selected="timesheet"/>
          <div className="main-content">
            <Header title="シフト管理"/>
            <div className="open-close-section">
              <section className={"pannel "}>
                <div className="section-header">
                    <div className="button-create" onClick={createGroup} ><i className="fas fa-plus"></i><div>新規作成</div></div>
                </div>

                <div className="mail-list-item header">
                  <div className="d400">期間</div>
                  <div className="d300">締め切り日</div>
                  <div  className="auto">未登録者</div>
                  <div  className="d100">リリース</div>
                  <div className="delete-h  wide-hidden">削除</div>
                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{list}</div>
                </div>

              </section>
            </div>

          </div>
        </main>
        <MessageBox message={boxMessage.message} error={boxMessage.error} main_class={boxMessage.box_class} closeMessageBox={closeMessageBox}/>
        {group_box}

    </div>
  )
}

export default TimeSheetListApp
