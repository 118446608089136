import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import { isSameDay, addDays,parseISO } from 'date-fns'
import Book from "../vo/Book"


const CHANGE_EVENT = "change"
const SYNC = "sync"
const PAYMENT = "payment"
const PAYMENT_DELETED = "payment_deleted"
const BOOK_DELETED = "deleted"
const TODAY_LOADED = "today_loaded"
const SEARCH_PARAM = "search_param"

var _items = []
var _list = []
var _item = Book()
var _status =  null //Dashboard様
var _today_list = []

var _search_start_date = null
var _search_end_date = null

var setList = (items) => {

  _items = items
}

var setStatus = (item) =>{
  _status = item
}

var setNamedList = (name, items) => {

  var is_find = false
  for(var i = 0; i < _list.length; i++){
    if(_list[i].name === name){
      _list[i].items = items
      is_find = true
      break;
    }
  }
  if(!is_find) _list.push({name:name,items:items})
}

var setDetail = (item) => {

  if(item){
    _item = BookStore.createObject(item)

  }else _item =  Book()

}


var deleteFromList = (id) => {
  //namedist
  for(var i = 0; i < _list.length; i++){
    for(var k = 0; k < _list[i].items.length; k++){
      if(Number(_list[i].items[k].id) === Number(id)){
        _list[i].items.splice(k,1)
      }
    }
  }

  //list
  for(var t = 0; t < _items.length; t++){
    if(Number(_items[t].id) === Number(id)){
      _items.splice(t,1)
    }
  }
}

var update = (name, value) => {
  _item = _item.set(name,value)
}

var setItem = (item ) => {
  _item = item
}

var setTodayList = (list) => {
  _today_list = list
}

var setSearchParam = (name, value) => {
  switch(name) {
    case "start_date":
        _search_start_date = value
        break;
    case "end_date" :
        _search_end_date = value
        break;
    default: 
  }
}

var BookStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getStatus(){
    return _status
  },
  getTodayList(){
    return _today_list
  },
  getNamedList(name){
    for(var i = 0; i < _list.length; i++){
      if(_list[i].name === name){
        return _list[i].items
      }
    }
    return []
  },

  getSearchValue(name) {
    switch(name) {
      case "start_date":
        return _search_start_date
      case "end_date" :
        return _search_end_date
      default: return null
    }
  },


  getByAccomodationId(id){
    const arr = []
    for(var i = 0; i < _items.length; i++){

      if(_items[i].accommodation_id === id){
        arr.push(_items[i])
      }
    }
    return arr
  },

  getDetail(){
    return _item
  },

  getDataFromDate(target_date){
    var books = []
    for(var i = 0; i < _items.length; i++){
      for(var t = 0; t < _items[i].days; t++){
        if(isSameDay(addDays(new Date(_items[i].start_date),t),target_date )){
          books.push(_items[i])
        }
      }
    }
    return books
  },

  createObject(item) {

    const start_date = parseISO(item.start_date)
    const created_at = parseISO(item.created_at)
    const updated_at = item.updated_at ? parseISO(item.updated_at) : null

    return new Book({
      id: item.id,
      accommodation_id: item.accommodation_id,
      name: item.name,
      mail: item.mail,
      start_date:  start_date,
      person_number: item.person_number,
      days: item.days,
      address:item.address,
      tel:item.tel,
      mini_memo:item.mini_memo,
      memo:item.memo,
      price: item.price,
      via:item.via,
      payment_limit_date: item.payment_limit_date,
      sync: item.sync,
      created_at:created_at,
      updated_at:updated_at,
      delete_flg: item.delete_flg,
      status: item.status,
      payments:item.payments,
      options:item.options,
      uid:item.uid,
      room_name:item.room_name,
      plan_name:item.plan_name,
      room_id:item.room_id,
      plan_id:item.plan_id,
      price_sub:item.price_sub,
      job : item.job
    }
    )
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.BOOK_LIST_LOADED:
      if(action.name){
        setNamedList(action.name, action.list);
      }else{
        setList(action.list);
      }

      BookStore.emitChange()
    break;
    case Constants.BOOK_DETAIL_LOADED:

      setDetail(action.item)
      BookStore.emitChange()
    break;
    case Constants.BOOK_DETAIL_SET:
      setItem(action.item)
      BookStore.emitChange()
    break
    case Constants.BOOK_STATUS_LOADED:
      setStatus(action.item)
      BookStore.emitChange()
    break;
    case Constants.BOOK_SYNCED:
      BookStore.emitChange(SYNC)
    break;
    case Constants.PAYMENT_CREATED:
      BookStore.emitChange(PAYMENT)
    break;
    case Constants.PAYMENT_DELETED:
      BookStore.emitChange(PAYMENT_DELETED)
    break;
    case Constants.BOOK_DELETED:
      deleteFromList(action.id)
      BookStore.emitChange(BOOK_DELETED)
      break;
    case Constants.BOOK_UPDATE_FORM:
      update(action.name,action.value)
      BookStore.emitChange()
    break;
    case Constants.BOOK_TODAY_LIST_LOADED:
      setTodayList(action.list)
      BookStore.emitChange(TODAY_LOADED)
    break;
    case Constants.BOOK_SET_SEARCH_PARAM:
      setSearchParam(action.name,action.value)
      BookStore.emitChange(SEARCH_PARAM)
    break;



    default:
  }


})
export default BookStore
