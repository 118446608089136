import { Record } from 'immutable'


const User = Record({
  id:null,
  name: null,
  accommodations:[],
  mail:null,
  user_type:null,
  password:null
});
export default User;
