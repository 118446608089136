import React from 'react'
import BookOptionActions from "../../../actions/BookOptionActions"
import AuthStore from "../../../stores/AuthStore"
import "../../../styles/alert.css"

function OptionPaidBox(props:PropsValue) {


  function update(){
    const target = props.item.paid_at ? false : true
    BookOptionActions.update(AuthStore.getToken(), props.book_id, props.item.id, target)
    props.cancell()
  }



  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">オプション支払い</div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">オプション名</div>
                <div className="item-content">
                  {props.item.name}
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">金額</div>
                <div className="item-content">
                  {props.item.price.toLocaleString()}円
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">支払い状況</div>
                <div className="item-content">
                  {props.item.paid_at ? "支払済" : "未決済"}
                </div>
              </div>

            </div>
          </div>

          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={update}>{props.item.paid_at ? "未決済に戻す" : "決済済みにする"}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionPaidBox
