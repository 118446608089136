import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


const CHANGE_EVENT = "change"
var _item = null
var _items = []


const setItem = (item) => {
  _item  = item
}


const setList = (items) => {
  _items = items
}



var MailReceiveStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.MAIL_UNSUPPORTED_LIST_LOADED:
      setList(action.list);
      MailReceiveStore.emitChange()
    break;
    case Constants.MAIL_UNSUPPORTED_LOADED:
      setItem(action.item)
      MailReceiveStore.emitChange()
      break
    default:
  }
})
export default MailReceiveStore
