import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import { isAfter,isBefore } from 'date-fns'

const CHANGE_EVENT = "change"
const CHANGE_SELCT = "selected"
const CREATE_UPPER = "create_upper"
const CREATED_UPPER = "created_upper"
const CREATE_BOTTOM = "create_bottom"
const CREATED_BOTTOM = "created_bottom"
const UPDATE_TYPE = "update_type"

var _items = []
var _current_month = null
var _current_date =  null
var _loaded_data =  null
var _type = "row"


var setCurrentMonth = (c) => {
  _current_month = c
}

var setCurrentDate = (c) => {
  _current_date = c
}

var setLoadedData = (c) => {
  _loaded_data = c
}

var setType = (type) => {
  _type = type
}


var CalendarStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getCurrentMonth(){
    return _current_month
  },

  getCurrentDate(){
    return _current_date
  },

  hasData(target_date){
    if(!_loaded_data) return false
    if(isBefore(target_date.start_at, _loaded_data.start_at)){
      return false
    }

    if(isAfter(target_date.end_at, _loaded_data.end_at)){
      return false
    }

    return true
  },

  getType(){
    return _type
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CALENDAR_SELETED:
      setCurrentMonth(action.month);
      CalendarStore.emitChange(CHANGE_SELCT)
    break;

    case Constants.CALENDAR_CREATE_UPPPER:
      CalendarStore.emitChange(CREATE_UPPER)
    break
    case Constants.CALENDAR_CREATED_UPPPER:
      CalendarStore.emitChange(CREATED_UPPER)
    break

    case Constants.CALENDAR_CREATE_BOTTOM:
      CalendarStore.emitChange(CREATE_BOTTOM)
    break
    case Constants.CALENDAR_CREATED_BOTTOM:
      CalendarStore.emitChange(CREATED_BOTTOM)
    break

    case Constants.CALENDAR_CURRENT_DATE:
      setCurrentDate(action.date)
    break

    case Constants.CALENDAR_LOADED_DATE:
      setLoadedData(action.date)
    break
    case Constants.CALENDAR_UPDATE_TYPE:
      setType(action.type)
      CalendarStore.emitChange(UPDATE_TYPE)

    break
    default:
  }
})
export default CalendarStore
