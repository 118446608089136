import { Record } from 'immutable'


const Faq = Record({
  id:null,
  question:null,
  answer: null,
  display_order:null,
  room_id:null,
});
export default Faq;
