import React,{useState} from 'react'
import { format } from 'date-fns'
import AuthStore from "../../../stores/AuthStore"
import BookActions from "../../../actions/BookActions"

import "../../../styles/alert.css"
import Checkbox from "./form/Checkbox"


function CancelBox(props:PropsValue) {

  const [mail_check, setMailCheck] = useState(["1"]);

  function deleteBook(){
    console.log(mail_check)
    BookActions.delete(AuthStore.getToken(), props.item.id, mail_check.length > 0)
    props.cancell()
  }

  function changeHandler(name,value){
    switch (name) {
      case "mail":
        if(mail_check.indexOf(value) >= 0 ){
          setMailCheck([])
        }else{
          setMailCheck(["1"])
        }

        break;
      default:
    }
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">

        <div className="message-box">
        予約の削除
        <div className="alert-message">以下の予約を削除してよろしいですか。</div>
        </div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">宿泊日</div>
                <div className="item-content">{format(props.item.start_date,"yyyy年M月d日")}</div>
              </div>

              <div className="list-item">
                <div className="item-head">宿泊者</div>
                <div className="item-content">{props.item.name}</div>
              </div>
            </div>
          </div>
          <div className="check-box">
            <Checkbox name="mail" selected={mail_check} changeHandler={changeHandler} items={[{value:"1",text:"宿泊者にメールを送信する<span class='sml'>(設定済みの場合)</span>"}]}/>
          </div>
          <div className="btn-box">

            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={deleteBook}>予約を削除</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default CancelBox
