import React,{useState} from 'react'


function TestMailBox(props:PropsValue) {

  const [mail_address, setMailAddress] = useState(null);


  function okHandler(){
    props.executor(mail_address)
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
          <div className="message-box">テストメールを送信します。
            <div className="form-box">
              <input placeholder="テストメール送信先アドレス" type="text" className="full" onChange={e => setMailAddress(e.target.value)} />
            </div>
          </div>

          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={okHandler}>送信</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestMailBox
