import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

const CHANGE_EVENT = "change"


var _items = []

var setList = (items) => {
  _items = items
}



var MailHistoryStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.MAIL_HISTORY_LIST_LOADED:
      setList(action.list);
      MailHistoryStore.emitChange()
    break;

    default:
  }
})
export default MailHistoryStore
