import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format } from 'date-fns'

var CustomerActions = {


  getList(token, accommodations, book_from ,book_to, stay_from, stay_to){

    let params = new URLSearchParams();

    params.set('accommodation_id', accommodations);
    if(book_from){
      params.set('book_from', format(book_from, 'yyyy-MM-dd'));
    }
    if(book_to){
      params.set('book_to', format(book_to, 'yyyy-MM-dd'));
    }
    if(stay_from){
      params.set('stay_from', format(stay_from, 'yyyy-MM-dd'));
    }
    if(stay_to){
      params.set('stay_to', format(stay_to, 'yyyy-MM-dd'));
    }


    fetch(config.get("API_PATH") + config.get("API_CUSTOMER") + "?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.CUSTOMER_LIST_LOADED,
          list:data.data,
          count:data.count
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  setList(list){
    AppDispatcher.dispatch({
      actionType: Constants.CUSTOMER_LIST_LOADED,
      list:list,
      count:list.length
    })
  },
  changeSelect(id,type){
    AppDispatcher.dispatch({
      actionType: Constants.CUSTOMER_SLECTED,
      id:id,
      type:type
    })
  },

  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.UPDATE_CUSTOMER_FORM,
      name:name,
      value:value
    })


  }


}

export default CustomerActions
