import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import FaqActions from '../../../actions/FaqActions';
import RoomActions from '../../../actions/RoomActions';

import AuthStore from '../../../stores/AuthStore';
import FaqStore from '../../../stores/FaqStore';
import RoomStore from '../../../stores/RoomStore';
import ErrorStore from '../../../stores/ErrorStore';

import MessageBox from '../parts/MessageBox'
import Alert from "../parts/Alert"
import Menu from '../parts/Menu';
import Header from '../parts/Header';
import FaqCreateBox from "./parts/FaqCreateBox"
import FaqItem from "./parts/FaqItem"

import Select from 'react-select'
import {selectFormStyles} from "../parts/selectStyle"

function FaqListApp(props:PropsValue) {

  const { accommodation_id } = useParams();

  const [list, setList] = useState(null)
  const [room_list, setRoomList] = useState([])

  const [alert, setAlert] = useState(null)
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")

  const [crateBox, setCrateBox] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableFaqChangeHandler = useCallback(faqChangeHandler, [])
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableRoomChangeHandler = useCallback(roomChangeHandler,[])

  useEffect(() => {
    RoomStore.addChangeListener(stableRoomChangeHandler)
    FaqStore.addChangeListener(stableFaqChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    RoomActions.getList(AuthStore.getToken(),accommodation_id)
    FaqActions.getList(AuthStore.getToken(),accommodation_id)
    return function cleanup() {
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      FaqStore.removeChangeListener(stableFaqChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
    };

  },[stableFaqChangeHandler,stableErrorChangeHandler,stableRoomChangeHandler,accommodation_id]);


  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
  }


  function roomChangeHandler(){
    setRoomList([{label:"全ての部屋で表示", value:null}].concat(RoomStore.getList().map(item => {
      return {label:item.name, value:item.id}
    })))
    faqChangeHandler()
  }

  function faqChangeHandler(){
    setList(FaqStore.getList().map( (item,index) => {
      return <FaqItem item={item} editItem={editItem} deleteItem={deleteItem} index={index}/>
    }))
  }


  function editItem(item){
    setCrateBox(<FaqCreateBox cancell={closeCreteBox} item={item} accommodation_id={accommodation_id} />)
  }

  function createNew(){
    const max = FaqStore.getDisplayOrderMax()
    setCrateBox(<FaqCreateBox cancell={closeCreteBox} display_order = {max + 1} accommodation_id={accommodation_id} />)
  }

  function closeCreteBox(){
    setCrateBox(null)
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function cancell(){
    setAlert(null)
  }

  function deleteExe(id){
    setAlert(null)
    FaqActions.delete(AuthStore.getToken(), accommodation_id, id)
  }


  function closeMessageBox(){
    setBoxClass("close")
  }

  function handleOnDragEnd(result: any) {
    const items = FaqStore.getList()
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    FaqActions.updateList(AuthStore.getToken(), accommodation_id, items);
  }

  function changeSelectRoomHandler(e){
    if(e){
        FaqActions.getList(AuthStore.getToken(),accommodation_id, Number(e))
      /** setList(FaqStore.getList().filter(item => item.room_id === Number(e)).map( (item,index) => {
        return <FaqItem item={item} editItem={editItem} deleteItem={deleteItem} index={index}/>
      })) **/
    }else{
      FaqActions.getList(AuthStore.getToken(),accommodation_id, null)
    }
  }


  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id} />
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="faq"/>

            <div className="free-pannel scrollable-vertical">
              <section className="pannel">
                <div className="section-header">
                  <div className="title">FAQ一覧</div>
                  <div className="select">
                    <Select
                        options={room_list}
                        placeholder = "部屋で絞り込み"
                        onChange = {(e) => changeSelectRoomHandler( e.value)}
                        styles = {selectFormStyles}
                    />
                  </div>
                  <div className="right-menu">
                    <div className="inline-link" onClick={createNew}><i className="fas fa-plus-circle"></i> 新規FAQ作成</div>
                  </div>
                </div>

                <div className="mail-list-item header">
                  <div className="w300">質問</div>
                  <div className="free">回答</div>
                  <div className="w130">部屋</div>
                  <div className="w50">編集</div>
                  <div className="w50">削除</div>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="faqlist">
                  {(provided) => (
                    <div
                      className="faqlist"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {list}
                      {provided.placeholder}
                    </div>
                  )}
                  </Droppable>
                </DragDropContext>

              </section>
            </div>
          </div>
          {alert}
          {crateBox}
          <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
        </main>
    </div>
  )
}

export default FaqListApp
