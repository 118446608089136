import React, {useEffect} from 'react';
import 'balloon-css';
import './baloon.css';
/**
 * Primary UI component for user interaction
 */
export const Baloon = ({
  message,
  ...props
}: DetailWindowProps) => { 

  useEffect(() => {

   
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return (
    <span>
      <button aria-label={message} data-balloon-pos="down"><i className="fas fa-info-circle"></i></button>
    </span>
  );
};

