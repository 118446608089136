import React,{useState} from 'react'

import SyncItemActions from "../../../actions/SyncItemActions"
import AuthStore from "../../../stores/AuthStore"
import "../../../styles/alert.css"

function SyncCreateBox(props:PropsValue) {

  const [target_name, setTargetName] = useState(null);


  function create(){
    SyncItemActions.create(AuthStore.getToken(), props.target_type, props.target_id, target_name)
    props.cancell()
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">同期名称登録</div>

          <div className="payment-item">
            <div className="section-content list form fix">
              <div className="list-item">
                <div className="item-head">名前</div>
                <div className="item-content"><input type="text" value={target_name}  className="full" placeholder="同期名" onChange={(e) => setTargetName(e.target.value)}/></div>
              </div>
            </div>
          </div>

          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={create}>送信</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default SyncCreateBox
