import React, {  } from 'react'

import { format,addDays,parseISO } from 'date-fns'
import '../../../styles/customer.css'
import AccommodationStore from '../../../stores/AccommodationStore'

function BookListItem(props:PropsValue) {


  function openPannel(){
    props.openPannel(props.item.id, props.item.accommodation_id)
  }
  return(

    <div className={props.item.delete_flg === 1 ? "item-list-item deleted" : "item-list-item " } onClick={openPannel}>

        <div  className="accommodation">
          {AccommodationStore.getById(props.item.accommodation_id)
            ? <div style={{backgroundColor: '#' + AccommodationStore.getById(props.item.accommodation_id).color}} className="acc_icon">{AccommodationStore.getById(props.item.accommodation_id).short_name}</div>
            : ""}</div>
        {props.with_room_name ? <div className="w180">{props.item.room_name}</div> : null}
        <div className="w130">{format(new Date(props.item.start_date), 'yyyy年M月d日')}</div>
        {props.with_days ? <div  className="w120">{format(addDays(new Date(props.item.start_date), props.item.days), 'yyyy年M月d日')}</div> : null}
        <div  className="free">{props.item.name}</div>
        <div  className="w80 wide-hidden">{props.item.person_number}人</div>
        <div className="w100 wide-hidden">{props.item.price.toLocaleString()}円</div>
        <div className="w130">{format(parseISO(props.item.created_at), 'yyyy年M月d日')}</div>
        <div className={props.with_days ? "w120 wide-hidden" : "w130 "}>{props.item.payment_limit_date ? format(new Date(props.item.payment_limit_date), 'yyyy年M月d日') : "-"}</div>
        <div  className="w80 wide-hidden"><a href={"/m/books/" + props.item.mail}>履歴</a></div>
        <div className="button wide-hidden">詳細 > </div>
    </div>


  )
}

export default BookListItem
