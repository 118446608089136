import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import CalendarStore from "../stores/CalendarStore"
import config from 'react-global-configuration';
import { format } from 'date-fns'
import cookie from 'react-cookies'

var CalendarActions = {


  changeCurrentMonth(current_month){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_SELETED,
      month:current_month
    })
  },

  setCurrentDate(token, date, accommodation_id){

    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_CURRENT_DATE,
      date:date
    })
    if(!CalendarStore.hasData(date)){

      let params = new URLSearchParams();
      params.set('accommodation_id', accommodation_id);
      params.set('stay_start_date', format(date.start_at,'yyyy-MM-dd'));
      params.set('stay_end_date', format(date.end_at, 'yyyy-MM-dd'));
      params.set('with_consecutive', true);

      cookie.save("book_search_params", {accommodation_id:accommodation_id,stay_from:date.start_at, stay_to:date.end_at,sync:null,no_paid:null}, { path: '/' })

      fetch(config.get("API_PATH") + config.get("API_BOOK") + "?" + params.toString(), {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + token
        },
        }).then(function(response) {
          if(response.status === 200  ){
            const contentType = response.headers.get("content-type");
              if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json()
              } else {
                return {"error":402}
              }
          }else if(response.status === 401){
            window.location.href = config.get("LOGIN_URL")
          }else{
            return  {"error":response.status}
          }
        }).then(function(data) {
        
          if(data && !data.error){

            AppDispatcher.dispatch({
              actionType: Constants.CALENDAR_LOADED_DATE,
              date:date
            })
            AppDispatcher.dispatch({
              actionType: Constants.BOOK_LIST_LOADED,
              list:data.books
            })

          }else{
            AppDispatcher.dispatch({
              actionType: Constants.API_ERROR,
              message:config.get("ERROR_API"),
              code:data.error
            })
          }
        })
    }else{
      //console.log("has data")
    }

  },


  updateCalendarType(type) {
    cookie.save("calendar_type", type, { path: '/' })
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_UPDATE_TYPE,
      type:type
    })
  },
  getCalendarType() {
    const type = cookie.load("calendar_type")
    if(type){
      AppDispatcher.dispatch({
        actionType: Constants.CALENDAR_UPDATE_TYPE,
        type:type
      })
    }
  },

  createUpper(){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_CREATE_UPPPER,
    })
  },

  createdUpper(){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_CREATED_UPPPER,
    })
  },
  createBottom(){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_CREATE_BOTTOM,
    })
  },
  createdBottom(){
    AppDispatcher.dispatch({
      actionType: Constants.CALENDAR_CREATED_BOTTOM,
    })
  }


}

export default CalendarActions
