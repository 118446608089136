
import { Record } from 'immutable'

type CouponProps = {
  id:string | null
  start_date:Date | null
  end_date: Date | null
  discount_type : string | null
  discount_value : number | null
};
const defaultValues: CouponProps = {
  id:null,
  start_date:null,
  end_date:null,
  discount_type:null,
  discount_value:null
};

class Coupon extends Record(defaultValues){


  getTypeString(){
    if(this.discount_type === "rate") return "割引率"
    if(this.discount_type === "price") return "割引金額"
    return "-"
  }

  getUnitString(){
    if(this.discount_type === "rate") return "%"
    if(this.discount_type === "price") return "円"
    return ""
  }

 
}
export default Coupon
