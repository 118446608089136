import { Record } from 'immutable'


const Room = Record({
  id:null,
  name:null,
  max_num:null,
  plans:[]
});
export default Room;
