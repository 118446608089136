import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import User from "../vo/User"

const CHANGE_EVENT = "change"
const CREATED = "created"
const DELETED = "deleted"
const UPDTE_PASSWORD = "update_password"

var _items = []
var _item = null

var setList = (items) => {
  _items = items.map(item => {
    return UserStore.createObject(item)
  })
}

var setItem = (item) => {
  _item = item
}

var update = (name, value) => {
   _item = _item.set(name,value)
}


var UserStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getFromList(id){
    for(var i = 0 ; i < _items.length; i++){
      if(_items[i].id === id ) return _items[i]
    }
    return null
  },

  getItem(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

  createObject(item) {
    return new User({
      id: item.id,
      name: item.name,
      accommodations: item.accommodations,
      mail: item.mail,
      user_type: item.user_type
    })
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.USER_LIST_LOADED:
      setList(action.list);
      UserStore.emitChange()
    break;
    case Constants.USER_SET_ITEM:
      setItem(action.item);
      UserStore.emitChange()
    break;
    case Constants.USER_UPDATE:
      update(action.name, action.value);
      UserStore.emitChange()
    break;
    case Constants.USER_CREATED:
      UserStore.emitChange(CREATED)
    break;
    case Constants.USER_DELETED:
      UserStore.emitChange(DELETED)
    break;
    case Constants.USER_UPDATE_PASSWORD:
      UserStore.emitChange(UPDTE_PASSWORD)
    break;
    default:
  }
})
export default UserStore
