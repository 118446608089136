import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"



var CHANGE_EVENT = "change"

var _item = null



const update = (name,value) => {
  _item = _item.set(name, value)
}

const setItem = (item) => {
  _item = item
}


var BookOptionStore = assign({},EventEmitter.prototype,{


  getItem(){
    return _item
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.BOOK_OPTION_UPDATE:
      update(action.name,action.value);
      BookOptionStore.emitChange()
    break;
    case Constants.BOOK_OPTION_SET_ITEM:
      setItem(action.item);
      BookOptionStore.emitChange()
    break;

    default:
  }


})
export default BookOptionStore
