import React, { useState, useEffect,useCallback,useMemo } from 'react'
import CustomerActions from '../../actions/CustomerActions';
import CustomerStore from '../../stores/CustomerStore';
import CompanyStore from '../../stores/CompanyStore'
import MailPublishStore from '../../stores/MailPublishStore'
import AuthStore from '../../stores/AuthStore';
import MailPublishActions from '../../actions/MailPublishActions';
import MailPublish from "../../vo/MailPublish"
import { parse } from 'papaparse';

import Menu from './parts/Menu';
import Header from './parts/Header';
import CustomerListItem from './parts/CustomerListItem'
import TestMailBox from './parts/TestMailBox'
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone'

import {baseStyle,  activeStyle, acceptStyle, rejectStyle} from "../../styles/droparea.js"
import AccommodationSelect from './parts/AccommodationSelect';

import "react-datepicker/dist/react-datepicker.css";

function PublishCreateApp(props:PropsValue) {

  const [customer_list, setCustomers] = useState([])

  const [bookStartDate, setBookStartDate] = useState(null);
  const [bookEndDate, setBookEndDate] = useState(null);
  const [stayStartDate, setStayStartDate] = useState(null);
  const [stayEndDate, setStayEndDate] = useState(null);

  const [test_mail_box, setTestMailBox] =  useState(null)
  const [send_number, setSendNumber] = useState(null)
  const [file_message, setFileMessage] = useState(null)
  const [message, setMessage] = useState(null)
  ///Mail
  //const [mail_title, setMailTitle] = useState("")
  //const [mail_from_address, setMailFromAddress] = useState("")
  //const [mail_send_at, setSendDate] = useState(new Date())
  //const [mail_body, setBody] = useState("")
  const [mail_publish, setMailPublish] = useState(new MailPublish())
  const [create_btn, setBtn] = useState()

  registerLocale('ja', ja)
  const onDrop = useCallback(acceptedFiles => {

    for(var i = 0; i < acceptedFiles.length; i++){
      if(acceptedFiles[i].size > 5000000){
        setMessage("サイズファイルは5M以内です")
      }else{
        setMessage(null)
        MailPublishActions.updatForm("mail_file", acceptedFiles[i])
      }

    }
  }, [])

  const onDropCsv = useCallback(acceptedFiles => {

    for(var i = 0; i < acceptedFiles.length; i++){
      
      parse(acceptedFiles[i], {
        complete: (results) => {
          const _list =  results.data.map((item, index) => {
            return {name:item[1], mail  :item[0]}
           // return <CustomerListItem item={{name:item[1], mail  :item[0]}} key={"cus_csv" + index} addAddress={addAddress} removeAddress = {removeAddress} />
          })
          //setCustomers( _list )
          CustomerActions.setList(_list)
        },
      });

    }
  }, [])


  const {
    //getRootProps,
    //getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: 'video/*, image/* ,application/pdf',onDrop});

 

  const style = useMemo(() => ({
     ...baseStyle,
     ...(isDragActive ? activeStyle : {}),
     ...(isDragAccept ? acceptStyle : {}),
     ...(isDragReject ? rejectStyle : {})
   }), [isDragActive,isDragAccept,isDragReject]);

 

  const stableCustomerChangeHandlerr = useCallback(customerChangeHandler, [])
  function customerChangeHandler(){
    const list = CustomerStore.getList()
      if(list.length > 0){
          setCustomers(
          CustomerStore.getList().map(item=>{
            return <CustomerListItem item={item} key={"cus_" + item.id} addAddress={addAddress} removeAddress = {removeAddress} />
          })
        )
      }else{
        setCustomers(<div className="message">検索結果がありません。</div>)
      }
    setSendNumber(CustomerStore.getList().length)
  }

  const stableCustomerSelectChangeHandlerr = useCallback(customerSelectChangeHandlerr,[])
  function customerSelectChangeHandlerr(){
    setSendNumber(CustomerStore.getSelectedList().length)
  }

  const stableCompanyChangeHandlerr = useCallback(companyChangeHandler, [])
  function companyChangeHandler(){
    /**const company = CompanyStore.getInfo()
    if(company && !mail_from_address){
      setMailFromAddress(company.mail)
    }**/
  }


  function searchCustomer(){
    const item = CustomerStore.getForm()
    if(item.accommodations && item.accommodations.length > 0){
      const accs = item.accommodations.map(acc => {
        return acc.value
      })
      CustomerActions.getList(AuthStore.getToken(), accs, item.book_start,item.book_end,item.stay_start,item.stay_end)
    }
  }

  const stableSendMail = useCallback(sendMail,[])
  function sendMail(){


    const addresses = CustomerStore.getSelectedList()
 
    const item = MailPublishStore.getItem()
    if(item.mail_title && item.mail_from_address && item.mail_send_at && item.mail_body && addresses.length > 0){
      setBtn(<div className="loading"><i className="fas fa-spinner fa-spin"></i></div>)
      if(item.mail_file){
        const fr = new FileReader();
        fr.onload = (e) => {
          // readAsDataURL実行後の処理
          MailPublishActions.create(AuthStore.getToken(),item.mail_send_at,{
            title:item.mail_title,
            from_address:item.mail_from_address,
            body:item.mail_body,
            users:addresses,
            file:e.target.result,
            file_name:item.mail_file.name
          } )

        }
        fr.readAsDataURL(item.mail_file);
      }else{
        MailPublishActions.create(AuthStore.getToken(),item.mail_send_at,{
          title:item.mail_title,
          from_address:item.mail_from_address,
          body:item.mail_body,
          users:addresses
        } )
      }
    }
  }

  const stableFormChangeHandler = useCallback(formChangeHandler,[])
  function formChangeHandler(){

    const item = CustomerStore.getForm()

    setBookStartDate(item.book_start)
    setBookEndDate(item.book_end)
    setStayStartDate(item.stay_start)
    setStayEndDate(item.stay_end)
    searchCustomer()

  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableMaleChangeHandler = useCallback(mailChangeHandler,[])
  function mailChangeHandler(){
    const item = MailPublishStore.getItem()
    setMailPublish(
      item
    )

    if(item.mail_title && item.mail_from_address && item.mail_send_at && item.mail_body ){
      setBtn(<button className="create half link-btn" onClick={stableSendMail}>送信する</button>)
    }else{
      setBtn(<button className="disable half link-btn" >送信</button>)
    }

    if(item.mail_file){
      setFileMessage(item.mail_file.name)
    }


  }
  useEffect(() => {
    CustomerStore.addChangeListener(stableCustomerChangeHandlerr)
    CustomerStore.addChangeListener(stableCustomerSelectChangeHandlerr,"selected")
    CompanyStore.addChangeListener(stableCompanyChangeHandlerr)
    CustomerStore.addChangeListener(stableFormChangeHandler,"form_change")
    MailPublishStore.addChangeListener(stableMaleChangeHandler)
    return function cleanup() {
      CustomerStore.removeChangeListener(stableCustomerChangeHandlerr)
      CustomerStore.removeChangeListener(stableCustomerSelectChangeHandlerr,"selected")
      CompanyStore.removeChangeListener(stableCompanyChangeHandlerr)
      CustomerStore.removeChangeListener(stableFormChangeHandler,"form_change")
      MailPublishStore.removeChangeListener(stableMaleChangeHandler)
    };

  },[stableCustomerChangeHandlerr,stableCustomerSelectChangeHandlerr,stableCompanyChangeHandlerr,stableFormChangeHandler,stableMaleChangeHandler]);


  function changeAccommodationHandler(selected){
    CustomerActions.updateForm("accommodations",selected)
    searchCustomer()
  }

  function addAddress(mail_add){
    CustomerActions.changeSelect(mail_add,1)
  }
  function removeAddress(mail_add){
    CustomerActions.changeSelect(mail_add,0)
  }


  function changeDateHandler(type, d){
    CustomerActions.updateForm(type,d)
  }
  function cancellTestMail(){
    setTestMailBox(null)
  }
  function sendTestMail(mail_address){
    setTestMailBox(null)
    const item = MailPublishStore.getItem()

    if(item.mail_file){
      const fr = new FileReader();
      fr.onload = (e) => {
        // readAsDataURL実行後の処理
        MailPublishActions.sendTest(AuthStore.getToken(),mail_address,item.mail_title,item.mail_from_address,item.mail_body,e.target.result,item.mail_file ? item.mail_file.name : null)
      }
      fr.readAsDataURL(item.mail_file);
    }else{
      MailPublishActions.sendTest(AuthStore.getToken(),mail_address,item.mail_title,item.mail_from_address,item.mail_body,null,null)
    }



  }
  function openTestMail(){
    setTestMailBox(<TestMailBox cancell={cancellTestMail} executor={sendTestMail}/>)
  }
  function changeMailPubish(e){
    const target = e.target
    MailPublishActions.updatForm(target.name, target.value)
  }

  function changeMailPubishDate(e){
    MailPublishActions.updatForm("mail_send_at", e)
  }

  return(
    <div className="inner">
        {test_mail_box}
        <main className="main">
          <Menu selected="mail"/>
          <div className="main-content">
            <Header title="一斉メール  新規作成"/>
            <div className="open-close-section">
              <section className="wide pannel">
                <div className="section-header">
                    <div className="title">配信先検索</div>
                </div>
                <div className="section-content list form fix">
                  <div className="list-item">
                    <div className="item-head">対象宿泊施設</div>
                    <div className="item-content"><AccommodationSelect  changeHandler={changeAccommodationHandler} /></div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">予約日</div>
                    <div className="item-content">
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="予約日" dateFormat="yyyy/MM/dd" isClearable selected={bookStartDate} onChange={date => changeDateHandler("book_start", date)} />
                      <div className="to-from">〜</div>
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="予約日"　dateFormat="yyyy/MM/dd" isClearable selected={bookEndDate} onChange={date => changeDateHandler("book_end", date)} />
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">宿泊日</div>
                    <div className="item-content">
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy/MM/dd" isClearable selected={stayStartDate} onChange={date => changeDateHandler("stay_start", date)} />
                      <div className="to-from">〜</div>
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日"　dateFormat="yyyy/MM/dd" isClearable selected={stayEndDate} onChange={date => changeDateHandler("stay_end", date)} />
                      </div>
                  </div>

                  <div className="list-item">
                    <div className="item-head">CSVをアップロード</div>
                    <div className="item-content">
                    <Dropzone onDrop={onDropCsv}>
                      {({getRootProps, getInputProps}) => (
                      <div {...getRootProps({className: 'dropzone', style})}>
                          <input {...getInputProps()} />
                          <p>{file_message ? file_message : "添付ファイル(1M以内)"}</p>
                      </div>
                       )}
                    </Dropzone >
                    </div>
                  </div>


                  <div className="customer-list-item header">
                    <div className="check"></div>
                    <div className="name">名前</div>
                    <div  className="mail">メールアドレス</div>
                    <div className="date">最終宿泊日</div>
                  </div>


                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{customer_list}</div>
                </div>
              </section>

              <section className="right pannel">
                <div className="section-header">
                    <div className="title">送信メール設定</div>
                </div>
                <div className="section-content list form fix">
                  <div className="list-item">
                    <div className="item-head">タイトル</div>
                    <div className="item-content"><input type="text" name="mail_title" value={mail_publish.mail_title} className="full" onChange = {changeMailPubish }/></div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">送信者アドレス</div>,
                    <div className="item-content"><input type="text" className="full" name="mail_from_address" value={mail_publish.mail_from_address} onChange = {changeMailPubish }/></div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">送信日時</div>
                    <div className="item-content"><DatePicker showTimeSelect showPopperArrow={false} selected={mail_publish.mail_send_at} locale="ja" placeholderText="送信日時" dateFormat="yyyy/MM/dd HH:mm" onChange={changeMailPubishDate} /></div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">本文</div>
                    <div className="item-content"><textarea className="full big" name="mail_body" onChange={changeMailPubish}>{mail_publish.mail_body}</textarea></div>
                  </div>
                  <div className="list-item">
                    <div className="item-head">添付ファイル</div>
                    <div className="item-content">
                    <Dropzone onDrop={onDrop}>
                      {({getRootProps, getInputProps}) => (
                      <div {...getRootProps({className: 'dropzone', style})}>
                          <input {...getInputProps()} />
                          <p>{file_message ? file_message : "添付ファイル(5M以内)"}</p>
                      </div>
                       )}
                    </Dropzone >
                    </div>
                  </div>

                  <div className="button-box">
                    <div className="message">{message}</div>
                    <div className="mail-number">{send_number ? send_number + "通のメールを送信します。" : null}</div>
                    {create_btn}
                    <div className="text-link" onClick={openTestMail} >テストメールを送信する</div>
                  </div>


                </div>
              </section>
            </div>

          </div>
        </main>
    </div>
  )
}

export default PublishCreateApp
