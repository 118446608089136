
import 'whatwg-fetch'

import Action from '../../../../actions/Action'

const CouponAction = {


  get(token, accommodation_id, callback, errorHandler){
    const path =  "/admin/accommodations/" + accommodation_id + "/coupons"
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },


 
}

export default CouponAction
