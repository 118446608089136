import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import SyncSetting from "../vo/SyncSetting"
import Ikyu from "../vo/setting/Ikyu"


var CHANGE_EVENT = "change"
var _items = []


var setList = (items) => {
  _items = items.map(item => {
    return SyncSettingStore.createObject(item)
  })
}

var update = (service_id,name,value)  =>{
  const index = SyncSettingStore.getIndexByServiceId(service_id)

  if(index !== null){
    _items[index] = _items[index].setIn(["params",name],value)
  }

}

var SyncSettingStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getIndexByServiceId(id){
    for(var i = 0; i < _items.length; i++){
      if(Number(_items[i].service_id) === Number(id)) return i
    }
    return null
  },

  getByServiceId(id){
    for(var i = 0; i < _items.length; i++){
      if(Number(_items[i].service_id) === Number(id)) return _items[i]
    }
    return null
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

  createObject(item) {
    var params = null
    if(item.service_id === 1 &&item.params){
      params = new Ikyu({
        accommodation_id:item.params.accommodation_id,
        id:item.params.id,
        password:item.params.password
      })
    }
    return new SyncSetting({
      id: item.id,
      tag: item.tag,
      mail_to: item.mail_to,
      params: params,
      service_id:item.service_id
    })
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.SYNC_SETTING_LOADED:
      setList(action.list);
      SyncSettingStore.emitChange()
    break;
    case Constants.SYNC_SETTING_UPDATE:
      update(action.service_id,action.name,action.value);
      SyncSettingStore.emitChange()
    break;

    default:
  }


})
export default SyncSettingStore
