import { Record } from 'immutable'


const CustomerSearchForm = Record({
  book_start: null,
  book_end: null,
  stay_start: null,
  stay_end: null,
  accommodations:[]
});
export default CustomerSearchForm;
