import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change"
var MAIL_SEND_SELECT = "mail_send"

var _mai_send = ["1"]

var setMailSendSelect = (is_send) => {
  _mai_send = is_send
}



var PaymentStore = assign({},EventEmitter.prototype,{



  getMailSelect(){
    return _mai_send
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.PAYMENT_MAIL_SEND_SELECT:
      setMailSendSelect(action.select);
      PaymentStore.emitChange(MAIL_SEND_SELECT)
      break;

    default:
  }
})
export default PaymentStore
