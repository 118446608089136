import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import BookActions from "./BookActions"

var FreeMailActions = {

  getBody(token, template_id, book_id){

    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + book_id + config.get("API_MAIL") + "/body?template_id=" + template_id , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.FREE_MAIL_UPDATE,
          name:"body",
          value:data.boby
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  create(token, book_id, item){


    fetch(config.get("API_PATH") + config.get("API_BOOK") + "/" + book_id + config.get("API_MAIL_FREE"), {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){

        BookActions.getDetail(token, book_id)

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.FREE_MAIL_UPDATE,
      name:name,
      value:value
    })
  },

  createNew(){
    AppDispatcher.dispatch({
      actionType: Constants.FREE_MAIL_CREATE_NEW
    })
  }

}

export default FreeMailActions
