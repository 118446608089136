import React, { useState, useEffect,useCallback } from 'react'

import SupplierActions from "../../../actions/SupplierActions"
import AuthStore from "../../../stores/AuthStore"
import SupplierStore from "../../../stores/SupplierStore"
import Supplier from "../../../vo/Supplier"

import "../../../styles/alert.css"

function SupplierBox(props:PropsValue) {

  const [item, setItem] = useState(null);
  const [message, setMessage] = useState(null)
  const stableSupplierChangeHandler = useCallback(supplierChangeHandler, [])
  useEffect(() => {

    SupplierStore.addChangeListener(stableSupplierChangeHandler)
    SupplierActions.setItem(props.item ? props.item : Supplier())
    return function cleanup() {
      SupplierStore.removeChangeListener(stableSupplierChangeHandler)
    };

  },[stableSupplierChangeHandler,props.item]);

  function supplierChangeHandler(){
    setItem(SupplierStore.getItem())
  }

  function update(e){
    const target = e.target
    SupplierActions.updateForm(target.name, target.value)
  }

  function edit(){
    const item = SupplierStore.getItem()
    if(!item.name){
      setMessage("名前を入力してください。")
      return false
    }
    if(!item.mail){
      setMessage("メールアドレスを入力してください")
      return false
    }
    SupplierActions.edit(AuthStore.getToken(), item)
    props.cancell()
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">{props.item ? "パートナー編集" : "パートナー作成"}  </div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">名前</div>
                <div className="item-content">
                  <input type="text" value={item ? item.name : null} className="full" name="name" onChange={update} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">メールアドレス</div>
                <div className="item-content">
                  <input type="text" value={item ? item.mail : null} className="full" name="mail" onChange={update} />
                </div>
              </div>

            </div>
          </div>
          <div className="message">{message}</div>
          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={edit}>{props.item ? "更新" : "作成"}</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default SupplierBox
