import React, {  } from 'react'

import '../../../styles/customer.css'
import CalendarInfoActions from '../../../actions/CalendarInfoActions'
import AccommodationStore from '../../../stores/AccommodationStore'
import AuthStore from '../../../stores/AuthStore'

function CalendarInfoListItem(props:PropsValue) {
  function changeHandler(e){
    const target = e.target

    CalendarInfoActions.updateComment(props.item.id, target.value)
  }

  function update(){
    if(props.item.id === 0){

      CalendarInfoActions.create(AuthStore.getToken(),props.item)
    }else{
      CalendarInfoActions.update(AuthStore.getToken(),props.item.accommodation_id,props.item.id,props.item.comment)
    }

  }

  function deleteItem(){
    if(props.item.id  === 0){
      CalendarInfoActions.updateComment(props.item.id, "")
    }else{
      CalendarInfoActions.delete(AuthStore.getToken(),props.item.accommodation_id,props.item.id,props.item.target_date)
    }
  }


  return(
    <div className="mail-list-item">
        <div  className="accommodation">
          {AccommodationStore.getById(props.item.accommodation_id)
            ? <div style={{backgroundColor: '#' + AccommodationStore.getById(props.item.accommodation_id).color}} className="acc_icon">{AccommodationStore.getById(props.item.accommodation_id).short_name}</div>
            : ""}
        </div>
        <div className="free"><input type="text" value={props.item.comment}  onChange={changeHandler} className="full" onBlur={() => update()}/></div>
        <div className="button wide-hidden" onClick={deleteItem}><i className="fas fa-trash"></i>&nbsp;削除 </div>
    </div>
  )
}

export default CalendarInfoListItem
