import React, { useState, useEffect,useCallback } from 'react'
import OptionActions from '../../../actions/OptionActions';
import BookOptionActions from "../../../actions/BookOptionActions"
import AuthStore from "../../../stores/AuthStore"
import OptionStore from "../../../stores/OptionStore"
import BookOptionStore from "../../../stores/BookOptionStore"
import BookOption from "../../../vo/BookOption"
import Select from 'react-select'

import {selectFormStyles} from "./selectStyle"
import "../../../styles/alert.css"

function OptionBox(props:PropsValue) {

  const [item, setItem] = useState(null);
  const [message, setMessage] = useState(null)
  const [option_list, setOptionList] = useState([])

  const stableOptionMasterChangeHandler = useCallback(optionMasterChangeHandler, [])
  const stableBookOptionChangeHandler = useCallback(bookOptionChangeHandler,[])

  useEffect(() => {
    OptionStore.addChangeListener(stableOptionMasterChangeHandler)
    BookOptionStore.addChangeListener(stableBookOptionChangeHandler)
    BookOptionActions.setItem(props.item ? props.item : BookOption({days:props.book.days}))

    OptionActions.getList(AuthStore.getToken(),props.book.accommodation_id, props.book.room_id)
    return function cleanup() {
      OptionStore.removeChangeListener(stableOptionMasterChangeHandler)
      BookOptionStore.removeChangeListener(stableBookOptionChangeHandler)
    };

  },[stableOptionMasterChangeHandler,stableBookOptionChangeHandler,props.item, props.book.accommodation_id,props.book.room_id,props.book.days]);

  function optionMasterChangeHandler(){
    setOptionList(
      OptionStore.getList().map(item => {
          return {label:item.name, value:item.id}
      })
    )
  }

  function bookOptionChangeHandler(){
    setItem(BookOptionStore.getItem())
  }



  function edit(){

   const item = BookOptionStore.getItem()

    if(!item.option_id){
      setMessage("オプションを選択してください。")
      return false
    }
    if(!item.unit || !Number(item.unit)){
      setMessage("数量を入力してください")
      return false
    }

    BookOptionActions.edit(AuthStore.getToken(), props.book.id, item)
    props.cancell()

  }

  function changeHandler( name, value){
    BookOptionActions.updateForm(name, value)
  }

  function updateForm(e){
    const target = e.target
    BookOptionActions.updateForm(target.name, target.value)
  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">{props.item ? "オプション編集" : "オプション登録"}  </div>

          <div className="payment-item">
            <div className="section-content list form fix">

              <div className="list-item">
                <div className="item-head">オプション</div>
                <div className="item-content">
                  {
                    props.item ? item && item.supplier ? item.supplier.name  : "公式"
                    :
                    <div className="select w100p">
                      <Select
                          options={option_list}
                          placeholder = "オプション"
                          onChange = {(e) => changeHandler("option_id", e.value)}
                          styles = {selectFormStyles}
                      />
                    </div>
                  }

                </div>
              </div>

              <div className="list-item">
                <div className="item-head">数量</div>
                <div className="item-content">
                  <input type="text" value={item ? item.unit : null} className="full" name="unit" onChange={updateForm} />
                </div>
              </div>

              <div className="list-item">
                <div className="item-head">泊数</div>
                <div className="item-content">
                  <input type="text" value={item ? item.days : null} className="full" name="days" onChange={updateForm} />
                </div>
              </div>

              

              <div className="list-item">
                <div className="item-head">説明</div>
                <div className="item-content">
                  <textarea value={item ? item.comment : null} className="full" name="comment" onChange={updateForm} />
                </div>
              </div>

            </div>
          </div>
          <div className="message">{message}</div>
          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={edit}>{props.item ? "更新" : "作成"}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionBox
