import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format } from 'date-fns'
import { subMonths } from 'date-fns'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'


import ErrorStore from '../../../stores/ErrorStore';
import AuthStore from '../../../stores/AuthStore';
import CashHistoryStore from "../../../stores/CashHistoryStore"

import CashHistoryActions from "../../../actions/CashHistoryActions"
import Menu from '../parts/Menu';
import Header from '../parts/Header';
import MessageBox from '../parts/MessageBox'
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'


function CashHistoryApp(props:PropsValue) {
  registerLocale('ja', ja)
  const { accommodation_id } = useParams();

  const [history, setHistory] = useState([])
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableCashHistoryChangeHander = useCallback(cashHistoryChangeHander,[])
  const stableSearchParamChangeHandler = useCallback(bookSearchChangeHandler,[])



  useEffect(() => {
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    CashHistoryStore.addChangeListener(stableSearchParamChangeHandler,"search_param")
    CashHistoryStore.addChangeListener(stableCashHistoryChangeHander)
    CashHistoryActions.setSearchParam("start_date",startOfMonth(subMonths(new Date(),1)))
    CashHistoryActions.setSearchParam("end_date",endOfMonth(subMonths(new Date(),1)))

    CashHistoryActions.getList(AuthStore.getToken(), accommodation_id, startOfMonth(subMonths(new Date(),1)),endOfMonth(subMonths(new Date(),1)))
    return function cleanup() {
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      CashHistoryStore.removeChangeListener(stableCashHistoryChangeHander)
      CashHistoryStore.removeChangeListener(stableSearchParamChangeHandler,"search_param")
    };
  },[stableErrorChangeHandler,stableCashHistoryChangeHander,stableSearchParamChangeHandler,accommodation_id]);


  function bookSearchChangeHandler(){
    setStartDate(CashHistoryStore.getSearchValue("start_date"))
    setEndDate(CashHistoryStore.getSearchValue("end_date"))
  }


  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
  }


  function setDate(name, date){
    CashHistoryActions.setSearchParam(name,date)
  }
  function search(){
    setHistory([<div className="loading"><i class="fas fa-circle-notch fa-spin"></i></div>])
    CashHistoryActions.getList(AuthStore.getToken(), accommodation_id, CashHistoryStore.getSearchValue("start_date"),CashHistoryStore.getSearchValue("end_date"))
    
  }

  function getCsv(){
    CashHistoryActions.getCSVList(AuthStore.getToken(), accommodation_id, CashHistoryStore.getSearchValue("start_date"),CashHistoryStore.getSearchValue("end_date"))
  }

  function cashHistoryChangeHander(){
    const list = CashHistoryStore.getList()
    setHistory( list.map( item => {
      return (
        <div className="mail-list-item">
          <div className="date">{format(new Date(item.paid_date),"yyyy年MM月dd日" )}</div>
          <div className="d150 right">{item.amount.toLocaleString()}円</div>
          <div className="free">{item.intended_use}</div>
          <div className="d150">{item.user_name}</div>
          <div className="d150 right">{item.balance.toLocaleString()}円</div>
        </div>
        )
    })
    )
  }

  function closeMessageBox(){
    setBoxClass("close")
  }

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id} />
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="free-pannel scrollable-vertical">
              <section className={"pannel "}>
                <div className="section-header">
                  <div className="title">小口現金</div>
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={start_date} onChange={(date) => setDate("start_date", date)} />&nbsp;〜&nbsp; 
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={end_date} onChange={(date) => setDate("end_date", date)} /> &nbsp;&nbsp;
                  <div className="button-create" onClick={search} ><div>表示</div></div>
                  &nbsp;
                  <div className="button-create" onClick={getCsv} ><div>csv</div></div>
                </div>

                <div className="mail-list-item header">
                  <div className="date">日付</div>
                  <div  className="d150">入出金額</div>
                  <div  className="free">使用目的</div>
                  <div  className="d150">登録者</div>
                  <div  className="d150">残高</div>

                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{history}</div>
                </div>


              </section>
            </div>
          </div>
          <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
        </main>
    </div>
  )
}

export default CashHistoryApp
