
import 'whatwg-fetch'
import cookie from 'react-cookies'



const AuthAction =  {

  

  getToken(  callback, errorHandler){
    var item =  cookie.load("token")
    if(item)
      callback(item)
    else
    errorHandler({error:1})
  },



   
}

export default AuthAction
