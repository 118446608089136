import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import CustomerSearchForm from "../vo/CustomerSearchForm"
var CHANGE_EVENT = "change"
var CHANGE_SELCT = "selected"
var FORM_CHANGE = "form_change"

var _items = []
var _count = null
var _selected_list = []
var _form = CustomerSearchForm()


var setList = (items) => {
  _items = items
  _selected_list = items.map(item=>{
    return item.mail
    //return {mail:item.mail, name:item.name}
  })
}

var setCount = (c) => {
  _count = c
}

var setForm= (name,value) =>{
  _form =  _form.set(name,value)

}

var changeSelected = (id, add) => {

   if(add === 1 ){
    _selected_list.push(id)
  }else{
    _selected_list.splice(_selected_list.indexOf(id),1)
  } 
}

var CustomerStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getForm(){
    return _form
  },

  getSelectedList(){
    return _selected_list
  },

  getCount(){
    return _count
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.CUSTOMER_LIST_LOADED:
      setList(action.list);
      setCount(action.count)
      CustomerStore.emitChange()
      break;
    case Constants.CUSTOMER_SLECTED:
        changeSelected(action.id,action.type);
        CustomerStore.emitChange(CHANGE_SELCT)
        break;
    case Constants.UPDATE_CUSTOMER_FORM:
        setForm(action.name,action.value);
        CustomerStore.emitChange(FORM_CHANGE)
        break;
    default:
  }
})
export default CustomerStore
