import React from 'react'
import {  Draggable } from "react-beautiful-dnd";

import '../../../../styles/movable.css'
import RoomStore from "../../../../stores/RoomStore"

function OptionItem(props:PropsValue) {


  return(
    <Draggable key={"item_" + props.item.id} draggableId={"item_" + props.item.id} index={props.index}>
    {(provided) => (
    <div className="list-item movable-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
       <div className="w300">{props.item.name}</div>
       <div className="w100 right">{props.item.price.toLocaleString()}円</div>
       <div className="w100">{props.item.unit_max}{props.item.unit}</div>
       <div className="free">{props.item.description}</div>
       <div className="w100">{props.item.supplier ? props.item.supplier.name : "公式" }</div>
       <div className="w130">{props.item.room_id ? RoomStore.getName(props.item.room_id) : "全て" }</div>
       <div className="w50 center">{props.item.notice ? "○" : "" }</div>
       <div className="w50 center">{props.item.is_stay_type ? "○" : "" }</div>
       <div  className="link w50 wide-hidden" onClick={() => props.editItem(props.item)}>編集</div>
       <div className="w50 link wide-hidden" onClick={() => props.deleteItem(props.item.id)}>削除</div>
     </div>
    )}
    </Draggable>
  )
}

export default OptionItem

