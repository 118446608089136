import { Record } from 'immutable'

const  PlanProps = Record({
  id:null,
  name:null,
  close_date_before:null,
  end_at:null,
  start_at:null,
  is_active:null,
  max_num: null,
  price:null,
  prices:null,
  description:null,
  check_in:null,
  ikyu_id:null,
  check_out:null,
  payment_method:null,
  icon:null,
  notice_mail :null,
});
export default PlanProps;

