import React,{useState,useEffect,useCallback} from 'react'
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'
import PaymentActions from "../../../actions/PaymentActions"
import PaymentStore from "../../../stores/PaymentStore"
import AuthStore from "../../../stores/AuthStore"
import config from 'react-global-configuration';
import Select from 'react-select'
import Checkbox from "./form/Checkbox"
import "../../../styles/payment.css"
import "../../../styles/alert.css"

function PaymentCreateBox(props:PropsValue) {

  const [payment_exe_date, setDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [pay_type, setPayType] = useState(1);
  const [with_mail, setWithMail] = useState(["1"]);

  
  

  var payment_methods = [
    {value:1, label:config.get("PAYTYPE_" + 1)},
    {value:2, label:config.get("PAYTYPE_" + 2)},
    {value:4, label:config.get("PAYTYPE_" + 4)}
  ]
  registerLocale('ja', ja)

  const stableMailChangeHandler = useCallback(mailChangeHandler, [])


  useEffect(() => {
    if(props.item){
      setAmount(props.item.price)
    }
    setDate(new Date())
    setPayType(1)
    PaymentStore.addChangeListener(stableMailChangeHandler,"mail_send")
    return function cleanup() {
      PaymentStore.removeChangeListener(stableMailChangeHandler,"mail_send")
    }
  },[props,stableMailChangeHandler]);

  function mailChangeHandler(){
    setWithMail(PaymentStore.getMailSelect())
  }

  function changeHandler(selected){
    setPayType(selected.value)
  }

  function changePaymentHandler(name,value){

    if(PaymentStore.getMailSelect() === null || PaymentStore.getMailSelect().indexOf(value) >= 0 ){
      PaymentActions.updateMailSend([])
    }else{
      PaymentActions.updateMailSend(["1"])
    }
  }

  function create(){
    if(Number(amount) && Number(pay_type)){
      PaymentActions.create(AuthStore.getToken(), payment_exe_date, Number(amount), Number(pay_type), props.item.id, PaymentStore.getMailSelect().indexOf("1") >= 0 ? true :false, true)
    }else{
      console.log("error")
    }

  }

  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
        <div className="message-box">入金記録の登録  </div>

          <div className="payment-item">
            <div className="section-content list form fix">
              <div className="list-item">
                <div className="item-head">入金日</div>
                <div className="item-content"><DatePicker className="date" showPopperArrow={false} locale="ja" placeholderText="入金日" selected={payment_exe_date} dateFormat="yyyy-MM-dd" onChange={date => setDate( date)} /></div>
              </div>

              <div className="list-item">
                <div className="item-head">入金額（円）</div>
                <div className="item-content"><input type="text" value={amount} className="small" placeholder="入金額" onChange={(e) => setAmount(e.target.value)}/></div>
              </div>

              <div className="list-item">
                <div className="item-head">支払い方法</div>
                <div className="item-content">
                  <Select
                    options={payment_methods}
           
                    placeholder = "支払い方法"
                    onChange = {changeHandler}
                    defaultValue = {payment_methods[0]}
                  />
                </div>
              </div>
            </div>
            <div className="check-box">
              <Checkbox name="mail" selected={with_mail} changeHandler={changePaymentHandler} items={[{value:"1",text:"宿泊者にメールを送信する<span class='sml'>(設定済みの場合)</span>"}]}/>
            </div>

          </div>

          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={create}>送信</div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default PaymentCreateBox
