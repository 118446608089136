import React, { useState, useEffect,useCallback } from 'react'
import { format } from 'date-fns'

import AuthStore from '../../stores/AuthStore';
import MailPublishStore from '../../stores/MailPublishStore';
import MailPublishActions from '../../actions/MailPublishActions';

import Menu from './parts/Menu';
import Header from './parts/Header';
import Alert from './parts/Alert';

import '../../styles/customer.css'

function PublishListApp(props:PropsValue) {

  const [puslish_list, setPublish] = useState([]);

  const [alert, setAlert] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stablePublishChangeHandlerr = useCallback(publishChangeHandler, [])

  function publishChangeHandler(){
    setPublish(
      MailPublishStore.getList().map(item =>{
        return <div className="mail-list-item" key={"publist_" + item.id}>

            <div className="name">{item.setting.title}</div>
            <div  className="date">{format(new Date(item.send_at), 'yyyy年MM月dd日')}</div>
            <div  className="date">{format(new Date(item.created_at), 'yyyy年MM月dd日')}</div>
            <div  className="cancel" onClick={() => deleteItem(item.id)}>{item.sent_at ?"" : "削除"}</div>
            <div  className="number">{item.setting.users.length}人</div>
          </div>
      })
    )
  }

  useEffect(() => {

    MailPublishStore.addChangeListener(stablePublishChangeHandlerr)
    MailPublishActions.getList(AuthStore.getToken())
    return function cleanup() {
      MailPublishStore.removeChangeListener(stablePublishChangeHandlerr)
    };

  },[stablePublishChangeHandlerr]);

  function openRightPannel() {
    window.location.href="/m/mail/create"
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="完全に削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function deleteExe(id){
    setAlert(null)
    MailPublishActions.delete(AuthStore.getToken(),id )
  }

  function cancell(){
    setAlert(null)
  }

  return(
    <div className="inner">
        {alert}

        <main className="main">
          <Menu selected="mail"/>
          <div className="main-content">
            <Header title="一斉メール"/>
            <div className="open-close-section">
              <section className="full pannel">
                <div className="section-header">
                    <div className="button-create" onClick={openRightPannel}><i className="fas fa-plus"></i><div>メール作成</div></div>
                </div>
                <div className="mail-list-item header">

                  <div className="name">タイトル</div>
                  <div  className="date">送信日</div>
                  <div  className="date">作成日</div>
                  <div  className="cancel-h">削除</div>
                  <div className="number">送信人数</div>
                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{puslish_list}</div>
                </div>
              </section>
            </div>

          </div>
        </main>
    </div>
  )
}

export default PublishListApp
