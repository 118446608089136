import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change"


var _item = null


var setInfo = (item) => {
  _item = item
}



var CompanyStore = assign({},EventEmitter.prototype,{



  getInfo(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.COMPANY_INFO_LOADED:
      setInfo(action.data);
      CompanyStore.emitChange()
      break;

    default:
  }
})
export default CompanyStore
