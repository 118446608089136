import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import MailPublish from "../vo/MailPublish"

const CHANGE_EVENT = "change"
const DELETED = "deleted"

var _item = new MailPublish()
var _items = []
var _count = null;

var setList = (items) => {
  _items = items
}

var update = (name ,value) => {
  _item = _item.set(name, value)
}


var MailPublishStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },

  getCount(){
    return _count
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.MAIL_PUBLISH_LIST_LOADED:
      setList(action.list);
      MailPublishStore.emitChange()
    break;
    case Constants.MAIL_PUBLISH_DELETED:
      MailPublishStore.emitChange(DELETED)
    break
    case Constants.MAIL_PUBLISH_UPDATE:
      update(action.name, action.value)
      MailPublishStore.emitChange()
    break
    default:
  }
})
export default MailPublishStore
