import { Record } from 'immutable'


const SyncSetting = Record({
  id:0,
  params: null,
  tag:null,
  mail_to:null,
  service_id: null
  
});
export default SyncSetting;
