import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

const CHANGE_EVENT = "change"


var _items = []
var _selected_id = null

var setList = (items) => {
  _items = items
}

var setSelectedId = (id) => {
  _selected_id = id
}

var MailTemplateStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getSelectedId(){
    return _selected_id
  },

  getFromList(id){
    const _t = _items.filter(item => item.id === id)
    if(_t.length > 0) return _t[0]
    else return null
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.MAIL_TEMPLATE_LIST_LOADED:
      setList(action.list);
      MailTemplateStore.emitChange()
    break;

    case Constants.MAIL_TEMPLATE_SELECTED:
      setSelectedId(action.id);
    break;

    default:
  }
})
export default MailTemplateStore
