import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var SupplierActions = {


  getList(token){

    fetch(config.get("API_PATH")  + config.get("API_SUPPLIER") , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.SUPPLIER_LIST_LOADED,
          list:data,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },


  edit(token, item){

    const str =  item.id ? ("/" + item.id) : ""

    fetch(config.get("API_PATH") + config.get("API_SUPPLIER") + str, {
      method: item.id ? "PATCH" : "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        SupplierActions.getList(token )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },



  delete(token,id){

    fetch(config.get("API_PATH") + config.get("API_SUPPLIER") + "/" + id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        SupplierActions.getList(token )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  
  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.SUPPLIER_UPDATE,
      name:name,
      value:value
    })
  },




  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.SUPPLIER_SET_ITEM,
      item:item
    })
  }



}

export default SupplierActions
