import { Record } from 'immutable'


const OptionMaster = Record({
  id:null,
  name:null,
  price: null,
  unit: null,
  unit_max: null,
  description: null,
  supplier_id: null,
  supplier: null,
  room_id:null,
  user_selection:null,
  is_stay_type:0,
  notice:0,
  display_order:0
});
export default OptionMaster;
