import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import OptionActions from '../../../actions/OptionActions';
import RoomActions from '../../../actions/RoomActions';
import AuthStore from '../../../stores/AuthStore';
import OptionStore from '../../../stores/OptionStore';
import ErrorStore from '../../../stores/ErrorStore';
import RoomStore from '../../../stores/RoomStore';

import MessageBox from '../parts/MessageBox'
import Alert from "../parts/Alert"
import Menu from '../parts/Menu';
import OptionItem from "./parts/OptionItem"
import Header from '../parts/Header';
import OptionMasterBox from "../parts/OptionMasterBox"

function OptionListApp(props:PropsValue) {

  const { accommodation_id } = useParams();

  const [list, setList] = useState(null)

  const [alert, setAlert] = useState(null)
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")

  const [crateBox, setCrateBox] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableOptionChangeHandler = useCallback(optionChangeHandler, [])
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])

  useEffect(() => {
    RoomStore.addChangeListener(stableOptionChangeHandler)
    OptionStore.addChangeListener(stableOptionChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    OptionActions.getList(AuthStore.getToken(),accommodation_id)
    RoomActions.getList(AuthStore.getToken(),accommodation_id)
    return function cleanup() {
      OptionStore.removeChangeListener(stableOptionChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      RoomStore.removeChangeListener(stableOptionChangeHandler)
    };

  },[stableOptionChangeHandler,stableErrorChangeHandler,accommodation_id]);


  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
  }


  function optionChangeHandler(){
    setList(OptionStore.getList().map( (item,index) => {
      
      return(
       <OptionItem item={item} editItem={editItem} deleteItem={deleteItem} index={index} />
      )

    }))
  }


  function editItem(item){
    //setCrateBox(<FaqCreateBox cancell={closeCreteBox} item={item} accommodation_id={accommodation_id} />)
    setCrateBox(<OptionMasterBox cancell={closeCreteBox}  accommodation_id={accommodation_id} item={item}/>)
  }

  function createNew(){
    setCrateBox(<OptionMasterBox cancell={closeCreteBox}  accommodation_id={accommodation_id}/>)
  }

  function closeCreteBox(){
    setCrateBox(null)
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function cancell(){
    setAlert(null)
  }

  function deleteExe(id){
    setAlert(null)
    OptionActions.delete(AuthStore.getToken(), accommodation_id, id)
  }


  function closeMessageBox(){
    setBoxClass("close")
  }


  function handleOnDragEnd(result){
    const items = OptionStore.getList()
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    OptionActions.updateList(AuthStore.getToken(), accommodation_id, items);

  }


  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id} />
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="option"/>

            <div className="free-pannel scrollable-vertical">
              <section className="pannel">
                <div className="section-header">
                  <div className="title">オプション一覧</div>
                  <div className="right-menu">
                    <div className="inline-link" onClick={createNew}><i className="fas fa-plus-circle"></i> 新規オプション作成</div>
                  </div>
                </div>

                <div className="mail-list-item header">
                  <div className="w300">名前</div>
                  <div className="w100">金額</div>
                  <div className="w100">最大</div>
                  <div className="free">説明</div>
                  <div className="w100">パートナー</div>
                  <div className="w130">部屋</div>
                  <div className="w50">通知</div>
                  <div className="w50">当日</div>
                  <div className="w50">編集</div>
                  <div className="w50">削除</div>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="faqlist">
                  {(provided) => (
                    <div
                      className="faqlist"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {list}
                      {provided.placeholder}
                    </div>
                  )}
                  </Droppable>
                </DragDropContext>

                

              

              </section>
            </div>
          </div>
          {alert}
          {crateBox}
          <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
        </main>
    </div>
  )
}

export default OptionListApp
