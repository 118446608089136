import React, { useState, useEffect,useCallback } from 'react'
import { format } from 'date-fns'
// import {parseISO} from 'date-fns'
//import config from 'react-global-configuration';

import AuthStore from '../../stores/AuthStore';
import BookActions from '../../actions/BookActions';
import MailReceiveActions from "../../actions/MailReceiveActions"
import SyncLogActions from '../../actions/SyncLogActions';
import CalendarInfoActions from '../../actions/CalendarInfoActions';
import BookStore from '../../stores/BookStore';
import CalendarInfoStore from '../../stores/CalendarInfoStore';
import ErrorStore from '../../stores/ErrorStore';
import SyncLogStore from '../../stores/SyncLogStore'
import MailReceiveStore from '../../stores/MailReceiveStore';
import AccommodationStore from '../../stores/AccommodationStore';
import Menu from './parts/Menu';
import Header from './parts/Header';
import BookListItem from './parts/BookListItem';
import BookItem from './parts/BookItem'
import CalendarInfoListItem from './parts/CalendarInfoListItem'
import SyncErrorListItem from './parts/SyncErrorListItem'


import "../../styles/dashboard.css"
import "../../styles/calendarInfo.css"

function DashBoardApp(props:PropsValue) {

  //const timeZone = 'Asia/Tokyo'

  const [sync_list, setSyncList] = useState([]);
  const [paid_list, setPaidList] = useState([])
  //const [up_supported_list, setUnSupportedList] = useState([])


  const [check_out_list, setCheckOutList] = useState([])
  const [check_in_list, setCheckInList] = useState([])
  const [consecutive_list, setConsecutiveList] = useState([])
  const [pannelCss, setPannelCss] = useState("full")
  const [bookPannel, setBookPannel] = useState(null)
  const [calendar_comment, setCalendarComment] = useState(null)

  const [current_date] = useState(new Date())

  const stableCalendarInfoChangeHandler = useCallback(calendarInfoChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler, [])
  const stableUnSupportedMailChangeHandler = useCallback(unSupportedMailChangeHandler,[])
  function bookChangeHandler(){

    setPaidList(
      BookStore.getNamedList("paid").map(item =>{
        return <BookListItem key={"book_p_" + item.id} item={item} openPannel={openPannel} />
      })
    )
    const st_list = BookStore.getStatus()

    if(st_list != null){
      setCheckOutList(
        st_list.check_out.map(
          item => {
            return <div className="books-status-item" onClick={() => openPannel(item.id, item.accommodation_id)}  key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div >{item.name} ({item.person_number}人)</div></div>
          }
        )
      )

      setCheckInList(
        st_list.check_in.map(
          item => {
            return <div className="books-status-item" onClick={() => openPannel(item.id, item.accommodation_id)}  key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div >{item.name} ({item.person_number}人)</div></div>
          }
        )
      )

      setConsecutiveList(
        st_list.consecutive.map(
          item => {
            return <div className="books-status-item" onClick={() => openPannel(item.id, item.accommodation_id)} key={"book_status_" + item.id}><div style={{backgroundColor: '#' + item.accommodation.color}} className="acc_icon">{item.accommodation.short_name}</div><div >{item.name} ({item.person_number}人)</div></div>
          }
        )
      )
    }
  }


  const stableSyncLogChangeHandler = useCallback(syncLogChangeHandler,[])

  function syncLogChangeHandler() {
    const list = SyncLogStore.getList()
    if(list.length > 0){
      setSyncList(
        <div>
          <div className="toggle-pannel-header">
            <div className="toggle-title">同期エラー</div>
          </div>

          <div className="mail-list-item header">
            <div  className="accommodation">宿泊施設</div>
            <div  className="w130">宿泊日</div>
            <div className="w250 wide-hidden">同期タイプ</div>
            <div  className="free">エラー内容</div>
            <div  className="w130 wide-hidden">発生日</div>
            <div  className="button wide-hidden">既読</div>
          </div>
          <div className="scrollable-container">
            <div > {list.map( item =>  {return <SyncErrorListItem item={item} read={read} />})}</div>
          </div>
        </div>
      )
    }else{
      setSyncList(null)
    }
  }


  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  function errorChangeHandler(){

  }

  useEffect(() => {
    AccommodationStore.addChangeListener(stableBookChangeHandler)
    BookStore.addChangeListener(stableBookChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    SyncLogStore.addChangeListener(stableSyncLogChangeHandler)
    CalendarInfoStore.addChangeListener(stableCalendarInfoChangeHandler)
    MailReceiveStore.addChangeListener(stableUnSupportedMailChangeHandler)
    BookActions.getList(AuthStore.getToken(), null, new Date(), null, null, true)
    MailReceiveActions.getList(AuthStore.getToken())
    SyncLogActions.getList(AuthStore.getToken(),0,0,null) //同期エラー未読

    return function cleanup() {
      AccommodationStore.removeChangeListener(stableBookChangeHandler)
      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      SyncLogStore.removeChangeListener(stableSyncLogChangeHandler)
      CalendarInfoStore.removeChangeListener(stableCalendarInfoChangeHandler)
      MailReceiveStore.removeChangeListener(stableUnSupportedMailChangeHandler)
    };

  },[stableBookChangeHandler,stableErrorChangeHandler,stableSyncLogChangeHandler,stableCalendarInfoChangeHandler,stableUnSupportedMailChangeHandler]);

  useEffect(() => {
    BookActions.getByDate(AuthStore.getToken(), format(current_date, 'yyyy-MM-dd'))

  },[current_date]);

  function calendarInfoChangeHandler(){
    const list = CalendarInfoStore.getList()
    setCalendarComment(
      list.map( item => {
        return <CalendarInfoListItem item={item} />
      })
    )
  }

  function unSupportedMailChangeHandler(){
    /**setUnSupportedList(MailReceiveStore.getList().map(item => {

      return (
        <div className="item-list-item" >
            <div className="free">{item.title}</div>
            <div  className="w200">{item.from_address}</div>
            <div  className="w200">{format(parseISO(item.created_at), 'MM月dd日 HH時mm分' ,{ timeZone:timeZone })}</div>
            <div className="button wide-hidden" onClick={() => displayMailDetail(item)}>詳細</div>
        </div>
      )
    }))
     */ 
  }

  function read(id){
    SyncLogActions.update(AuthStore.getToken(), id, true)
  }

  function openPannel(id, accommodation_id = 0){
    setPannelCss("wide")
    setBookPannel(<BookItem id={id} closePannel={closePannel} accommodation_id={accommodation_id} />)
  }

  function closePannel(){
    setPannelCss("full")
    setBookPannel(null)
  }
  /**
  function displayMailDetail(item){
   props.history.push("/m/mail/receive/" + item.id)
  } */

  return(
    <div className="inner">

        <main className="main">
          <Menu selected="index" callback={CalendarInfoActions.getTodayInfo}/>
          <div className="main-content">
            <Header title="ホーム" />
            <div className="free-pannel scrollable-vertical">
              <section className={"pannel " + pannelCss}>
                <div className="toggle-pannel-header">
                  <div className="toggle-title">本日の予定</div>
                </div>
                <div className="calendar-comments">
                  {calendar_comment}
                </div>
                <div className="today-status">
                  <div className="book-status"><div className=" header title">チェックアウト</div><div>{check_out_list}</div></div>
                  <div className="book-status"><div className="header title">連泊</div><div>{consecutive_list}</div></div>
                  <div className="book-status"><div className="header title">チェックイン</div><div>{check_in_list}</div></div>
                </div>

                {sync_list}
             {/**
                <div className="toggle-pannel-header">
                  <div className="toggle-title">未対応メッセージ</div>
                </div>
                <div className="item-list-item header">
                  <div  className="w130">タイトル</div>
                  <div className="w200">メールアドレス</div>
                  <div  className="w200">発生時間</div>
                  <div  className="button wide-hidden"></div>
                </div>
                <div className="scrollable-container">
                  <div>{up_supported_list}</div>
                </div>
               */ }

                <div className="toggle-pannel-header">
                  <div className="toggle-title">未入金予約</div>
                </div>
                <div className="item-list-item header">
                  <div  className="accommodation">宿泊施設</div>
                  <div  className="w130">宿泊日</div>
                  <div className="free">宿泊者</div>
                  <div  className="w100 wide-hidden">宿泊人数</div>
                  <div  className="w100 wide-hidden">支払い金額</div>
                  <div  className="w130">予約発生日</div>
                  <div  className="w130 ">振込期限</div>
                  <div  className="w80 ">履歴</div>
                  <div  className="button wide-hidden"></div>
                </div>

                <div className="scrollable-container">
                  <div>{paid_list}</div>
                </div>

              </section>
              {bookPannel}
            </div>
          </div>

        </main>
    </div>
  )
}

export default DashBoardApp
