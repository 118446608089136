import { Record } from 'immutable'


const Plan = Record({
  id:null,
  close_date_before: null,
  name:null,
  end_at:new Date(),
  start_at: new Date(),
  is_active:null,
  max_num:null,
  description:null,
  ikyu_id:null,
  check_in:null,
  check_out:null,
  price:null,
  prices:[],
  icon:null,
  notice_mail:null,
  payment_method:[]
});
export default Plan;
