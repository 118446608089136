import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var SyncLogActions = {


  getList(token, success,read,offset){

    let params = new URLSearchParams();
    if(success != null){
      params.set('success', success);
    }
    if(read != null){
      params.set('read', read);
    }
    if(offset != null){
      params.set('offset', offset);
    }

    fetch(config.get("API_PATH") + config.get("API_SYNC_LOG")  + "?" + params.toString() , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.SYNC_LOG_LIST_LOADED,
          list:data.logs
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  update(token, id, read){

    const  request_data = {
      "read":read
    }


    fetch(config.get("API_PATH") + config.get("API_SYNC_LOG")  + "/" + id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        SyncLogActions.getList(token,0,0,null)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    })
  },



}

export default SyncLogActions
