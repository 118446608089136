import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Room from "../vo/Room"

var CHANGE_EVENT = "change"

var CREATED = "created"
var DELETED = "deleted"

var _item = null
var _items = []


var setItem = (item) => {
  _item = RoomStore.createObject(item)

}

var setList = (list) => {
  _items = list
}

var update = (name, value) => {
  _item = _item.set(name, value)
}


var RoomStore = assign({},EventEmitter.prototype,{

  getItem(){
    return _item
  },


  getList(){
    return _items
  },

  getName(id){
    
    const item =  _items.filter(item => item.id === id)
    return item.length > 0 ? item[0].name  : "-"
  },

  checkOverBook(bookings){

    for(var i = 0 ; i < _items.length; i++){
      const room = _items[i]
      var counter = 0
      for(var t = 0; t < bookings.length; t++){
        const book = bookings[t]

        if(book.room_id === room.id){
          counter ++
          if(counter > room.max_num ){
            return false
          }
        }
      }
    }
    return true
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


  createObject(item) {


    return new Room({
      id: item.id,
      name: item.name,
      max_num: item.max_num,
      plans:item.plans
      })
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.ROOM_LIST_LOADED:
      setList(action.list);
      RoomStore.emitChange()
    break;

    case Constants.ROOM_DETAIL_LOADED:
      setItem(action.item)
      RoomStore.emitChange()
    break;

    case Constants.ROOM_UPDATE:
      update(action.name,action.value)
      RoomStore.emitChange()
    break;

    case Constants.ROOM_SET:
      setItem(action.item)
      RoomStore.emitChange()
    break;

    case Constants.ROOM_CREATED:
      RoomStore.emitChange(CREATED)
    break;
    case Constants.ROOM_DELETED:
      RoomStore.emitChange(DELETED)
    break;


    default:
  }
})
export default RoomStore
