import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  MESSAGE_CREATE:null,

  ///AUTH
  AUTH: null,
  AUTH_LOGOUT:null,
  AUTH_ERROR:null,
  AUTH_NOUSER:null,
  ACCOMMODATION_SLECTED:null,
  ACCOMMODATION_LIST_LOADED:null,

  MAIL_PUBLISH_LIST_LOADED:null,
  MAIL_PUBLISH_DELETED:null,
  MAIL_PUBLISH_UPDATE:null,

  MAIL_HISTORY_LIST_LOADED:null,

  MAIL_TEMPLATE_LIST_LOADED:null,
  MAIL_TEMPLATE_SELECTED:null,

  MAIL_UNSUPPORTED_LIST_LOADED:null,
  MAIL_UNSUPPORTED_LOADED:null,

  FREE_MAIL_UPDATE:null,
  FREE_MAIL_CREATE_NEW: null,
  PAYMENT_MAIL_SEND_SELECT:null,


  CUSTOMER_LIST_LOADED:null,
  CUSTOMER_SLECTED:null,
  UPDATE_CUSTOMER_FORM:null,
  COMPANY_INFO_LOADED:null,
  BOOK_LIST_LOADED:null,
  BOOK_TODAY_LIST_LOADED:null,
  BOOK_DETAIL_LOADED:null,
  BOOK_DETAIL_SET:null,
  BOOK_SYNCED:null,
  BOOK_STATUS_LOADED:null,
  BOOK_DELETED:null,
  BOOK_UPDATE_FORM:null,

  SENT_TEST_MAIL:null,
  PAYMENT_CREATED:null,
  PAYMENT_DELETED:null,
  CALENDAR_SELETED:null,
  CALENDAR_CREATE_UPPPER:null,
  CALENDAR_CREATED_UPPPER:null,
  CALENDAR_CREATE_BOTTOM:null,
  CALENDAR_CREATED_BOTTOM:null,
  CALENDAR_SET_FIRST_DATE:null,
  CALENDAR_CURRENT_DATE:null,
  CALENDAR_LOADED_DATE:null,
  CALENDAR_UPDATE_TYPE:null,

  CALENDAR_INFO_LIST_LOADED:null,
  CALENDAR_INFO_UPDATE:null,
  CALENDAR_INFO_UPDATED:null,
  CALENDAR_INFO_CREATE_NEW:null,
  CALENDAR_INFO_DELETE:null,
  SYNC_LOG_LIST_LOADED:null,
  SYNC_SETTING_UPDATE:null,

  PLAN_LIST_LOADED:null,
  PLAN_DETAIL_LOADED:null,
  PLAN_PRICEL_LOADED:null,
  PLAN_UPDATE:null,

  ROOM_LIST_LOADED:null,
  ROOM_DETAIL_LOADED:null,
  ROOM_UPDATE:null,
  ROOM_SET:null,
  ROOM_CREATED:null,
  ROOM_DELETED:null,

  SYNC_ITEM_CREATED:null,
  SYNC_ITEM_LOADED:null,
  SYNC_ITEM_UPDATE:null,

  CSV_SELECTED:null,
  PLAN_PRICES_LOADED:null,
  PLAN_SET:null,
  PLAN_CREATED:null,
  PLAN_DELETED:null,
  PLAN_GET_IKYU:null,
  PLAN_CLEAR_IKYU:null,
  USER_LIST_LOADED:null,
  USER_SET_ITEM:null,
  USER_UPDATE:null,
  USER_CREATED:null,
  USER_DELETED:null,
  USER_UPDATE_PASSWORD:null,

  TIMESHEET_GROUP_LIST_LOADED:null,
  TIMESHEET_GROUP_ITEM_LOADED:null,
  TIMESHEET_GROUP_DELETED:null,
  TIMESHEET_GROUP_SET_ITEM:null,
  TIMESHEET_GROUP_UPDATE:null,

  PAYMENT_METHOD_LIST_LOADED:null,

  //CASH
  CASH_HISTORY_LOADED:null,
  CASH_HISTORY_UPDATE:null,
  CASH_HISTORY_NEW:null,
  CASH_HISTORY_SET_SEARCH_PARAM:null,
  SYNC_SETTING_LOADED:null,

  FAQ_LIST_LOADED:null,
  FAQ_UPDATE:null,
  FAQ_SET_ITEM:null,

  SUPPLIER_LIST_LOADED:null,
  SUPPLIER_UPDATE:null,
  SUPPLIER_SET_ITEM:null,

  OPTION_LIST_LOADED:null,
  OPTION_UPDATE:null,
  OPTION_SET_ITEM:null,

  BOOK_OPTION_UPDATE:null,
  BOOK_OPTION_SET_ITEM:null,
  BOOK_SET_SEARCH_PARAM:null,

  API_ERROR:null
})
