import React, {  } from 'react'

import { format,parseISO } from 'date-fns'
import '../../../styles/customer.css'
import AccommodationStore from '../../../stores/AccommodationStore'
import config from 'react-global-configuration';

function SyncErrorListItem(props:PropsValue) {

  const timeZone = 'Asia/Tokyo'
  function read(){
    props.read(props.item.id)
  }
  return(

    <div className="mail-list-item">

        <div  className="accommodation">
          {AccommodationStore.getById(props.item.accommodation_id)
            ? <div style={{backgroundColor: '#' + AccommodationStore.getById(props.item.accommodation_id).color}} className="acc_icon">{AccommodationStore.getById(props.item.accommodation_id).short_name}</div>
            : ""}</div>
        <div className="w130"><a href={"/m/accommodation/" + props.item.accommodation_id + "/book/" + format(new Date(props.item.target_date), 'yyyy/MM/dd') } >{format(new Date(props.item.target_date), 'yyyy年M月d日')}</a></div>
        <div  className="w250 wide-hidden">{config.get("SYNC_" + props.item.sync_type)}</div>
        <div  className="free">{config.get("SYNC_ERROR_" + props.item.result_type)}</div>
        <div className="w130 wide-hidden">{format(parseISO(props.item.created_at), 'yyyy年M月d日' ,{ timeZone:timeZone })}</div>
        <div className="button wide-hidden" onClick={read}><i className="far fa-check-circle"></i>&nbsp;既読 </div>
    </div>



  )
}

export default SyncErrorListItem
