import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import CashHistory from "../vo/CashHistory"

const CHANGE_EVENT = "change"
const UPDATE = "update"
const SEARCH_PARAM = "search_param"

var _items = []
var _item = CashHistory()

var _search_start_date = null
var _search_end_date = null


var setList = (items) => {
  _items = items
}

var update = (name, value) => {
  _item = _item.set(name,value)
}

var createNew = () =>  {
  _item =  CashHistory()
}


var setSearchParam = (name, value) => {
  switch(name) {
    case "start_date":
        _search_start_date = value
        break;
    case "end_date" :
        _search_end_date = value
        break;
    default: 
  }
}

var CashHistoryStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItem(){
    return _item
  },

  getSearchValue(name) {
    switch(name) {
      case "start_date":
        return _search_start_date
      case "end_date" :
        return _search_end_date
      default: return null
    }
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.CASH_HISTORY_LOADED:
      setList(action.list);
      CashHistoryStore.emitChange()
    break;

    case Constants.CASH_HISTORY_UPDATE:
      update(action.name,action.value);
      CashHistoryStore.emitChange(UPDATE)
    break;

    case Constants.CASH_HISTORY_NEW:
      createNew();
      CashHistoryStore.emitChange(UPDATE)
    break;  

    case Constants.CASH_HISTORY_SET_SEARCH_PARAM:
      setSearchParam(action.name,action.value)
      CashHistoryStore.emitChange(SEARCH_PARAM)
    break;

    default:
  }


})
export default CashHistoryStore
