import { Record } from 'immutable'


const CalendarInfo = Record({
  id:0,
  accommodation_id: null,
  target_date:null,
  comment:null
});
export default CalendarInfo;
