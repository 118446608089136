import React, { useCallback, useState, useEffect } from 'react'
import { format,parseISO } from 'date-fns'
import BookStore from '../../../stores/BookStore';
import AccommodationStore from '../../../stores/AccommodationStore'
import '../../../styles/pannel.css'

function AttachBookOnMail(props:PropsValue) {
  const [list, setList] = useState(null)
  const timeZone = 'Asia/Tokyo'
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])

  useEffect(() => {
    
    BookStore.addChangeListener(stableBookChangeHandler)
    return function cleanup() {
      BookStore.removeChangeListener(stableBookChangeHandler)
     
    };

  },[stableBookChangeHandler,props.id,props.date]);

  function bookChangeHandler(){
   setList(
    BookStore.getList().map(item => {
      const acc = AccommodationStore.getById(item.accommodation_id)
      return <div className={item.delete_flg ? "deleted-item pannel-list-item" : "pannel-list-item"}>
        <div  className="accommodation"> {acc ? <div style={{backgroundColor: '#' + acc.color}} className="acc_icon">{acc.short_name}</div>: null}</div>
        <div className="w100">{item.name}</div>
        <div className="w200">{format(parseISO(item.start_date), 'yyyy年M月d日' ,{ timeZone:timeZone })}</div>
        <div className="free">{item.room_name}</div>
        <div className="w50 link" onClick={() => crelaLink(item.id)}>紐付け</div>
      </div>
    }
    )
   )
  }

  function crelaLink(id){
    console.log(id)
  }

  return(
    <div>
    <section className="right pannel">
      <div className="section-header">
          <div className="title">予約候補</div>
      </div>
      
      <div className="section-content list form fix">
        {list}
      </div>
      

    </section>
    </div>

  )
}

export default AttachBookOnMail
