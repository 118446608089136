import React,{useState,useEffect,useCallback} from 'react'

import FaqActions from "../../../../actions/FaqActions"
import FaqStore from "../../../../stores/FaqStore"
import AuthStore from "../../../../stores/AuthStore"
import RoomStore from "../../../../stores/RoomStore"

import Faq from '../../../../vo/Faq'
import '../../../../styles/overlay_form.css'

import Select from 'react-select'
import {selectFormStyles} from "../../parts/selectStyle"

function FaqCreateBox(props:PropsValue) {

  const [item, setItem] = useState(null);
  const _room_list = RoomStore.getList().map(item => {
    return {label:item.name, value:item.id}
  })

  const room_list = [{label:"全ての部屋に表示", value:null}].concat(_room_list)

  const stableChangeHandler = useCallback(faqChangeHandler,[])

  useEffect(() => {
    FaqStore.addChangeListener(stableChangeHandler)

    if(props.item){
      FaqActions.setItem(props.item)
    }else{
      FaqActions.setItem(new Faq({display_order:props.display_order}))
    }
    return function cleanup() {
      FaqStore.removeChangeListener(stableChangeHandler)
    };
  },[stableChangeHandler,props.item, props.display_order]);


  function faqChangeHandler(){

    setItem(
      FaqStore.getItem()
    )
  }


  function changeHandler(e){
    const target = e.target
    FaqActions.updateForm(target.name, target.value)
  }

  function changeSelectRoomHandler(e){
    const room_id = e ? Number(e) : null
    FaqActions.updateForm("room_id", room_id)
  }


  function create(){
    props.cancell()
    FaqActions.create(AuthStore.getToken(), props.accommodation_id,FaqStore.getItem() )
  }



  return(
    <div className="overlay-wrap">
      <div className="overlay"></div>
      <div className="overlay-container">
        <div className="overlay-form">
          <div className="overlay-form-title">FAQ</div>
          <div className="overlay-form-list">
            <div className="overlay-form-list-item">
              <label>部屋</label>
              <div className="item-value ">
              <Select
                  options={room_list}
                  placeholder = "部屋で絞り込み"
                  onChange = {(e) => changeSelectRoomHandler(e.value)}
                  styles = {selectFormStyles}
              />
              </div>
            </div>


            <div className="overlay-form-list-item">
              <label>質問</label>
              <div className="item-value "><input type="text" name="question" value={item ? item.question : null} onChange={changeHandler}/></div>
            </div>

            <div className="overlay-form-list-item">
              <label>回答</label>
              <div className="item-value "><textarea onChange={changeHandler} name="answer" value={item ? item.answer : null} /></div>
            </div>

            <div className="btn-box">
                <div className="cancell" onClick={props.cancell}>キャンセル</div>
                <div className="ok" onClick={create}>送信</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqCreateBox
