import React, { useState, useEffect,useCallback } from 'react'


import SupplierStore from "../../stores/SupplierStore"
import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import SupplierActions from "../../actions/SupplierActions"

import Menu from './parts/Menu';
import Header from './parts/Header';
import Alert from './parts/Alert';
import MessageBox from './parts/MessageBox'
import SupplierBox from "./parts/SupplierBox"

import '../../styles/customer.css'


function  SupplierListApp(props:PropsValue) {


  const [list, setList] = useState([]);

  const [alert, setAlert] = useState(null)
  const [boxMessage, setBoxMessage] = useState({message:"", box_class:"", error:0})
  const [createItem, setCreateItem] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableSupplierChangeHandler = useCallback(supplierChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])


  useEffect(() => {
    SupplierStore.addChangeListener(stableSupplierChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    SupplierActions.getList(AuthStore.getToken())
    return function cleanup() {
      SupplierStore.removeChangeListener(stableSupplierChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
    };

  },[stableSupplierChangeHandler, stableErrorChangeHandler]);

  function errorChangeHandler(){
    setBoxMessage({message:ErrorStore.getMessage(), box_class:"active", error:1})
  }



  function supplierChangeHandler() {

    setList(
      SupplierStore.getList().map(
        item => {
          return (<div className="mail-list-item">
            <div className="w300">{item.name}</div>
            <div className="free wide-hidden">{item.mail}</div>
            <div  className="link w50 wide-hidden" onClick={() => update(item)}>編集</div>
            <div className="w50 link wide-hidden" onClick={() => deleteItem(item.id)}>削除</div>
          </div>)
        }
      )
    )
  }


  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="パートナーを削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function cancell(){
    setAlert(null)
  }

  function deleteExe(id){
    setAlert(null)
    SupplierActions.delete(AuthStore.getToken(),id)
  }

  function update(item){

      setCreateItem(<SupplierBox cancell={closeCreateBox} item={item}/>)
  }

  function closeMessageBox(){
    setBoxMessage({message:boxMessage.message, box_class:"close", error:boxMessage.error})
  }

  function createSupplier(){
    setCreateItem(<SupplierBox cancell={closeCreateBox} />)
  }
  function closeCreateBox(){
    setCreateItem(null)
  }

  return(
    <div className="inner">
        {alert}
        {createItem}
        <main className="main">
          <Menu selected="supplier"/>
          <div className="main-content">
            <Header title="パートナー一覧"/>
            <div className="open-close-section">
              <section className={"pannel "}>
                <div className="section-header">
                  <div className="right-menu">
                    <div className="button-create" onClick={createSupplier} ><i className="fas fa-plus"></i> パートナー作成</div>
                  </div>
                </div>

                <div className="mail-list-item header">
                  <div className="w300">名前</div>
                  <div  className="free wide-hidden">メールアドレス</div>
                  <div  className="w50 wide-hidden">編集</div>
                  <div className="w50  wide-hidden">削除</div>
                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{list}</div>
                </div>

              </section>
            </div>

          </div>
        </main>
        <MessageBox message={boxMessage.message} error={boxMessage.error} main_class={boxMessage.box_class} closeMessageBox={closeMessageBox}/>
    </div>
  )
}

export default SupplierListApp
