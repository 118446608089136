import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import AuthActions from "./AuthActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';

import { format } from 'date-fns'

var CashHistoryActions = {

  getList(token, accommodation_id, start_date, end_date){
    const  params = new URLSearchParams();
    if(start_date){
      params.set('start_date', format(start_date, "yyyy-MM-dd"));
    }
    if(end_date){
      params.set('end_date', format(end_date, "yyyy-MM-dd"));
    }
    

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/cashes?" + params.toString() , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        console.log(data)
        AppDispatcher.dispatch({
          actionType: Constants.CASH_HISTORY_LOADED,
          list:data
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getCSVList(token, accommodation_id, start_date, end_date){
    const  params = new URLSearchParams();
    params.set("format", "csv")
    if(start_date){
      params.set('start_date', format(start_date, "yyyy-MM-dd"));
    }
    if(end_date){
      params.set('end_date', format(end_date, "yyyy-MM-dd"));
    }


    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/cashes?" + params.toString() , {
      method: "GET",
      headers: {
       'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("text/csv") !== -1) {
           return response.blob()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        let anchor = document.createElement("a");
        
        anchor.href = URL.createObjectURL(data)
        anchor.download =  "green_stage_" + format(start_date,"yyyy-MM-dd") + "-" + format(end_date,"yyyy-MM-dd")  + ".csv"
        anchor.click();

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  create(token, accommodation_id, item){

    var amount = Number(item.amount)
    if(Number(item.direction) === 1){
      amount = -1 * amount
    }
    const _item = {
      "paid_date":format(item.paid_date, "yyyy-MM-dd"),
    	"amount":Number(amount),
    	"balance_check":0,
    	"intended_use":item.intended_use
    }
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/cashes" , {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.refreshToken()
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        CashHistoryActions.getList(token,accommodation_id)

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  createNew(){
    AppDispatcher.dispatch({
      actionType: Constants.CASH_HISTORY_NEW,
    })
  },
  updateItem(name, value){

    AppDispatcher.dispatch({
      actionType: Constants.CASH_HISTORY_UPDATE,
      name:name,
      value:value
    })
  },
  setSearchParam(name,value) {
    AppDispatcher.dispatch({
      actionType: Constants.CASH_HISTORY_SET_SEARCH_PARAM,
      name:name,
      value:value
    })
    
  }


}

export default CashHistoryActions
