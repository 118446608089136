import React from 'react'

import '../../../styles/alert.css'

function Alert(props:PropsValue) {
  function okHandler(){
    props.executor(props.param)
  }

  return(
    <div className="alert-wrap">
      <div className="overlay"></div>
      <div className="alert-container">
        <div className="alert-box">
          <div className="message-box">{props.message}</div>
          <div className="btn-box">
            <div className="cancell" onClick={props.cancell}>キャンセル</div>
            <div className="ok" onClick={okHandler}>{props.ok}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alert
