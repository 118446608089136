import React, { useState } from 'react'

import { format } from 'date-fns'
import '../../../styles/customer.css'

function CustomerListItem(props:PropsValue) {

  const [send_flg , setSendFlg] = useState(1);

  function setFlg(){
    if(send_flg === 1){
      setSendFlg(0)
      props.removeAddress(props.item.mail)
    }else{
      setSendFlg(1)
      props.addAddress(props.item.mail)
    }

  }

  return(
    <div className="customer-list-item">
      <div onClick={setFlg} className={"check " + (send_flg===1 ? "able" : "disabled")}><i className="far fa-check-circle"></i></div>
      <div className="name">{props.item.name}</div>
      <div  className="mail">{props.item.mail.split("@").length > 0 ? "@" + props.item.mail.split("@")[1] : "--"}</div>
      <div className="date">{props.item.last_visited ? format(new Date(props.item.last_visited), 'yyyy年MM月dd日') : null}</div>
    </div>
  )
}

export default CustomerListItem
