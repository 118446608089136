
import 'whatwg-fetch'
import Error from './Error';
import config from 'react-global-configuration';
import ResponseData from "./ResponseData"


const Action =  {


  fetchJson( path, method, header, body=null,  callbackHandler = null, errorHandler = null){
    fetch(config.get("API_PATH")  + path  , {
      method: method,
      headers: header,
      body: (method === "POST" || method === "PATCH") &&  body ? body : null
    }).then(function(response) {
     
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 400){
        return {"error":400}
      }else if(response.status === 401){

        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
      }else{
        return {"error":0}
      }
    }).then(function(data) {
      const response_data = new ResponseData({})
      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },
  

  fetchAuthorizedJson( path, token, method, header,  body=null,  callbackHandler = null, errorHandler = null, response_data = {}){
   
    if(!token) {
      return false
    }

    header.Authorization =  'Bearer ' + token
    
    fetch(config.get("API_PATH")   + path , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {body:response.json(),headers:response.headers}
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
       
        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
        
      }else{
        return {"error":response.status}
      }
    }).then(function(data) {  
     
      if(data && !data.error && callbackHandler){
      
        data.body.then(function(r) {
          
          response_data.data = r
          response_data.headers = data.headers
          callbackHandler(response_data)
        })
       
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          //
          
        }else if(data && data.error === 401){
          console.log("auth error")
          Error.authError()
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },


}

export default Action
