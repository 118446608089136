import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change"
var CHANGE_SELCT = "change_select"

var _items = []
var _selected_list = []

var setList = (items) => {
  _items = items
}

var changeSelected = (array) => {
  _selected_list = array
}


var AccommodationStore = assign({},EventEmitter.prototype,{

  getList(){

    return _items
  },

  getSelectedList(){
    return _selected_list
  },

  getById(id){

    for(var i = 0; i < _items.length; i++){
      if(Number(_items[i].id) === Number(id)) return _items[i]
    }
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.ACCOMMODATION_LIST_LOADED:
      setList(action.list);
      AccommodationStore.emitChange()
      break;
    case Constants.ACCOMMODATION_SLECTED:
        changeSelected(action.list);
        AccommodationStore.emitChange(CHANGE_SELCT)
        break;
    default:
  }


})
export default AccommodationStore
