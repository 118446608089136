
const config = {
    LOGIN_URL:                  '/',
    API_AUTH:                   '/oauth/auth',
    API_ACCOMMODATION:          '/admin/accommodations',
    API_PLAN:                    '/admin/plans',
    API_BOOK:                   '/admin/books',
    API_USER:                    '/admin/stuffs',
    API_CUSTOMER:               '/admin/customers',
    API_MAIL_PUBLISH:           '/admin/mail/publishes',
    API_MAIL_RECEIVE:           '/admin/mail/receive',
    API_PAYMENT_METHOD:          '/admin/payment_methods',

    API_BOOK_DATE:              '/admin/books/date',
    API_PAYMENT:                '/admin/payments',
    API_SYNC_LOG:               '/admin/logs/sync',
    API_SCHEDULE:               '/schedules',
    API_CALENDAR_INFO:          '/calendar/comments',
    API_SYNC_SETTING:           '/sync/settings',
    API_MAIL:                   '/mail',
    API_MAIL_FREE:               '/mail/free',
    API_TIMESHEET_GROUP:        '/admin/schedules/groups',
    API_FAQ:                    '/faqs',
    API_SYNC_ITEM:              '/admin/sync/items',
    API_SUPPLIER:                '/admin/suppliers',
    API_OPTION:                  '/options',
    API_PATH:                 process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : 'http://localhost:9000',
    API_AUTH_PATH:            process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : 'http://localhost:9000',

    SERVICE_ID_IKYU:1,

    WEEK_0: '日',
    WEEK_1: '月',
    WEEK_2: '火',
    WEEK_3: '水',
    WEEK_4: '木',
    WEEK_5: '金',
    WEEK_6: '日',

    CALENDAR_ADD_WEEKS: 5,
    CALENDAR_ADD_DAYS: 200,

    ERROR_BOOK_UPDATE: "更新に失敗しました。内容をご確認ください。",
    ERROR_BOOK_CREATE: "予約に失敗しました。内容をご確認ください。",
    ERROR_CSV_TEST: "エラーが発生しました。csvファイルの形式などをご確認ください。",
    ERROR_API:"エラーが発生しました。",
    ERROR_AUTH:     "メールアドレスまたはパスワードが一致しません",
    ERROR_DELETE:      "削除に失敗しました",
    ERROR_SYNC_0:   "同期に失敗しました。パスワード・日程を確認してください。",
    ERROR_MAIL_0:   "メールの送信に失敗しました。",

    MESSAGE_BOOK_DELETED: "予約が削除されました。",
    MESSAGE_USER_CREATED: "ユーザーが作成されました。",
    MESSAGE_USER_DELETED: "ユーザーが削除されました。",
    MESSAGE_TIMESHEET_GROUP_DELETED: "シフトリクエストが削除されました。",
    MESSAGE_USER_UPDATE_PASSWORD:"パスワードが更新されました。",

    SYNC_IKKYU_CLOSE_BOOKING: "一休売り止めエラー",
    SYNC_IKKYU_RELEASE_BOOKING:"一休キャンセル同期エラー",
    SYNC_ERROR_error_1: "パスワードが異なります",
    SYNC_ERROR_error_2: "同期ができな状態です",
    VIA_0: "公式" ,
    VIA_1: "一休" ,
    VIA_100: "その他" ,
    VIA_101:"管理者作成",　
    PAYTYPE_1: '振込',
    PAYTYPE_2: 'クレジットカード',
    PAYTYPE_3: '一休',
    PAYTYPE_4: '請求書',
    USER_TYPE_1:"管理者",
    USER_TYPE_2:"スタッフ",
    JOBS:[{value:0, label:"-"}, {value:1, label:"自営業"},{value:2, label:"会社員"},{value:3, label:"公務員"},{value:4, label:"パートアルバイト"},{value:5, label:"フリーランス"},{value:6, label:"学生"},{value:7, label:"無職"},{value:8, label:"その他"}],
    LIST_MAIL_PARTS:[{label:"宿泊者名",value:"book.name"},{label:"宿泊日",value:"book.start_date"}, {label:"料金（支払額）",value:"book.price"} , {label:"料金（総額）",value:"book.price_sub"},{label:"オプション",value:"book.options"}, {label:"キャンセル料金（総額x50%）",value:"book.price_sub_50"},  {label:"キャンセル料金（総額x10%）",value:"book.price_sub_10"}],

}
export default config;
