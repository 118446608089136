import React, { useState, useEffect,useCallback } from 'react'

import config from 'react-global-configuration';

import AccommodationStore from "../../stores/AccommodationStore"
import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActions';

import Menu from './parts/Menu';
import Header from './parts/Header';
import Alert from './parts/Alert';
import MessageBox from './parts/MessageBox'
import UserItem from "./parts/UserItem"
import PasswordBox from "./parts/PasswordBox"

import '../../styles/customer.css'
import '../../styles/user.css'

function  UserListApp(props:PropsValue) {


  const [stuff_list, setStuffList] = useState([]);


  const [alert, setAlert] = useState(null)

  const [boxMessage, setBoxMessage] = useState({message:"", box_class:"", error:0})
  const [password_box, setPasswordBox] = useState(null)
  const [userPannel, setUserPannel] = useState(null)
  const [pannelCss, setPannelCss] = useState("full")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableUserChangeHandler = useCallback(userChangeHandler, [])
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])
  const stableUserCreatedHandler = useCallback(userCreatedHandler, [])

  useEffect(() => {
    UserStore.addChangeListener(stableUserChangeHandler)
    UserStore.addChangeListener(stableUserCreatedHandler, "created")

    ErrorStore.addChangeListener(stableErrorChangeHandler)
    UserActions.getList(AuthStore.getToken())
    return function cleanup() {
      UserStore.removeChangeListener(stableUserChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      UserStore.removeChangeListener(stableUserCreatedHandler, "created")
    };

  },[stableUserChangeHandler, stableErrorChangeHandler,stableUserCreatedHandler]);

  function errorChangeHandler(){
    setBoxMessage({message:ErrorStore.getMessage(), box_class:"active", error:1})
  }

  function userCreatedHandler(){
    setBoxMessage({message:config.get("MESSAGE_USER_CREATED"), box_class:"active", error:0})
    setPannelCss("full")
    setUserPannel(null)
  }

  function addAccommodation(user_id, accommodation_id){
    const user_item = UserStore.getFromList(user_id)
    if(user_item){
      const acc_ids = user_item.accommodations
      acc_ids.push(accommodation_id)
      UserActions.update(AuthStore.getToken(), user_id, {"accommodations":acc_ids})
    }
  }

  function withdrawAccommodation(user_id, accommodation_id){
    const user_item = UserStore.getFromList(user_id)
    if(user_item){
      const acc_ids = user_item.accommodations
      acc_ids.splice(acc_ids.indexOf(accommodation_id),1)
      UserActions.update(AuthStore.getToken(), user_id, {"accommodations":acc_ids})
    }
  }


  function userChangeHandler() {
    const accommodations = AccommodationStore.getList()

    setStuffList(
      UserStore.getList().map(
        item => {
          return (<div className="mail-list-item">
            <div className="w130">{item.name}</div>
            <div className="w300 wide-hidden">{item.mail}</div>
            <div className="w100">{config.get("USER_TYPE_" + item.user_type)}</div>
            <div className="free accommodation-list wide-hidden">{
              accommodations.map( acc => {
                if(item.accommodations.indexOf(acc.id) >= 0) {
                  return <div style={{backgroundColor: '#' + acc.color}} className="acc_icon" onClick={() => withdrawAccommodation(item.id,acc.id)}>{acc.short_name}</div>
                }else{
                  return <div style={{backgroundColor: '#cecece'}} className="acc_icon" onClick={() => addAccommodation(item.id,acc.id)}>{acc.short_name}</div>
                }

              })
            }
            </div>
            <div  className="link w130 wide-hidden" onClick={() => changePassword(item.id)}>パスワード変更</div>
            <div className="w50 link wide-hidden" onClick={() => deleteItem(item.id)}>削除</div>
          </div>)
        }
      )
    )
  }

  function userDeletedHandler(){
    UserStore.removeChangeListener(userDeletedHandler, "deleted")
    setBoxMessage({message:config.get("MESSAGE_USER_DELETED"), box_class:"active", error:0})
  }

  function deleteItem(id){
    setAlert(<Alert ok="削除"  message="ユーザーを削除してよろしいですか" executor={deleteExe} cancell={cancell} param={id}/>)
  }

  function cancell(){
    setAlert(null)
  }

  function deleteExe(id){
    setAlert(null)
    UserStore.addChangeListener(userDeletedHandler, "deleted")
    UserActions.delete(AuthStore.getToken(), id)
  }

  function changePassword(id){
    UserStore.addChangeListener(userPasswordHandler, "update_password")
    setPasswordBox(<PasswordBox id={id} cancel={closePasswordBox}/>)
  }
  function closePasswordBox(){
    UserStore.removeChangeListener(userPasswordHandler, "update_password")
    setPasswordBox(null)
  }
  function userPasswordHandler(){
    closePasswordBox()
    setBoxMessage({message:config.get("MESSAGE_USER_UPDATE_PASSWORD"), box_class:"active", error:0})
  }



  function closeMessageBox(){
    setBoxMessage({message:boxMessage.message, box_class:"close", error:boxMessage.error})
  }

  function createUser(){
    setPannelCss("wide")
    setUserPannel(<UserItem  closePannel={closePannel} />)
  }

  function closePannel(){
    setPannelCss("full")
    setUserPannel(null)
  }


  return(
    <div className="inner">
        {alert}

        <main className="main">
          <Menu selected="user"/>
          <div className="main-content">
            <Header title="ユーザー一覧"/>
            <div className="open-close-section">
              <section className={"pannel " + pannelCss}>
                <div className="section-header">
                    <div className="button-create" onClick={createUser} ><i className="fas fa-plus"></i><div>ユーザー作成</div></div>
                </div>

                <div className="mail-list-item header">
                  <div className="w130">名前</div>
                  <div  className="w300 wide-hidden">メールアドレス</div>
                  <div  className="w100">タイプ</div>
                  <div  className="free wide-hidden">アクセス可能施設</div>
                  <div  className="w130 wide-hidden">パスワード変更</div>
                  <div className="w50  wide-hidden">削除</div>
                </div>

                <div className="scrollable-container">
                  <div className="scrollable-vertical">{stuff_list}</div>
                </div>

              </section>
            </div>

          </div>
        </main>
        <MessageBox message={boxMessage.message} error={boxMessage.error} main_class={boxMessage.box_class} closeMessageBox={closeMessageBox}/>
        {userPannel}
        {password_box}
    </div>
  )
}

export default UserListApp
