import React, { useCallback, useState, useEffect } from 'react'

import { format } from 'date-fns'

import '../../../styles/customer.css'
import AuthStore from '../../../stores/AuthStore'
import PlanStore from '../../../stores/PlanStore'
import ErrorStore from '../../../stores/ErrorStore'
import PaymentMethodStore from "../../../stores/PaymentMethodStore"
import SyncItemStore from "../../../stores/SyncItemStore"

import SyncItemActions from '../../../actions/SyncItemActions'

import PlanActions from '../../../actions/PlanActions'
import PaymentMethodActions from '../../../actions/PaymentMethodActions'
import Plan from '../../../vo/Plan'

import MessageBox from './MessageBox'
import SyncCreateBox from "./SyncCreateBox"
import RadioBox  from './form/Radiobox'
import CheckBox  from './form/Checkbox'
import Alert from "./Alert"
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'


/*****
id
closePannel

***/


function PlanItem(props:PropsValue) {
  const create_btn = <button className="create-btn midium" onClick={create}>プランを更新</button>

  const [plan, setItem] = useState(Plan({id:0}))
  const [payment_methods, setPaymentMethods] = useState([])
  const [mainClass, setMainClass] = useState("right")
  const [message, setMessage] = useState(null)
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")
  const [payment_method_list, setPaymentMethodList] = useState([])
  const [btn, setBtn] = useState(create_btn)

  const [syncNameBox, setSyncNameBox] = useState(null)
  const [syncList, setSyncList] = useState([])
  const [alert, setAlert] = useState(null)

  registerLocale('ja', ja)

  const stablePlanChangeHandler = useCallback(planChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stablePaymentMethodHandler = useCallback(paymentMethodHandler,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stabkeChangeHandlerRadios = useCallback(changeHandlerRadios,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableSyncItemChangeHandler = useCallback(syncItemChangeHandler,[])

  useEffect(() => {
    PaymentMethodStore.addChangeListener(stablePaymentMethodHandler)
    SyncItemStore.addChangeListener(stableSyncItemChangeHandler,"sync_item_loaded")
    PlanStore.addChangeListener(stablePlanChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    if(props.id > 0){
      PlanActions.getDetail(AuthStore.getToken(),props.accommodation_id, props.id)
    }else{
      PlanActions.setItem(new Plan({id:0,is_active:0,price:0,max_num:1}))
    }
    PaymentMethodActions.getList(AuthStore.getToken())
    SyncItemActions.getList(AuthStore.getToken(),2,props.id)
    setMainClass("right")
    return function cleanup() {
      SyncItemStore.removeChangeListener(stableSyncItemChangeHandler,"sync_item_loaded")
      PlanStore.removeChangeListener(stableErrorChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      PaymentMethodStore.removeChangeListener(stablePlanChangeHandler)
    };

  },[stableErrorChangeHandler,stablePlanChangeHandler,stablePaymentMethodHandler,stableSyncItemChangeHandler,props.id,props.accommodation_id]);

  useEffect(() => {
    setPaymentMethods(<CheckBox changeHandler={stabkeChangeHandlerRadios}  selected={plan.payment_method} name="payment_method" items={payment_method_list} />)
  },[plan.payment_method, payment_method_list,stabkeChangeHandlerRadios]);

  function paymentMethodHandler(){
    const list = PaymentMethodStore.getList().map(pm => {
      return {text:pm.name,value:pm.id}
    })
    setPaymentMethodList(list)
  }


    function syncItemChangeHandler(){
      setSyncList(SyncItemStore.getSyncList().map(item => {
        return (<div className="plan-item no-border"><input value={item.target_name} className="full" type="text" onChange={(e) => changeSyncItemHandler(item.id, e.target.value)}  onBlur = {() => updateSync(item.id)}/><div className="delete" onClick={() => removeSyncItem(item.id)}>削除</div></div>)
      }))
    }

  function planChangeHandler(){
    const item =  PlanStore.getItem()
    if(item){
      setItem(item)
      paymentMethodHandler()
    }
  }

  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
    setBtn(create_btn)
  }

  function closeMessageBox(){
    setBoxClass("close")
  }


  function updateSync(id){
    const item = SyncItemStore.getById(id)
    SyncItemActions.update(AuthStore.getToken(), item.id, item.target_name, 2, props.id)
  }

  function changeSyncItemHandler(id, value){
    SyncItemActions.updateForm(id,value)
  }

  function removeSyncItemExe(id){
    SyncItemActions.delete(AuthStore.getToken(), id, 1,props.id)
    setAlert(null)
  }

  function removeSyncItem(id){
    setAlert(<Alert message="この同期情報を削除します"  ok="削除"  executor={() => removeSyncItemExe(id)} cancell={() => setAlert(null)} />)
  }

  function cancellSyncCreateBox(){
    setSyncNameBox(null)
  }

  function createSync(){
    setSyncNameBox(<SyncCreateBox cancell={cancellSyncCreateBox} target_type={2} target_id={props.id} />)
  }

  function create(){
    const item =  PlanStore.getItem()
    if(!item.name || item.name === ""){
      setMessage("プラン名を入力してください。")
      return false
    }else if(!item.max_num || item.max_num === 0){
      setMessage("最大人数を入力してください。")
      return false
    }else if(item.price === null){
      setMessage("基本金額を入力してください。(0円でも可能です。)")
      return false
    }
    setBtn(<i className="fa fa-circle-o-notch fa-spin "></i>)
    if(!item.id){
      PlanActions.create(AuthStore.getToken(), props.accommodation_id, item)
    }else{
      PlanActions.update(AuthStore.getToken(), props.accommodation_id,item.id, item)
    }
    
  }

  function changeHandler(e){
    const target = e.target
    var value = target.value
    if(target.type === "number"){
      value = Number(target.value)
    }
    PlanActions.updateItem(target.name,value)
  }

  function changeHandlerDate(name,value){
    PlanActions.updateItem(name,value)
    if(name === "start_at"){
      PlanActions.update(AuthStore.getToken(), props.accommodation_id, props.id, {start_at:format(value,"yyyy-MM-dd")})
    }else if(name === "end_at"){
      PlanActions.update(AuthStore.getToken(), props.accommodation_id, props.id, {end_at:format(value,"yyyy-MM-dd")})
    }
  }

  function changeHandlerRadios(name,value){

    if(name === "payment_method"){
      const _item =  PlanStore.getItem()
      var _list = _item.payment_method
      if(_item.payment_method.indexOf(Number(value)) >= 0){
          _list.splice(_list.indexOf(Number(value)),1)
      }else{
        _list.push(Number(value))
      }
      PlanActions.updateItem("payment_method",_list)
      if(props.id !== 0){
        PlanActions.update(AuthStore.getToken(), props.accommodation_id, props.id, {"payment_method":_list})
      }
    }else if( name === "is_active"){
      PlanActions.updateItem(name,Number(value))
      if(props.id !== 0){
        PlanActions.update(AuthStore.getToken(), props.accommodation_id, props.id, {"is_active":Number(value)})
      }
    }
  }

  function update(param){

    if(props.id !== 0){
      PlanActions.update(AuthStore.getToken(), props.accommodation_id, props.id, param)
    }
  }



  return(
    <div>
    <section className={mainClass + " pannel "}>
      <div className="section-header">
          <div className="title">{plan.id > 0 ? "プラン詳細" : "新規プラン"}</div>
          <div className="close" onClick={props.closePannel}><i className="fas fa-times"></i></div>
      </div>

      <div className="section-content list form fix">
        <div className="list-item">
          <div className="item-head">プラン名</div>
          <div className="item-content"><input value={plan.name} type="text" className="full" name="name" onChange={changeHandler} onBlur = {() => update({name:plan.name} )} /></div>
        </div>
        <div className="list-item">
          <div className="item-head">最大人数</div>
          <div className="item-content"><input value={plan.max_num} className="small" type="number" step={1} name="max_num" onChange={changeHandler}  onBlur = {() => update({max_num:Number(plan.max_num)} )}/>&nbsp;人</div>
        </div>
        <div className="list-item">
          <div className="item-head">プラン適用期間</div>
          <div className="item-content">
            <DatePicker showPopperArrow={false} locale="ja" placeholderText="開始日" dateFormat="yyyy-MM-dd" selected={plan.start_at} onChange={(date) => changeHandlerDate("start_at", date)}  />&nbsp;　〜　&nbsp;
            <DatePicker showPopperArrow={false} locale="ja" placeholderText="終了日" dateFormat="yyyy-MM-dd" selected={plan.end_at} onChange={(date) => changeHandlerDate("end_at", date)}  />
          </div>
        </div>
        <div className="list-item">
          <div className="item-head">基本金額</div>
          <div className="item-content"><input value={plan.price}  className="small" type="number" step={1} name="price" onChange={changeHandler} onBlur = {() => update({price:Number(plan.price)} )} />&nbsp;円</div>
        </div>
        <div className="list-item">
          <div className="item-head">予約終了日</div>
          <div className="item-content"><input value={plan.close_date_before}  className="small" step={1} type="number" name="close_date_before" onChange={changeHandler} onBlur = {() => update({close_date_before:Number(plan.close_date_before)} )} />&nbsp;日前</div>
        </div>
        <div className="list-item">
          <div className="item-head">チェックイン</div>
          <div className="item-content"><input value={plan.check_in} type="text" className="full" name="check_in" onChange={changeHandler}  /></div>
        </div>

        <div className="list-item">
          <div className="item-head">チェックアウト</div>
          <div className="item-content"><input value={plan.check_out} type="text" className="full" name="check_out" onChange={changeHandler}  /></div>
        </div>


        <div className="list-item">
          <div className="item-head">支払い方法</div>
          <div className="item-content">{payment_methods}</div>
        </div>


        <div className="list-item">
          <div className="item-head">簡単な説明</div>
          <div className="item-content"><textarea value={plan.description}  className="full big"  name="description" onChange={changeHandler}  /></div>
        </div>

        <div className="list-item">
          <div className="item-head">掲載</div>
          <div className="item-content"><RadioBox  changeHandler={changeHandlerRadios}  selected={plan.is_active} name="is_active" items={[{value:0,text:"非掲載"},{value:1,text:"掲載"}]}/></div>
        </div>

        <div className="list-item">
          <div className="item-head">アイコン</div>
          <div className="item-content"><input value={plan.icon} type="text" className="small" name="icon" onChange={changeHandler}  />&nbsp;{plan.icon ? <i className={plan.icon} ></i> : null}</div>
        </div>


        <div className="list-item">
          <div className="item-head">通知メール</div>
          <div className="item-content"><input value={plan.notice_mail} type="text" className="full" name="notice_mail" onChange={changeHandler}  /></div>
        </div>

        <div className="list-item">
          <div className="item-head">一休同期名</div>
          <div className="item-content plan">
            {syncList}
            <div className="button-create-left" onClick={createSync}><i className="fas fa-plus"></i><div>&nbsp;同期名を新規作成</div></div>

          </div>
        </div>

        <div className="list-item">
          <div className="item-head">一休 プランID</div>
          <div className="item-content"><input value={plan.ikyu_id} type="text" className="full" name="ikyu_id" onChange={changeHandler} onBlur = {() => update({ikyu_id:plan.ikyu_id} )}  /></div>
        </div>


      </div>
    
        <div className="button-wrap">
          <div className="message">{message}</div>
          <div className="section-content  button">
            {btn}
          </div>
        </div>


    </section>
    {syncNameBox}
    {alert}
    <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
    </div>

  )
}

export default PlanItem
