import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import SyncItem from "../vo/SyncItem"

var CHANGE_EVENT = "change"

var SYNC_ITEM_CREATED = "sync_item_created"
var SYNC_ITEM_LOADED = "sync_item_loaded"

var _sync_items = []


var setSyncInfo = (list) => {
  _sync_items = list.map( item => {
    return SyncItem({id:item.id, target_name:item.target_name})
  })
}

var update = (id, target_name) => {
  for(var i = 0; i < _sync_items.length; i++){
    if(_sync_items[i].id === id) {
      _sync_items[i] = _sync_items[i].set("target_name", target_name)
    }
  }
}


var SyncItemStore = assign({},EventEmitter.prototype,{



  getSyncList(){
    return _sync_items
  },

  getById(id){
    for(var i = 0; i < _sync_items.length; i++){
      if(_sync_items[i].id === id) {
        return _sync_items[i]
      }
    }
    return null
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする


    case Constants.SYNC_ITEM_CREATED:
      SyncItemStore.emitChange(SYNC_ITEM_CREATED)
    break;
    case Constants.SYNC_ITEM_LOADED:
      setSyncInfo(action.list)
      SyncItemStore.emitChange(SYNC_ITEM_LOADED)
    break;
    case Constants.SYNC_ITEM_UPDATE:
      update(action.id, action.target_name)
      SyncItemStore.emitChange(SYNC_ITEM_LOADED)
    break;
    default:
  }
})
export default SyncItemStore
