import React, { useState, useEffect,useCallback } from 'react'

import {  useParams} from 'react-router-dom';
import AuthStore from '../../../stores/AuthStore';
import BookActions from '../../../actions/BookActions';
import BookStore from '../../../stores/BookStore';
import CalendarInfoActions from '../../../actions/CalendarInfoActions';
import CalendarInfoStore from '../../../stores/CalendarInfoStore';

import ErrorStore from '../../../stores/ErrorStore';
import Menu from '../parts/Menu';
import Header from '../parts/Header';
import BookListItem from '../parts/BookListItem';
import BookItem from '../parts/BookItem'
import CalendarInfoListItem from "../parts/CalendarInfoListItem"


import "../../../styles/dashboard.css"
import '../../../styles/customer.css'

function BookListApp(props:PropsValue) {


  const [list, setList] = useState([]);

  const [pannelCss, setPannelCss] = useState("full")
  const [bookPannel, setBookPannel] = useState(null)
  const {accommodation_id } = useParams()
  const { year } = useParams();
  const { month } = useParams();
  const { day } = useParams();
  const [calendar_comment, setCalendarComment] = useState(null)
// eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler, [])
  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  const stableCalendarInfoChangeHandler = useCallback(calendarInfoChangeHandler, [])

  useEffect(() => {

    BookStore.addChangeListener(stableBookChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    CalendarInfoStore.addChangeListener(stableCalendarInfoChangeHandler)
    BookActions.getList(AuthStore.getToken(), accommodation_id, new Date(year,month-1, day), new Date(year,month-1, day), null, null, true)
    CalendarInfoActions.getList(AuthStore.getToken(), accommodation_id, new Date(year,month-1, day), new Date(year,month-1, day),true)

    return function cleanup() {

      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      CalendarInfoStore.removeChangeListener(stableCalendarInfoChangeHandler)
    };

  },[stableBookChangeHandler,stableErrorChangeHandler,stableCalendarInfoChangeHandler,accommodation_id,year,month,day]);


  function bookChangeHandler(){

    const books = BookStore.getList().map(item =>{
      return <BookListItem key={"book_p_" + item.id} item={item} openPannel={openPannel} with_days={true} with_room_name={true} />
    })
    setList(
      books
    )
  }

  function calendarInfoChangeHandler(){
    const list = CalendarInfoStore.getList()
    setCalendarComment(
      list.map( item => {
        return <CalendarInfoListItem item={item} />
      })
    )
  }

  function errorChangeHandler(){

  }


  function openPannel(id){
    setPannelCss("wide")
    setBookPannel(<BookItem id={id} closePannel={closePannel} date={ new Date(year,month-1, day)}  accommodation_id={accommodation_id}  />)
  }

  function closePannel(){
    setPannelCss("full")
    setBookPannel(null)
  }

  function createNew(){
    setPannelCss("wide")
    setBookPannel(<BookItem id={0} accommodation_id={accommodation_id} closePannel={closePannel} date={new Date(year,month-1,day)}/>)
  }
  return(
    <div className="inner">

        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id}/>
          <div className="main-content">
            <Header accommodation_id = {accommodation_id} id="calendar"/>
            <div className="free-pannel scrollable-vertical">
              <section className={"pannel " + pannelCss}>

              <div className="toggle-pannel-header">
                <div className="toggle-title">{year}年{month}月{day}日の予定</div>

              </div>
              <div className="calendar-comments">
                {calendar_comment}
              </div>

              <div className="toggle-pannel-header">
                  <div className="toggle-title">予約一覧</div>
                  <div className="right-menu">
                    <div className="inline-link" onClick={createNew}><i className="fas fa-plus-circle"></i> 新規予約作成</div>
                  </div>

                </div>
                <div className="item-list-item header">
                  <div  className="accommodation">宿泊施設</div>
                  <div  className="w180">部屋タイプ</div>
                  <div  className="w120">チェックイン</div>
                  <div  className="w120">チェックアウト</div>
                  <div className="free">宿泊者</div>
                  <div  className="w100 wide-hidden">宿泊人数</div>
                  <div  className="w100 wide-hidden">支払い金額</div>
                  <div  className="w120">予約発生日</div>
                  <div  className="w120 wide-hidden">振込期限</div>
                  <div  className="button wide-hidden"></div>
                </div>

                <div className="scrollable-container">
                  <div>{list}</div>
                </div>

              </section>
              {bookPannel}
            </div>
          </div>

        </main>
    </div>
  )
}

export default BookListApp
