import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import AuthActions from "./AuthActions"
import config from 'react-global-configuration';


var RoomActions = {

  getList(token,accommodation_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms", {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
        return null
      }else{
        throw new Error('Network response was not ok.');
      }

    }).then(function(data) {
      
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.ROOM_LIST_LOADED,
          list:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  getItem(token,accommodation_id,id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms/" + id, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {

      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
        return null
      }else{

        throw new Error('Network response was not ok.');
      }

    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.ROOM_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },



  create(token,accommodation_id, item){
    const form = {
      name:item.name,
      max_num:item.max_num
    }
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms" , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        RoomActions.getList(token,accommodation_id)
        AppDispatcher.dispatch({
          actionType: Constants.ROOM_CREATED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  update(token,accommodation_id,plan_id, params){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms/" + plan_id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        RoomActions.getList(token,accommodation_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },


  delete(token,accommodation_id, plan_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms/" + plan_id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 204  ){
        return true
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        RoomActions.getList(token,accommodation_id)
        AppDispatcher.dispatch({
          actionType: Constants.ROOM_DELETED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  attach(token,accommodation_id, plan_id, room_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms/" + room_id + "/attach/" + plan_id  , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.ROOM_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  disattach(token,accommodation_id, plan_id, room_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/rooms/" + room_id + "/attach/" + plan_id  , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return true
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        RoomActions.getItem(token,accommodation_id,room_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },


  updateItem(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.ROOM_UPDATE,
      name:name,
      value:value
    })
  },

  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.ROOM_SET,
      item:item
    })
  }

}

export default RoomActions
