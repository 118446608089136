import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';


var SyncSettingActions = {


  getList(token,accommodation_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + config.get("API_SYNC_SETTING"), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      console.log("get accommodation")
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
         window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.SYNC_SETTING_LOADED,
          list:data.sync_settings
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })


  },

  update(token,accommodation_id, id, item){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + config.get("API_SYNC_SETTING") + "/" + id, {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      console.log("get accommodation")
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
         window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        SyncSettingActions.getList(token,accommodation_id)
        console.log("updated")
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })


  },

  updateItem(service_id,name,value){
    AppDispatcher.dispatch({
      actionType: Constants.SYNC_SETTING_UPDATE,
      service_id:service_id,
      name:name,
      value:value
    })
  }



}

export default SyncSettingActions
