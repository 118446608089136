
  export const selectFormStyles = {
    control: styles =>
      (
        {
          ...styles,
          borderRadius:'0',
          boxShadow:'none ',
          fontSize: '.8em',
          marginBottom:'2px'
         }
      ),

      menu: styles => (
        { ...styles,

          color:"#000",
          width:'100%',
          borderRadius:'0',
          fontSize: '.8em',
        }
      ),
      container: styles => (
        { ...styles,
          borderColor:'#333',
          fontSize: '.8em',
        }
      ),
  };
