import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

import CalendarInfo from "../vo/CalendarInfo"


var CHANGE_EVENT = "change"


var _items = []


var setList = (items) => {
  const _tmp_list = items.map( _ => {return CalendarInfoStore.createObject(_)})
  _items = _items.concat(_tmp_list)
}

var updateCommnet = (id, comment) => {

  const index = CalendarInfoStore.getIndexById(id)
  if(index !== null){
    _items[index] = _items[index].set("comment",comment)
  }
}

var updateItem = (id, item) => {
  const index = CalendarInfoStore.getIndexById(id)
  if(index !== null){
      _items[index] = CalendarInfoStore.createObject(item)
  }
}

var addNew = (accommodation_id, target_date) => {
  _items.push(new CalendarInfo({accommodation_id:accommodation_id,target_date:target_date}))
}

var deleteItem = (id) => {
  const index = CalendarInfoStore.getIndexById(id)

  if(index !== null){
     _items.splice(index,1)
  }
}


var CalendarInfoStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getById(id){
    for(var i = 0; i < _items.length; i++){
      if(_items[i].id === id){
        return _items[i]
      }
    }
    return null
  },

  getIndexById(id){
    for(var i = 0; i < _items.length; i++){
      if(_items[i].id === id){
        return i
      }
    }
    return null
  },

  createObject(item) {
    return new CalendarInfo({
      id: item.id,
      accommodation_id: item.accommodation_id,
      target_date: new Date(item.target_date),
      comment: item.comment
    })
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.CALENDAR_INFO_LIST_LOADED:
      setList(action.list);
      CalendarInfoStore.emitChange()
    break;
    case Constants.CALENDAR_INFO_UPDATE:
      updateCommnet(action.id, action.comment)
      CalendarInfoStore.emitChange()
    break
    case Constants.CALENDAR_INFO_UPDATED:
      updateItem(action.id, action.item)
      CalendarInfoStore.emitChange()
    break
    case Constants.CALENDAR_INFO_CREATE_NEW:
      addNew(action.accommodation_id, action.target_date)
      CalendarInfoStore.emitChange()
    break
    case Constants.CALENDAR_INFO_DELETE :
      deleteItem(action.id)
      CalendarInfoStore.emitChange()
    break
    default:
  }


})
export default CalendarInfoStore
