import React, { useCallback, useState, useEffect } from 'react'


import RadioBox from "./form/Radiobox"
import User from "../../../vo/User"
import AuthStore from '../../../stores/AuthStore'
//import ErrorStore from '../../../stores/ErrorStore'
import UserStore from '../../../stores/UserStore'
import AccommodationStore from "../../../stores/AccommodationStore"
import UserActions from '../../../actions/UserActions'


import '../../../styles/customer.css'

function UserItem(props:PropsValue) {

  const [user_item, setItem] = useState(User())
  const [mainClass, setMainClass] = useState("")
  const [acc_selecter, SetAccSelect] = useState(null)

  const stableUserChangeHandler = useCallback(userChangeHandler, [])

  function userChangeHandler(){
    const item = UserStore.getItem()

    setItem(item)
    setMainClass("right")
    SetAccSelect(

        Number(item.user_type) === 1 ? "全て" :

        AccommodationStore.getList().map( acc => {
          if(item.accommodations.indexOf(Number(acc.id)) >= 0) {
            return <div style={{backgroundColor: '#' + acc.color}} className="acc_icon" onClick={() => withdrawAccommodation(acc.id)}>{acc.short_name}</div>
          }else{
            return <div style={{backgroundColor: '#cecece'}} className="acc_icon" onClick={() => addAccommodation(acc.id)}>{acc.short_name}</div>
          }
        })

    )
  }

  useEffect(() => {
    UserStore.addChangeListener(stableUserChangeHandler)
    UserActions.setItem(new User({user_type:2}))

    return function cleanup() {
      UserStore.removeChangeListener(stableUserChangeHandler)
    };

  },[stableUserChangeHandler]);

  function create(){
    UserActions.create(AuthStore.getToken(),UserStore.getItem() )
  }

  function changeHandler(e){
    const target = e.target
    UserActions.updateItem(target.name,target.value)

  }
  function updateHandler(name,value){
    UserActions.updateItem(name,value)
  }
  function addAccommodation( accommodation_id){
    const user_item = UserStore.getItem()
    if(user_item){
      const acc_ids = user_item.accommodations
      acc_ids.push(accommodation_id)
      UserActions.updateItem("accommodations", acc_ids)
    }

  }

  function withdrawAccommodation( accommodation_id){
    const user_item = UserStore.getItem()
    if(user_item){
      const acc_ids = user_item.accommodations
      acc_ids.splice(acc_ids.indexOf(accommodation_id),1)
      UserActions.updateItem("accommodations", acc_ids)
    }
  }

  return(
    <div>
    <section className={mainClass + " pannel "}>
      <div className="section-header">
          <div className="title">新規ユーザー</div>
          <div className="close" onClick={props.closePannel}><i className="fas fa-times"></i></div>
      </div>

      <div className="section-content list form fix">
        <div className="list-item">
          <div className="item-head">ユーザー名</div>
          <div className="item-content"><input type="text" value={user_item.name} name="name" onChange={changeHandler} className="full" /></div>
        </div>

        <div className="list-item">
          <div className="item-head">メールアドレス</div>
          <div className="item-content"><input type="text" value={user_item.mail} className="full" name="mail" onChange={changeHandler} /></div>
        </div>

        <div className="list-item">
          <div className="item-head">パスワード</div>
          <div className="item-content"><input type="password" value={user_item.password} className="full" name="password" onChange={changeHandler} /></div>
        </div>

        <div className="list-item">
          <div className="item-head">タイプ</div>
          <div className="item-content"><RadioBox items={[{value:1,text:"管理者"},{value:2,text:"スタッフ"}] } selected={Number(user_item.user_type)} name="user_type" changeHandler={updateHandler}/></div>
        </div>

        <div className="list-item">
          <div className="item-head">アクセス可能施設</div>
          <div className="item-content acc-list">
          {acc_selecter}
          </div>
        </div>

      </div>
      <div className="section-content  button">
          <button className="create-btn midium" onClick={create}>ユーザーを作成</button>
      </div>


    </section>
    </div>

  )
}

export default UserItem
