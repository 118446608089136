import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import AuthActions from "./AuthActions"
import config from 'react-global-configuration';


var UserActions = {


    getList(token){

      fetch(config.get("API_PATH") + config.get("API_USER") , {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token
        },
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
        }

        throw new Error('Network response was not ok.');
      }).then(function(data) {

        if(data && !data.error){
          AppDispatcher.dispatch({
            actionType: Constants.USER_LIST_LOADED,
            list:data
          })
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
    },


    create(token, user){
      const params = {
        "mail":user.mail,
      	"password":user.password,
      	"name":user.name,
      	"accommodations":user.accommodations,
      	"user_type":Number(user.user_type)
      }
      fetch(config.get("API_PATH") + config.get("API_USER") , {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
        }
        throw new Error('Network response was not ok.');
      }).then(function(data) {
        if(data && !data.error){
          UserActions.getList(token)
          AppDispatcher.dispatch({
            actionType: Constants.USER_CREATED,
          })
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      })
    },


  update(token, id, params){
    fetch(config.get("API_PATH") + config.get("API_USER") + "/" + id , {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {
      if(data && !data.error){
        UserActions.getList(token)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  updatePassword(token, id, password){
    fetch(config.get("API_PATH") + config.get("API_USER") + "/" + id , {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"password":password})
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.USER_UPDATE_PASSWORD
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },


  delete(token, id){
    fetch(config.get("API_PATH") + config.get("API_USER") + "/" + id , {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function(response) {
      if(response.status === 204){
        return {status:"deleted"}
      }else if(response.status === 401){
        AuthActions.logout()
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {
      if(data && !data.error){
        UserActions.getList(token)
        AppDispatcher.dispatch({
          actionType: Constants.USER_DELETED
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  setItem(item) {
    AppDispatcher.dispatch({
      actionType: Constants.USER_SET_ITEM,
      item:item
    })
  },
  updateItem(name, value) {
    AppDispatcher.dispatch({
      actionType: Constants.USER_UPDATE,
      name:name,
      value:value
    })
  }

  //updateForm()
}

export default UserActions
