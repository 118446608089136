import { Record } from 'immutable'


const BookOption = Record({
  id:null,
  option_id:null,
  unit: null,
  days:null,
  comment: null,
  paid_at: null
});
export default BookOption;
