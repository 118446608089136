import { Record } from 'immutable'


const TimeSheetGroup = Record({
  id:0,
  end_at:null,
  start_date: null,
  end_date:null,
});
export default TimeSheetGroup;
