import React, { useState, useEffect } from 'react'
import '../../../styles/book_base.css'

function BookBase(props:PropsValue) {

  const [message_box, setMessageBox] = useState(null)

  //const stableCompanyChangeHandlerr = useCallback(companyChangeHandler, [])
  /**
   *  <div className="overlay-wrap">
          <div className="overlay-container"></div>
        </div>
   */
 
  useEffect(() => {
  
    return function cleanup() {
      
    };
  },[]);

  function closePannel(){
    setMessageBox(null)
  }

  useEffect(() => {
    setMessageBox( props.mail ? 
      <div className="overlay-wrap">
          <div className="overlay-container">
            
            <div className="content-box">
            <div className="close right" onClick={closePannel}><i className="fas fa-times"></i></div> 
              <div>
                <div className="overlay-form-list-item">
                  <label>タイトル</label>
                  <div className="item-value ">
                    <div className="item-value ">{props.mail.title}</div>
                  </div>
                </div>
                <div className="overlay-form-list-item">
                  <label>本文</label>
                  <div className="item-value ">
                    <div className="item-value " dangerouslySetInnerHTML={{ __html :props.mail.body.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></div>
                  </div>
                </div>
              </div>

            </div>
            
          </div>
      </div>
     : null)
  },[props.mail]);

  

  return(
    <div id="book-base">
       {message_box}
       
    </div>
  )
}

export default BookBase
