import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import AuthActions from "./AuthActions"
import AccommodationActions from "./AccommodationActions"
import config from 'react-global-configuration';
import { format } from 'date-fns'

var TimeSheetActions = {


    getList(token){

      fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") , {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token
        },
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
          return
        }
        throw new Error('Network response was not ok.');
      }).then(function(data) {

        if(data && !data.error){

          AppDispatcher.dispatch({
            actionType: Constants.TIMESHEET_GROUP_LIST_LOADED,
            list:data
          })
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {

        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
    },


    getItem(token, id){

      fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") + "/" + id , {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token
        },
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
          return
        }
        throw new Error('Network response was not ok.');
      }).then(function(data) {
      
        if(data && !data.error){
          AppDispatcher.dispatch({
            actionType: Constants.TIMESHEET_GROUP_ITEM_LOADED,
            item:data
          })
          AccommodationActions.getList(token, TimeSheetActions.getBooks)
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {

        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
    },


    create(token, timesheet_group){
      const params = {
        "end_at":timesheet_group.end_at,
      	"start_date":format(timesheet_group.start_date,"yyyy-MM-dd"),
      	"end_date":format(timesheet_group.end_date,"yyyy-MM-dd")
      }
      fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") , {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
        }
        throw new Error('Network response was not ok.');
      }).then(function(data) {
        if(data && !data.error){
          TimeSheetActions.getList(token)
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      })
    },


  update(token, id, params){
    fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") + "/" + id , {
      method: "PATCH",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        AuthActions.logout()
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {
      if(data && !data.error){
        TimeSheetActions.getList(token)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },




  delete(token, id){
    fetch(config.get("API_PATH") + config.get("API_TIMESHEET_GROUP") + "/" + id , {
      method: "DELETE",
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function(response) {
      if(response.status === 204){
        return {status:"deleted"}
      }else if(response.status === 401){
        AuthActions.logout()
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {
      if(data && !data.error){
        TimeSheetActions.getList(token)
        AppDispatcher.dispatch({
          actionType: Constants.TIMESHEET_GROUP_DELETED
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  getBooks(token){
    console.log("getBooks")
  },

  setItem(item) {
    AppDispatcher.dispatch({
      actionType: Constants.TIMESHEET_GROUP_SET_ITEM,
      item:item
    })
  },
  updateItem(name, value) {
    AppDispatcher.dispatch({
      actionType: Constants.TIMESHEET_GROUP_UPDATE,
      name:name,
      value:value
    })
  }

  //updateForm()
}

export default TimeSheetActions
