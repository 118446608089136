import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';


import ErrorStore from '../../stores/ErrorStore';
import AuthStore from '../../stores/AuthStore';
import MailReceiveStore from '../../stores/MailReceiveStore';
import MailReceiveActions from "../../actions/MailReceiveActions"
import Menu from './parts/Menu';
import Header from './parts/Header';
import ReceiveMailItem from "./parts/ReceiveMailItem"
import BookItem from "./parts/BookItem"
import AttachBookOnMail from "./parts/AttachBookOnMail"

import '../../styles/customer.css'


function  ReceiveMailApp(props:PropsValue) {

  const { id } = useParams();
  const [item_html, setItemHtml] = useState([]);

  const [right_pannel, setRightPannel] = useState(null)
  const stableMailChangeHandler = useCallback(mailChangeHandler, [])
  const stableErrorChangeHandler = useCallback(errorChangeHandler, [])


  useEffect(() => {
    MailReceiveStore.addChangeListener(stableMailChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    MailReceiveActions.getItem(AuthStore.getToken(), id)
    
    return function cleanup() {
      MailReceiveStore.removeChangeListener(stableMailChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
    };

  },[stableMailChangeHandler, stableErrorChangeHandler,id]);

  function errorChangeHandler(){
    
  }

  function closePannel() {
    return null
  }

  function mailChangeHandler() {
    const _item = MailReceiveStore.getItem()
    
    setItemHtml( <ReceiveMailItem item= {_item} />)
    if(_item.book_id !== null){
      setRightPannel(<BookItem id={_item.book_id} closePannel={closePannel} accommodation_id={_item.accommodation_id} />)
    }else{
      setRightPannel(<AttachBookOnMail />)
    }
  }

  return(
    <div className="inner">
       
        <main className="main">
          <Menu selected="mail"/>
          <div className="main-content">
            <Header title="受信メール"/>
            <div className="open-close-section">
              <section className="pannel left">
                {item_html}
              </section>
              {right_pannel}
            </div>

          </div>
        </main>
        
    </div>
  )
}

export default ReceiveMailApp
