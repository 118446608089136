import React, { useCallback, useState, useEffect } from 'react'
import '../../../styles/customer.css'
import "../../../styles/selecter.css"
import AuthStore from '../../../stores/AuthStore'
import PlanStore from '../../../stores/PlanStore'
import RoomStore from '../../../stores/RoomStore'
import SyncItemStore from "../../../stores/SyncItemStore"
import ErrorStore from '../../../stores/ErrorStore'

import SyncItemActions from '../../../actions/SyncItemActions'
import RoomActions from '../../../actions/RoomActions'
import PlanActions from '../../../actions/PlanActions'

import Room from '../../../vo/Room'

import SyncCreateBox from "./SyncCreateBox"
import MessageBox from './MessageBox'
import Alert from "./Alert"

import Select from 'react-select'

/*****
id
closePannel

***/


function PlanItem(props:PropsValue) {
  const create_btn = <button className="create-btn midium" onClick={create} > 部屋を作成</button>

  const [room, setItem] = useState(Room({id:0}))
  const [plans, setPlans] = useState([])
  const [plan_list, setPlanList] = useState([])
  const [mainClass, setMainClass] = useState("right")
  const [message, setMessage] = useState(null)
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [boxClass, setBoxClass] = useState("")
  const [syncNameBox, setSyncNameBox] = useState(null)
  const [syncList, setSyncList] = useState([])
  const [alert, setAlert] = useState(null)
  const [btn, setBtn] = useState(create_btn)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableRoomChangeHandler = useCallback(roomChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stablePlanChangeHandler = useCallback(planChangeHandler, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableSyncItemChangeHandler = useCallback(syncItemChangeHandler,[])
  const accStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'0',
        boxShadow:'none ',
        border:"1px solid #eee"
       }
    ),

    container: styles => (
      { ...styles,
        backgroundColor: 'white',
        width:'100%',
        textAlign:'left'
      }
    )
  };


  useEffect(() => {

    SyncItemStore.addChangeListener(stableSyncItemChangeHandler,"sync_item_loaded")
    RoomStore.addChangeListener(stableRoomChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    PlanStore.addChangeListener(stablePlanChangeHandler)
    PlanActions.getList(AuthStore.getToken(),props.accommodation_id)
    SyncItemActions.getList(AuthStore.getToken(),1,props.item.id)
    if(props.item.id > 0){
      RoomActions.getItem(AuthStore.getToken(), props.accommodation_id, props.item.id)
    }else{
      RoomActions.setItem(props.item)
    }


    setMainClass("right")
    return function cleanup() {
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      SyncItemStore.removeChangeListener(stableSyncItemChangeHandler,"sync_item_loaded")
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      PlanStore.removeChangeListener(stablePlanChangeHandler)
    };

  },[stableErrorChangeHandler,stableRoomChangeHandler,stablePlanChangeHandler,stableSyncItemChangeHandler,props.item.id,props.accommodation_id,props.item]);

  function roomChangeHandler(){
    const item =  RoomStore.getItem()
    if(item){
      setItem(item)
      const list = item.plans.map( item => {
        return <div className="plan-item"><div className="name">{item.name} <span className="sml">({item.start_at}〜{item.start_at} )</span></div><div className="delete" onClick={() => disattachPlan(item.id)}>解除</div></div>
      })
      setPlans(list)
      planChangeHandler()
    }
  }

  function planChangeHandler(){
    const item =  RoomStore.getItem()
    if(item){
      const list = PlanStore.getList().filter( plan => item.plans.findIndex(({id}) => id === plan.id) === -1).map( _p => {
        return {value:_p.id, label:_p.name}
      })
      setPlanList(list)
    }

  }

  function syncItemChangeHandler(){
    setSyncList(SyncItemStore.getSyncList().map(item => {
      return (<div className="plan-item no-border"><input value={item.target_name} className="full" type="text" onChange={(e) => changeSyncItemHandler(item.id, e.target.value)}  onBlur = {() => updateSync(item.id)}/><div className="delete" onClick={() => removeSyncItem(item.id)}>削除</div></div>)
    }))
  }

  function updateSync(id){
    const item = SyncItemStore.getById(id)
    SyncItemActions.update(AuthStore.getToken(), item.id, item.target_name, 1, props.item.id)
  }

  function changeSyncItemHandler(id, value){
    SyncItemActions.updateForm(id,value)
  }

  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setBoxClass("active")
    setBtn(create_btn)
  }

  function closeMessageBox(){
    setBoxClass("close")
  }




  function create(){
    const item =  RoomStore.getItem()
    if(!item.name || item.name === ""){
      setMessage("部屋名を入力してください。")
      return false
    }else if(!item.max_num || item.max_num === 0){
      setMessage("部屋数を入力してください。")
      return false
    }
    setBtn(<i className="fa fa-circle-o-notch fa-spin "></i>)
    RoomActions.create(AuthStore.getToken(), props.accommodation_id, item)
  }


  function changeHandler(e){
    const target = e.target
    var value = target.value
    if(target.type === "number"){
      value = Number(target.value)
    }
    RoomActions.updateItem(target.name,value)
  }


  function update(param){
    if(props.item.id !== 0){
      RoomActions.update(AuthStore.getToken(), props.accommodation_id, props.item.id, param)
    }
  }

  function changePlanHandler(e){
    RoomActions.attach(AuthStore.getToken(),props.accommodation_id, e.value, props.item.id)
  }

  function disattachPlan(id){
    RoomActions.disattach(AuthStore.getToken(),props.accommodation_id, id, props.item.id)
  }

  function removeSyncItemExe(id){
    SyncItemActions.delete(AuthStore.getToken(), id, 1,props.item.id)
    setAlert(null)
  }

  function removeSyncItem(id){
    setAlert(<Alert message="この同期情報を削除します"  ok="削除"  executor={() => removeSyncItemExe(id)} cancell={() => setAlert(null)} />)
  }


  function cancellSyncCreateBox(){
    setSyncNameBox(null)
  }
  function createSync(){
    setSyncNameBox(<SyncCreateBox cancell={cancellSyncCreateBox} target_type={1} target_id={props.item.id} />)
  }

  return(
    <div>
    <section className={mainClass + " pannel "}>
      <div className="section-header">
          <div className="title">{room.id > 0 ? "部屋詳細" : "新規部屋作成"}</div>
          <div className="close" onClick={props.closePannel}><i className="fas fa-times"></i></div>
      </div>

      <div className="section-content list form fix">
        <div className="list-item">
          <div className="item-head">部屋名</div>
          <div className="item-content"><input value={room.name} type="text" className="full" name="name" onChange={changeHandler} onBlur = {() => update({name:room.name} )} /></div>
        </div>
        <div className="list-item">
          <div className="item-head">部屋数</div>
          <div className="item-content"><input value={room.max_num} className="small" type="number" step={1} name="max_num" onChange={changeHandler}  onBlur = {() => update({max_num:Number(room.max_num)} )}/>&nbsp;部屋</div>
        </div>

        {room.id > 0 ?
        <div className="list-item big-margin">
          <div className="item-head">利用可能プラン</div>
          <div className="item-content plan">
          <Select
            options={plan_list}
            styles = {accStyles}
            placeholder = "利用可能プランの追加"
            onChange = {changePlanHandler}
          />

            {plans}

          </div>

        </div>
        : null}
        <div className="list-item">
          <div className="item-head">一休同期名</div>
          <div className="item-content plan">
            {syncList}
            <div className="button-create-left" onClick={createSync}><i className="fas fa-plus"></i><div>&nbsp;同期名を新規作成</div></div>

          </div>
        </div>
      </div>
      {room && room.id === 0 ?
        <div className="button-wrap">
          <div className="message">{message}</div>
          <div className="section-content  button">
            {btn}
          </div>
        </div>
      : null
      }


    </section>
    {syncNameBox}
    {alert}
    <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
    </div>

  )
}

export default PlanItem
