



import React, { useState, useEffect } from 'react'

import {  useParams} from 'react-router-dom';


import Menu from '../../parts/Menu';
import Header from '../../parts/Header';

import useCouponState from './useCouponState.tsx';
import useAuthState from '../../../../auth/useAuthState';
import "../../../../styles/dashboard.css"
import '../../../../styles/customer.css'
import { format } from 'date-fns';

function CouponListApp(props:PropsValue) {

  const {accommodation_id } = useParams()
 // const [alert, setAlert] = useState(null)

  const [coupons, setCoupons] = useState(null)
  //const [boxMessage, setBoxMessage] = useState("")


  const {token,getToken} = useAuthState()
  const {coupon, getCoupon} = useCouponState()


  useEffect(() => {

    if(coupon){
      setCoupons(
        coupon.map(c => {
          return(
            <div className="mail-list-item">
              <div className="w130">{format(c.start_date, "yyyy年M月d日")}</div>
              <div className="w130">{c.end_date ? format(c.end_date, "yyyy年M月d日") : null}</div>
              <div className="w130">{c.getTypeString()}</div>
              <div className="w130">{c.discount_value.toLocaleString()}{c.getUnitString()}</div>
            </div>
          )
        })
      )
    }

  },[coupon]);

  useEffect(() => {

    if(token){
      getCoupon(token, accommodation_id)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {

    getToken()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const createNew = () => {
    
  }



  return(
    <div className="inner">

        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id}/>
          <div className="main-content">
            <Header accommodation_id = {accommodation_id} id="calendar"/>
            <div className="free-pannel scrollable-vertical">
              <section className="pannel">
                <div className="section-header">
                  <div className="title">クーポン一覧</div>
                  <div className="select">
                   
                  </div>
                  <div className="right-menu">
                    <div className="inline-link" onClick={createNew}><i className="fas fa-plus-circle"></i> 新規クーポン作成</div>
                  </div>
                </div>

                <div className="mail-list-item header">
                  <div className="w130">開始日</div>
                  <div className="w130">終了日</div>
                  <div className="w130">タイプ</div>
                  <div className="w130">割引</div>
                </div>

                {coupons}

              </section>
            </div>
          </div>
         
          
        </main>
    </div>
  )
}

export default CouponListApp
