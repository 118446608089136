import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

import FreeMail from "../vo/FreeMail"


var CHANGE_EVENT = "change"

var _item = null

const update = (name,value) => {
  _item = _item.set(name, value)
}

const createNew = () => {
  _item = FreeMail()
}


var FreeMailStore = assign({},EventEmitter.prototype,{


  getItem(){
    return _item
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.FREE_MAIL_UPDATE:
      update(action.name,action.value);
      FreeMailStore.emitChange()
    break;

    case Constants.FREE_MAIL_CREATE_NEW:
      createNew();
      FreeMailStore.emitChange()
    break;


    default:
  }


})
export default FreeMailStore
