import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { format,addMonths,addDays,getDaysInMonth,setDate,getYear,getMonth} from 'date-fns'
import ja from 'date-fns/locale/ja'

import AuthStore from '../../../stores/AuthStore';
import PlanActions from '../../../actions/PlanActions';
import PlanStore from '../../../stores/PlanStore';
import BookActions from "../../../actions/BookActions"
import BookStore from "../../../stores/BookStore"

import Menu from '../parts/Menu';
import Header from '../parts/Header';
import Select from 'react-select'
import "../../../styles/selecter.css"
import "../../../styles/price.css"

function SyncCheckApp(props:PropsValue) {

  const { accommodation_id } = useParams();
  const { plan_id } = useParams();

  const [target_date, setTaretDate] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState([])


  const today = setDate(new Date(),1)
  const dates = (new Array(6)).fill(0).map((item, index) => {
    const target = addMonths(today,index)
    return {"label":format(target, ' yyyy年MM月'), "value":target}
  })
  const accStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'2px',
        boxShadow:'none ',
       }
    ),

    container: styles => (
      { ...styles,
        backgroundColor: 'white',
        width:'200px',
      }
    ),

  };


  const stablePlanChangeHandler = useCallback(planChangeHandler, [])
  function planChangeHandler(){
    const plan = PlanStore.getItem()
    setItem(plan)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableCreateDays = useCallback(createDays,[])

  useEffect(() => {
    PlanStore.addChangeListener(stablePlanChangeHandler)

    PlanActions.getDetail(AuthStore.getToken(),accommodation_id,plan_id)
    return function cleanup() {
      PlanStore.removeChangeListener(stablePlanChangeHandler)

    };

  },[stablePlanChangeHandler,accommodation_id,stableCreateDays,plan_id]);


  function check(){
    PlanActions.clearIkyu()
    BookStore.addChangeListener(createDays)
    PlanStore.addChangeListener(createDays, "check_loaded")
    BookActions.getList(AuthStore.getToken(), accommodation_id, target_date, addMonths(target_date,1))

    PlanActions.getIkyuStatus(AuthStore.getToken(),accommodation_id, plan_id, getYear(target_date), getMonth(target_date) + 1)
    //createDays()
  }

  function createDays(){

    const dif = getDaysInMonth(target_date)

    setList(
      (new Array(dif)).fill(0).map((t, index) => {
        const d = addDays(target_date,index)
        const book_number = BookStore.getDataFromDate(d).filter(book => book.plan_id === Number(plan_id)).length
        const status = PlanStore.getIkyuStatus(index + 1)
        console.log(status)
        return (
          <div className={"mail-list-item week_" + d.getDay()}>
            <div className="date with-week">{format(d, 'yyyy年MM月dd日 (eeeee)',{locale: ja})}</div>
            <div className="w100">{book_number}件</div>
            <div className="w100">{status === 0 ? "" : status !== null ? "FULL" : "Loading..."}</div>
            <div className="w100">{Number(status) === Number(book_number) ? "" : status !== null ? <a rel="noopener noreferrer" href={"/m/accommodation/" + accommodation_id + "/book/" + d.getFullYear() + "/" +(d.getMonth() + 1) + "/" + d.getDate()} target="_blank">要確認</a> : "Loading..."}</div>
          </div>
        )


      })
    )

  }

    return(
      <div className="inner">
          <main className="main">
            <Menu selected="accommodation" accommodation_id={accommodation_id}/>
            <div className="main-content calendar">
              <Header accommodation_id = {accommodation_id} id="calendar"/>

              <div className="open-close-section">

                <section className={"pannel full "}>
                  <div className="section-header">
                      <div className="title">プラン | {item ? item.name : null} | 同期確認（一休） </div>
                  </div>

                  <div className="section-content list form fix">
                    <div className="list-item">
                      <div className="item-head">対象宿泊施設</div>
                      <div className="item-content row">
                        <Select
                          options={dates}
                          styles = {accStyles}
                          placeholder = "チェック年月を選択"
                          onChange = {(e) => setTaretDate(e.value)}

                        />
                        <button className="small link-btn" onClick={check}>確認する</button>
                      </div>
                    </div>

                  </div>
                  <div className="scrollable-container ">
                    <div className="mail-list-item header">
                      <div className="date with-week">日付け</div>
                      <div className="w100">管理ページ</div>
                      <div className="w100">一休予約</div>
                      <div className="w100">状況</div>
                    </div>
                    <div className="scrollable-vertical">
                        {list}
                    </div>
                  </div>


                </section>
              </div>
            </div>

          </main>
      </div>
    )
}

export default SyncCheckApp
