import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';
import { format } from 'date-fns'
import {parseISO} from 'date-fns'
import config from 'react-global-configuration';

import AuthStore from '../../stores/AuthStore';
import BookActions from '../../actions/BookActions';
import BookStore from '../../stores/BookStore';
import ErrorStore from '../../stores/ErrorStore';
import AccommodationStore from '../../stores/AccommodationStore'

import Menu from './parts/Menu';
import Header from './parts/Header';
import BookItem from './parts/BookItem'
import "../../styles/dashboard.css"
import "../../styles/calendarInfo.css"

function BookListApp(props:PropsValue) {

  //const timeZone = 'Asia/Tokyo'
  const { mail } = useParams();
  const [list, setList] = useState([]);

  const [pannelCss, setPannelCss] = useState("full")
  const [bookPannel, setBookPannel] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler, [])

  function bookChangeHandler(){
    setList(
      BookStore.getList().map(item => {
        return(
          <div className="item-list-item">
            <div  className="accommodation">
              {AccommodationStore.getById(item.accommodation_id)
                ? <div style={{backgroundColor: '#' + AccommodationStore.getById(item.accommodation_id).color}} className="acc_icon">{AccommodationStore.getById(item.accommodation_id).short_name}</div>
                : ""}
            </div>
            <div className="w130">{format(new Date(item.start_date), 'yyyy年M月d日')}</div>
            <div  className="free">{item.name}</div>
            <div  className="w100 wide-hidden">{item.person_number}人</div>
            <div className="w130">{format(parseISO(item.created_at), 'yyyy年M月d日')}</div>
            <div className="w100 wide-hidden">{config.get("VIA_" + item.via)}</div>
            <div className="w100  ">{item.delete_flg ? "キャンセル" : null}</div>
            <div className="w100  wide-hidden">{item.updated_at ? format(parseISO(item.updated_at), 'yyyy年M月d日') : null}</div>
            <div className="button wide-hidden" onClick={() => openPannel(item.id, item.accommodation_id)}>詳細 > </div>
          </div>
        )
      })
    )
  }

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  function errorChangeHandler(){

  }

  useEffect(() => {
    BookStore.addChangeListener(stableBookChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    BookActions.getList(AuthStore.getToken(), null, null, null, null, false, true, mail)
    
    return function cleanup() {
     
      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
    };

  },[stableBookChangeHandler,stableErrorChangeHandler,mail]);

  

  function openPannel(id, accommodation_id = 0){
    setPannelCss("wide")
    setBookPannel(<BookItem id={id}  closePannel={closePannel} accommodation_id={accommodation_id} />)
  }

  function closePannel(){
    setPannelCss("full")
    setBookPannel(null)
  }
  /**
  function displayMailDetail(item){
   props.history.push("/m/mail/receive/" + item.id)
  } */

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="index" />
          <div className="main-content">
            <Header title="宿泊一覧" />
            <div className="free-pannel scrollable-vertical">
              <section className={"pannel " + pannelCss}>
              
                <div className="toggle-pannel-header">
                  <div className="toggle-title">{mail}の宿泊一覧</div>
                </div>
                <div className="item-list-item header">
                  <div  className="accommodation">宿泊施設</div>
                  <div  className="w130">宿泊日</div>
                  <div className="free">宿泊者</div>
                  <div  className="w100 wide-hidden">宿泊人数</div>
                  <div  className="w130">予約発生日</div>
                  <div  className="w100 ">予約経路</div>
                  <div  className="w100 ">キャンセル</div>
                  <div  className="w100 wide-hidden">キャンセル日</div>
                  <div  className="button wide-hidden">詳細</div>
                </div>

                <div className="scrollable-container">
                  <div>{list}</div>
                </div>

              </section>
              {bookPannel}
            </div>
          </div>

        </main>
    </div>
  )
}

export default BookListApp
