import React, { useState, useEffect,useCallback } from 'react'
import AuthStore from '../../stores/AuthStore';
import BookStore from '../../stores/BookStore';
import BookActions from '../../actions/BookActions';
import AccommodationStore from '../../stores/AccommodationStore'
import Menu from './parts/Menu';
import Header from './parts/Header';
import { subMonths } from 'date-fns'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import differenceInDays from 'date-fns/differenceInDays'
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'

import "../../styles/color.css"

function DashBoardApp(props:PropsValue) {
  registerLocale('ja', ja)
  //const { year } = useParams();
  //const { month } = useParams();

  //const l_m = year && month ?  new Date(year + "-" + month + "-01")  : add(new Date(), {months:-1})

  const [book_list, setBookList] = useState([]);
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const stableBookChangeHandlerr = useCallback(bookChangeHandlerr, [])
  const stableSearchParamChangeHandler = useCallback(bookSearchChangeHandler,[])
  
  function bookSearchChangeHandler(){
   
    setStartDate(BookStore.getSearchValue("start_date"))
    setEndDate(BookStore.getSearchValue("end_date"))
  }
  function bookChangeHandlerr(){
    const days = differenceInDays( BookStore.getSearchValue("end_date"),BookStore.getSearchValue("start_date"))
   
    setBookList(
      AccommodationStore.getList().map( acc => {
        var _total_book_all = 0;
        var _total_book_paid = 0;
        var _total_price = 0
        var _total_option = 0
        
        const list = BookStore.getByAccomodationId(acc.id)
        for(var i = 0; i < list.length; i++){
          const book = list[i]
          
          if(book.delete_flg === 0){
            _total_book_all ++
            if(book.price > 0 ) _total_book_paid++
            _total_price += book.price

            for(var t = 0; t< book.options.length; t++){
              const opt = book.options[t]
              _total_option += opt.price
            }

          }
        }
        return (
          <div className="mail-list-item">
            <div className="icon"><span style={{backgroundColor: '#' + acc.color}} className="acc_icon">{acc.short_name}</span></div>
            <div className="name">{acc.name}</div>
            <div  className="number">{_total_book_all}</div>
            <div  className="number">{_total_book_paid}</div>
            <div  className="number">{Math.round(_total_book_all * 100 / (acc.room_number * days))}%</div>
            <div className="number large">{_total_price.toLocaleString()}円</div>
            <div className="number">{_total_option.toLocaleString()}円</div>
          </div>
        )

      })
    )

  }

  useEffect(() => {
    BookStore.addChangeListener(stableSearchParamChangeHandler,"search_param")
    BookStore.addChangeListener(stableBookChangeHandlerr)
    BookActions.setSearchParam("start_date",startOfMonth(subMonths(new Date(),1)))
    BookActions.setSearchParam("end_date",endOfMonth(subMonths(new Date(),1)))
    BookActions.getList(AuthStore.getToken(), null, startOfMonth(subMonths(new Date(),1)), endOfMonth(subMonths(new Date(),1)))
    return function cleanup() {
      BookStore.removeChangeListener(stableSearchParamChangeHandler,"search_param")
      BookStore.removeChangeListener(stableBookChangeHandlerr)
    };

  },[stableBookChangeHandlerr,stableSearchParamChangeHandler]);

  function setDate(name, date){
  
    BookActions.setSearchParam(name,date)
  }
  function search(){
    setBookList([<div className="loading"><i class="fas fa-circle-notch fa-spin"></i></div>])
    BookActions.getList(AuthStore.getToken(), null, BookStore.getSearchValue("start_date"), BookStore.getSearchValue("end_date"))
  }
  
  return(
    <div className="inner">
      <main className="main">
        <Menu selected="analytics"/>
        <div className="main-content">
          <Header title="宿泊者分析"/>
          <div className="open-close-section">
            <section className="full pannel">
              <div className="section-header">
                  <h2 className="title">宿泊者分析</h2>
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={start_date} onChange={(date) => setDate("start_date", date)} />&nbsp;〜&nbsp; 
                  <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={end_date} onChange={(date) => setDate("end_date", date)} /> &nbsp;&nbsp;
                  <div className="button-create" onClick={search} ><div>表示</div></div>
              </div>
              <div className="mail-list-item header">

                <div className="name">宿泊施設</div>
                <div  className="number">全予約数</div>
                <div  className="number">有料予約数</div>
                <div  className="number">稼働率</div>
                <div className="number large">宿泊金額合計</div>
                <div className="number large">OP金額合計</div>
              </div>

              <div className="scrollable-container">
                {book_list}
              </div>
            </section>
          </div>

        </div>
      </main>
    </div>
  )
}

export default DashBoardApp
