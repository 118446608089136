import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var FaqActions = {


  getList(token, accommodation_id ,room_id = null){

    const room_str = room_id ? "?room_id="  + room_id : ""
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_FAQ") + room_str, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.FAQ_LIST_LOADED,
          list:data,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  create(token, accommodation_id, item,  refresh_list = true){

    const  _item = {
      question:item.question,
      answer:item.answer,
      display_order:Number(item.display_order ? item.display_order : 0 ),
      room_id :item.room_id
    }
    const id_str = item.id ? "/" +item.id : ""

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + config.get("API_FAQ") + id_str , {
      method: item.id ? "PATCH" : "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(_item)
    }).then(function(response) {
      if(response.status === 200 ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data){
        if(refresh_list){
          FaqActions.getList(token, accommodation_id )
        }

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })



  },


  delete(token,accommodation_id,id, refresh_list = true){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" +accommodation_id + config.get("API_FAQ") +  "/" + id , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        if(refresh_list)
          FaqActions.getList(token, accommodation_id )
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.FAQ_UPDATE,
      name:name,
      value:value
    })
  },

  updateList(token, accommodation_id, items){
    AppDispatcher.dispatch({
      actionType: Constants.FAQ_LIST_LOADED,
      list:items
    })
    for(var i = 0; i < items.length; i++){
      if(items[i].display_order !== i) {
        const _item = items[i].set("display_order", i )
        FaqActions.create(token, accommodation_id, _item, false)
      }
    }
  },


  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.FAQ_SET_ITEM,
      item:item
    })
  }



}

export default FaqActions
