import { Record } from 'immutable'


const MailPublish = Record({
  mail_title:null,
  mail_body: null,
  mail_send_at:new Date(),
  mail_from_address:null,
  mail_file:null
});
export default MailPublish;
