import React,{useState, useEffect,useCallback} from 'react'


function MessageBox(props:PropsValue) {

  const [timeoutId,setTimeOutId] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableTimeout = useCallback(timeout, [])
  function timeout(){
    props.closeMessageBox()
  }

  useEffect(() => {
    if(props.main_class === "active"){
      setTimeOutId(window.setTimeout(stableTimeout, 5000)) ;
    }
  },[props.main_class,stableTimeout]);

  function closeHandler(){
    window.clearTimeout(timeoutId);
    props.closeMessageBox()
  }


  return(
    <div className={"message-box-wrap " + props.main_class}>
      <div className={ props.error === 1 ? "message-box-header error" : "message-box-header"}>
        {props.title}
        <div className="close" onClick={closeHandler}><i className="fas fa-times"></i></div>
      </div>
      <div className="message-content">
        {props.message}
      </div>
    </div>
  )
}

export default MessageBox
