import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

import BookActions from "./BookActions"
var PaymentActions = {



  create(token, payment_exe_date, amount, pay_type, book_id, with_mail, get_non_paid_list = false){
    const  request_data = {
      "payment_exe_date":payment_exe_date,
      "book_id":book_id,
      "amount":amount,
      "pay_type":pay_type,
      "mail":with_mail
    }

    fetch(config.get("API_PATH") + config.get("API_PAYMENT") , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_CREATED,
        })

        if(get_non_paid_list){
          BookActions.getList(token, null, new Date(), null, null ,true)
          BookActions.getDetail(token,book_id)
        }

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  delete(token,id){

    fetch(config.get("API_PATH") + config.get("API_PAYMENT") + "/" + id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 204  ){
        return {}
      }else if(response.status === 401){
        window.location.href = config.get("LOGIN_URL")
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_DELETED,
        })
        BookActions.getList(token, null, new Date(), null, null ,true)
        BookActions.getDetail(token,id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_DELETE")
        })
      }
    })
  },


  updateMailSend(is_send){
    AppDispatcher.dispatch({
      actionType: Constants.PAYMENT_MAIL_SEND_SELECT,
      select:is_send
    })
  }



}

export default PaymentActions
