import React,{useState,useEffect,useCallback} from 'react'

import FreeMailActions from "../../../actions/FreeMailActions"
import MailTemplateActions from "../../../actions/MailTemplateActions"
import AuthStore from "../../../stores/AuthStore"
import FreeMailStore from "../../../stores/FreeMailStore"
import MailTemplateStore from "../../../stores/MailTemplateStore"
import config from 'react-global-configuration';
import Select from 'react-select'
import {selectFormStyles} from "../parts/selectStyle"
import { format } from 'date-fns'
import '../../../styles/overlay_form.css'

function MailCreateBoxItem(props:PropsValue) {

  const [item, setItem] = useState([]);
  const [templates, setTemplates] = useState([])
  const [template, setTemplate] = useState(null)
  const [insert_id, setInsertId] = useState(null)
  const [caret, setCaret] = useState(0)

  const stableMailChangeHandler = useCallback(mailChangeHandler,[])
  const stableMailTemplateChangeHandler = useCallback(mailTemplateChangeHandler,[])

  useEffect(() => {

    FreeMailStore.addChangeListener(stableMailChangeHandler)
    MailTemplateStore.addChangeListener(stableMailTemplateChangeHandler)
    FreeMailActions.createNew()
    MailTemplateActions.getList(AuthStore.getToken(), props.accommodation_id)
    return function cleanup() {
      FreeMailStore.removeChangeListener(stableMailChangeHandler)
        MailTemplateStore.removeChangeListener(stableMailTemplateChangeHandler)
    };
  },[stableMailChangeHandler,stableMailTemplateChangeHandler,props.accommodation_id]);


  function mailChangeHandler(){
    setItem(
      FreeMailStore.getItem()
    )
  }

  function mailTemplateChangeHandler(){
    setTemplates(MailTemplateStore.getList().map(item => {
      return {label:item.title, value:item.id}
    }))
  }


  function changeHandler(e){
    const target = e.target
    FreeMailActions.updateForm(target.name, target.value)
  }


  function create(){
    FreeMailActions.create(AuthStore.getToken(), props.item.id,FreeMailStore.getItem())
    props.cancell()
  }

  function changeSelectTemplateHandler(e){
    setTemplate(e)
  }

  function readTemplate(){
    const _template = MailTemplateStore.getFromList(template)
    if(_template){
      FreeMailActions.updateForm("title", _template.title)
      FreeMailActions.getBody(AuthStore.getToken(), _template.id, props.item.id)
    }
  }
  function changeSelectInsertHandler(id){
    setInsertId(id)
  }
  function insertParts(){
    const current_body = FreeMailStore.getItem().body
    const str_a = current_body ? current_body.substring(0, caret) : ""
    const str_b = current_body ? current_body.substring(caret) : ""
    var insert_str = ""
    switch (insert_id) {
      case "book.name":
        insert_str = props.item.name
        break;
      case "book.start_date":
        insert_str = format(new Date(props.item.start_date), "yyyy年MM月dd日")
        break;
      case "book.price":
        insert_str = props.item.price ? props.item.price.toLocaleString() : ""
          break;
      case "book.options":
        const options = props.item.options ? props.item.options.map(op =>  `${op.name} x ${op.unit} : ${op.price.toLocaleString()}円`) : []
        const total_price = props.item.options ? props.item.options.map(op =>  op.price ) : []
        insert_str = options.join("\n") + `\n合計金額: ${total_price.reduce((sum, element) => sum + element, 0).toLocaleString()}円`
      break;
      case "book.price_sub":
        insert_str = props.item.price_sub ? props.item.price_sub.toLocaleString() : ""
        break;
      case "book.price_sub_50":
        insert_str = props.item.price_sub ? Math.round(props.item.price_sub * 0.5).toLocaleString() : ""
        break;
      case "book.price_sub_10":
        insert_str = props.item.price_sub ? Math.round(props.item.price_sub * 0.1).toLocaleString() : ""
        break;
      default:

    }
    FreeMailActions.updateForm("body", str_a + insert_str + str_b)
  }
  function setCaretPosition(e){
    setCaret(e.target.selectionStart)
  }
  return(
      <div className="overlay-form">
          <div className="overlay-form-title">メール送信</div>
          <div className="overlay-form-2c">
            <div className="overlay-form-list overlay-form-2c-wide">

              <div className="overlay-form-list-item">
                <label>タイトル</label>
                <div className="item-value ">
                  <div className="item-value "><input type="text" name="title" value={item ? item.title : null} onChange={changeHandler}/></div>
                </div>
              </div>
              <div className="overlay-form-list-item">
                <label>本文</label>
                <div className="item-value ">
                  <div className="item-value "><textarea onChange={changeHandler} name="body" className="tall" value={item ? item.body : null}  onBlur={setCaretPosition} /></div>
                </div>
              </div>

              <div className="btn-box">
                <div className="cancell" onClick={props.cancell}>キャンセル</div>
                <div className="ok" onClick={create}>送信</div>
              </div>
            </div>

            <div className="overlay-form-list overlay-form-2c-narrow">
              <div className="overlay-form-list-item">
                <label>テンプレート</label>
                <div className="item-value ">
                  <div className="item-value ">
                    <Select
                        options={templates}
                        placeholder = "テンプレートを選択"
                        onChange = {(e) => changeSelectTemplateHandler(e.value)}
                        styles = {selectFormStyles}
                    />
                  </div>
                  <div className="btn-box">
                    <div className="ok" onClick={readTemplate}>読み込み</div>
                  </div>
                </div>
                <div className="overlay-form-list-item margin-top-30">
                  <label>パーツの挿入</label>
                  <div className="item-value ">
                    <div className="item-value ">
                      <Select
                          options={config.get("LIST_MAIL_PARTS")}
                          placeholder = "選択"
                          onChange = {(e) => changeSelectInsertHandler(e.value)}
                          styles = {selectFormStyles}
                      />
                    </div>
                    <div className="btn-box">
                      <div className="ok" onClick={insertParts}>挿入</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
     </div>




  )
}

export default MailCreateBoxItem
