import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var SyncItemActions = {


  getList(token, target_type, target_id){

    let params = new URLSearchParams();
    if(target_type != null){
      params.set('target_type', target_type);
    }
    if(target_id != null){
      params.set('target_id', target_id);
    }


    fetch(config.get("API_PATH") + config.get("API_SYNC_ITEM")  + "?" + params.toString() , {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.SYNC_ITEM_LOADED,
          list:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  create(token, target_type, target_id, target_name){

    const  request_data = {
      "service":1,
      "target_type":target_type,
      "target_id":target_id,
      "target_name":target_name,
    }

    fetch(config.get("API_PATH") + config.get("API_SYNC_ITEM"), {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        SyncItemActions.getList(token,target_type,target_id)
        AppDispatcher.dispatch({
          actionType: Constants.SYNC_ITEM_CREATED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  update(token, id, target_name,target_type,target_id){

    const  request_data = {
      "target_name":target_name
    }

    fetch(config.get("API_PATH") + config.get("API_SYNC_ITEM")  + "/" + id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,'Content-Type': 'application/json'
      },
      body: JSON.stringify(request_data)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        SyncItemActions.getList(token,target_type,target_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      }
    })
  },



  delete(token, id, target_type,target_id){
    fetch(config.get("API_PATH") + config.get("API_SYNC_ITEM")  + "/" + id , {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 204  ){
          return {status:"deleted"}
        }else if(response.status === 401){
          return null
        }else{
          return  {"error":response.status}
        }
      }).then(function(data) {
        if(data && !data.error){
          SyncItemActions.getList(token,target_type,target_id)
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      })
  },

  updateForm(id,value){
    AppDispatcher.dispatch({
      actionType: Constants.SYNC_ITEM_UPDATE,
      id:id,
      target_name:value
    })
  }

}

export default SyncItemActions
