import { Record } from 'immutable'


const CashHistory = Record({
  paid_date:new Date(),
  amount: null,
  balance_check:0,
  intended_use:null,
  direction:null
});
export default CashHistory;
