
import PlanProps from './PlanProps'
import {addDays, format, differenceInDays} from 'date-fns';
const props = extendValues => class extends PlanProps {};
class Plan extends props({}) {

  getCsvObject(){
   /**const first_row = [""]
    for(var i = 1; i <= this.max_num; i++){
      first_row.push( i + "名")
    }
    
    const array = [first_row.join(",")] */ 

    const start_date = this.start_at ? this.start_at : new Date()
    const end_date = this.end_at ? this.end_at : addDays(start_date,1)
    const steps  = differenceInDays( end_date, start_date)

    
    const array = []
    for(var t = 0; t < steps; t++){
      const _row = [format(addDays(start_date,t), "yyyy/MM/dd")]
      for(var i = 1; i <= this.max_num; i++){
        _row.push("")
      }
      array.push(_row.join(","))
    }
  
    
    return array.join('\n')
    //return array.map((a) => a.join(',')).join('\n')
   // return ""
  }


  getFileName(){
    return "CSV_PRICE_FORMAT_" + this.name
  }
 // 

}
export default Plan


