import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import AuthActions from "./AuthActions"

import config from 'react-global-configuration';


var PaymentMethodActions = {


    getList(token){

      fetch(config.get("API_PATH") + config.get("API_PAYMENT_METHOD") , {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token
        },
      }).then(function(response) {
        if(response.status === 200  ){
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
             return response.json()
           } else {
             return {"error":402}
          }
        }else if(response.status === 401){
          AuthActions.logout()
          return
        }
        throw new Error('Network response was not ok.');
      }).then(function(data) {

        if(data && !data.error){

          AppDispatcher.dispatch({
            actionType: Constants.PAYMENT_METHOD_LIST_LOADED,
            list:data
          })
        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("ERROR_API")
          })
        }
      }).catch(function(error) {

        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message:config.get("ERROR_API")
        })
      });
    },

}

export default PaymentMethodActions
